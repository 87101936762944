import { Card, CardContent, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

type ProductCardProps = {
    icon: any,
    title: string,
    id: string,
    description: string,
    isExternalUrl?: boolean | string}

export const ProductCard = ({ icon, title, description, id, isExternalUrl = false }: ProductCardProps) => {

    let cardprop = { component: Link, id };

    // if(id ===  'inno-gist') {
    //      cardprop = { component: 'a', "href" : `https://www.gistai.in`, "target": "_blank", id }
    // }
    // else if(id === 'inno-ckyc-cropping') {
    //      cardprop = { component: 'a', "href" : `https://ckyc-ui-bucket.s3-website-us-west-2.amazonaws.com/dashboard`, "target": "_blank", id } 
    // }
    // else {
    //     cardprop = { component: Link, id }
    // }
    
    return (
        <Card {...cardprop} variant="outlined"
            sx={{
                borderRadius: "0",
                minHeight: { xs: "220px", xl: "240px" },
                border: "none",
                borderRight: "0.5px solid #E3E3E3",
                borderBottom: "0.5px solid #E3E3E3",
                display: "block",
                textDecoration: "none",
                boxSizing: "border-box",
                transition: `background 0.4s ease`,
                "&:hover": {
                    background: "#EFEFF1",
                    borderColor: "#E3E3E3"
                }
            }}
        >
            <CardContent>
                <img src={icon} alt='product-img' style={{width:"53px", height:"53px"}} />
                <Typography
                    variant='subtitle1'
                    fontWeight="fontWeightMedium"
                    color="#000"
                    sx={{
                        marginTop: "6px",
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    variant='subtitle2'
                    fontWeight="fontWeightRegular"
                    color="rgba(0, 0, 0, 0.70)"
                    lineHeight={1.5}
                >
                    {description}
                </Typography>
            </CardContent>
        </Card>
    )
}