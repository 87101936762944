import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Breadcrumb as AntBreadcrumb } from "antd";

const Breadcrumb = () => {
  const location = useLocation();
  const params = useParams();
  const breadcrumbView = () => {
    const { pathname } = location;
    const pathnames = pathname.split("/").filter((item) => item);

    return (
      <div>
        <AntBreadcrumb>
          {pathnames.length > 0 ? (
            <AntBreadcrumb.Item>
              <Link to="/">Home</Link>
            </AntBreadcrumb.Item>
          ) : (
            <AntBreadcrumb.Item>Home</AntBreadcrumb.Item>
          )}
          {pathnames.length > 0 &&
            pathnames.map((name, index) => {
              const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
              const isLast = index === pathnames.length - 1;

              return isLast ? (
                <AntBreadcrumb.Item key={index}>
                  {/* {capitalizeFirstLetter(formatSegment(name))} */}
                  {name}
                </AntBreadcrumb.Item>
              ) : (
                <AntBreadcrumb.Item key={index}>
                  {name === "dashboard" || name === "api-docs" ? (
                    null
                    // name
                  ) : (
                    <Link to={routeTo}>
                      {/* ( capitalizeFirstLetter(formatSegment(name)) ) */}
                      {name}
                    </Link>
                  )}
                </AntBreadcrumb.Item>
              );
            })}
        </AntBreadcrumb>
      </div>
    );
  };

  return <div>{breadcrumbView()}</div>;
};

export default Breadcrumb;
