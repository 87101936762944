import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom';
import Loader from '../components/Common/Loader/Loader';
import FileDragDrop from '../components/Common/FileDragDrop';
import HeaderTop from '../components/pages/OCR/HeaderTop';
import { Box, Grid } from '@mui/material';
import ImagePreviewCard from '../components/pages/OCR/ImagePreviewCard';
import JSONCard from '../components/Common/JSONCard';
import { OutletcontextType } from '../components/Layout/Index';

const result = {
    "FORM_TYPE": "REG-06",
    "TRADE_NAME": "E&OE"
}

const GSTOCR = () => {
    const { handleChangeTitle } = useOutletContext<OutletcontextType>();
    const [ocrFiles, setOcrFiles] = useState<any>({});
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        handleChangeTitle("GST OCR", "Extract data from Goods and Services Tax(GST) bill image.")
    }, [handleChangeTitle])

    const handleChange = (files: any, e: any) => {
        // console.log(files[0])
        setLoading(true);
        setOcrFiles(files[0]);
        setTimeout(() => {
            setLoading(false)
        }, 5000)
    }

    return (
        <>
            {
                isLoading &&
                <Loader />
            }
            {
                (!ocrFiles?.name) ?
                    <FileDragDrop handleChange={handleChange} />
                    : (!isLoading) &&
                    <>
                        <HeaderTop
                            handleFileChange={(e) => handleChange(e.target.files, e)}
                            reset={() => setOcrFiles({})}
                            enableUpload
                        />
                        <Box>
                            <Grid container spacing={2} mt={0}>
                                <Grid item lg={4} md={5} sm={6} xs={12} >
                                    <ImagePreviewCard
                                        padding="0"
                                        src='/images/gst.png'
                                        imageName="aadhar_kettan.jpg"
                                        objectFit="container"
                                    />
                                </Grid>
                                <Grid item lg={4} md={5} sm={6} xs={12}>
                                    <JSONCard
                                        data={result}
                                        maxHeight="100%"
                                        cardStyle={{
                                            height: "100%"
                                        }}
                                    />
                                </Grid>
                            </Grid >
                        </Box>
                    </>
            }
        </>
    )
}

export default GSTOCR