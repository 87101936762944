import React from 'react'

const ImageSearch = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4256_17149)">
        <path
          d="M32.7533 29.8058L27.5435 36.4487L24.7913 32.9397C24.7412 32.8757 24.6772 32.8239 24.6041 32.7883C24.531 32.7527 24.4507 32.7342 24.3694 32.7342C24.2881 32.7342 24.2079 32.7527 24.1348 32.7883C24.0617 32.8239 23.9977 32.8757 23.9475 32.9397L17.2645 41.4576C17.2027 41.5365 17.1643 41.6313 17.1538 41.731C17.1433 41.8308 17.1611 41.9314 17.2051 42.0216C17.2491 42.1117 17.3175 42.1876 17.4027 42.2406C17.4878 42.2936 17.5861 42.3216 17.6864 42.3214H42.3225C42.7712 42.3214 43.019 41.8058 42.7444 41.4576L33.6038 29.8058C33.553 29.7417 33.4884 29.6899 33.4147 29.6543C33.3411 29.6186 33.2604 29.6001 33.1786 29.6001C33.0968 29.6001 33.016 29.6186 32.9424 29.6543C32.8688 29.6899 32.8041 29.7417 32.7533 29.8058V29.8058ZM19.8225 25.3125C19.8225 26.0229 20.1048 26.7042 20.6071 27.2065C21.1094 27.7089 21.7907 27.9911 22.5011 27.9911C23.2115 27.9911 23.8928 27.7089 24.3952 27.2065C24.8975 26.7042 25.1797 26.0229 25.1797 25.3125C25.1797 24.6021 24.8975 23.9208 24.3952 23.4185C23.8928 22.9161 23.2115 22.6339 22.5011 22.6339C21.7907 22.6339 21.1094 22.9161 20.6071 23.4185C20.1048 23.9208 19.8225 24.6021 19.8225 25.3125ZM52.9431 15.0402L38.5324 0.629464C38.1306 0.227679 37.5882 0 37.019 0H8.57254C7.38728 0 6.42969 0.957589 6.42969 2.14286V57.8571C6.42969 59.0424 7.38728 60 8.57254 60H51.4297C52.615 60 53.5725 59.0424 53.5725 57.8571V16.5603C53.5725 15.9911 53.3449 15.442 52.9431 15.0402ZM48.6306 17.5446H36.0279V4.94196L48.6306 17.5446ZM48.7511 55.1786H11.2511V4.82143H31.4743V19.2857C31.4743 20.0316 31.7706 20.747 32.2981 21.2745C32.8255 21.8019 33.5409 22.0982 34.2868 22.0982H48.7511V55.1786Z"
          fill="black"
          fill-opacity="0.85"
        />
      </g>
      <defs>
        <clipPath id="clip0_4256_17149">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ImageSearch
