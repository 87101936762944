import React from "react";

const DisabledSaveIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4191_30147)">
        <path
          d="M14.8092 4.09681L11.9056 1.19324C11.7717 1.05931 11.6074 0.961099 11.4288 0.907527V0.859313H1.42884C1.11277 0.859313 0.857414 1.11467 0.857414 1.43074V14.5736C0.857414 14.8897 1.11277 15.145 1.42884 15.145H14.5717C14.8878 15.145 15.1431 14.8897 15.1431 14.5736V4.90396C15.1431 4.60038 15.0235 4.3111 14.8092 4.09681ZM5.71456 2.14503H10.286V4.00217H5.71456V2.14503ZM13.8574 13.8593H2.14313V2.14503H4.5717V4.5736C4.5717 4.88967 4.82706 5.14503 5.14313 5.14503H10.8574C11.1735 5.14503 11.4288 4.88967 11.4288 4.5736V2.53431L13.8574 4.96288V13.8593ZM8.00027 6.75217C6.58063 6.75217 5.42884 7.90396 5.42884 9.3236C5.42884 10.7432 6.58063 11.895 8.00027 11.895C9.41992 11.895 10.5717 10.7432 10.5717 9.3236C10.5717 7.90396 9.41992 6.75217 8.00027 6.75217ZM8.00027 10.7522C7.21099 10.7522 6.5717 10.1129 6.5717 9.3236C6.5717 8.53431 7.21099 7.89503 8.00027 7.89503C8.78956 7.89503 9.42884 8.53431 9.42884 9.3236C9.42884 10.1129 8.78956 10.7522 8.00027 10.7522Z"
          fill="#A6A6A6"
        />
      </g>
      <defs>
        <clipPath id="clip0_4191_30147">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DisabledSaveIcon;
