/* eslint-disable no-labels */
import { createSlice, nanoid } from "@reduxjs/toolkit";

var userLoginData = JSON.parse(localStorage.getItem("userLoginData"));

// Check if the object exists
if (userLoginData !== null) {
  // Access the 'name' property from the object
  var name = userLoginData.name;
  console.log("Retrieved name:", name);
} else {
  console.log("Object not found in local storage.");
}

const initialState = {
  profileName: name,
};

console.log(initialState.profileName, "profileName Redux");

export const profileSlice = createSlice({
  name: "profileValue",
  initialState,
  reducers: {
    changeProfileName: (state, action) => {
      state.profileName = action.payload;
    },
  },
});

export const { changeProfileName } = profileSlice.actions;
export default profileSlice.reducer;
