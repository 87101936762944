import React from "react";

const LogoutIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Logout" clip-path="url(#clip0_2841_29529)">
        <path
          id="Vector"
          d="M14.3564 11.9275H13.1011C13.0154 11.9275 12.935 11.965 12.8814 12.0311C12.7564 12.1828 12.6225 12.3293 12.4814 12.4686C11.9044 13.0461 11.221 13.5064 10.4689 13.8239C9.68974 14.153 8.85226 14.3218 8.00643 14.3203C7.15107 14.3203 6.3225 14.1525 5.54392 13.8239C4.79183 13.5064 4.1084 13.0461 3.53142 12.4686C2.95342 11.893 2.49253 11.2107 2.17428 10.4596C1.84392 9.68105 1.67785 8.85427 1.67785 7.99891C1.67785 7.14355 1.84571 6.31677 2.17428 5.5382C2.49214 4.78641 2.94928 4.10963 3.53142 3.52927C4.11357 2.94891 4.79035 2.49177 5.54392 2.17391C6.3225 1.84534 7.15107 1.67748 8.00643 1.67748C8.86178 1.67748 9.69035 1.84355 10.4689 2.17391C11.2225 2.49177 11.8993 2.94891 12.4814 3.52927C12.6225 3.67034 12.7546 3.81677 12.8814 3.96677C12.935 4.03284 13.0171 4.07034 13.1011 4.07034H14.3564C14.4689 4.07034 14.5386 3.94534 14.4761 3.8507C13.1064 1.72213 10.71 0.313197 7.98678 0.32034C3.70821 0.331054 0.277853 3.80427 0.32071 8.07748C0.363567 12.2828 3.78857 15.6775 8.00643 15.6775C10.7225 15.6775 13.1082 14.2703 14.4761 12.1471C14.5368 12.0525 14.4689 11.9275 14.3564 11.9275ZM15.9439 7.88641L13.41 5.88641C13.3154 5.81141 13.1779 5.87927 13.1779 5.99891V7.35605H7.57071C7.49214 7.35605 7.42785 7.42034 7.42785 7.49891V8.49891C7.42785 8.57748 7.49214 8.64177 7.57071 8.64177H13.1779V9.99891C13.1779 10.1186 13.3171 10.1864 13.41 10.1114L15.9439 8.11141C15.961 8.09805 15.9748 8.08097 15.9843 8.06148C15.9938 8.04199 15.9987 8.02059 15.9987 7.99891C15.9987 7.97723 15.9938 7.95583 15.9843 7.93634C15.9748 7.91685 15.961 7.89977 15.9439 7.88641Z"
          fill="black"
          fill-opacity="0.85"
        />
      </g>
      <defs>
        <clipPath id="clip0_2841_29529">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoutIcon;
