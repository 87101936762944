import {
  Box,
  Button,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography,
  Card,
  styled,
  TooltipProps,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import { OutletcontextType } from "../components/Layout/Index";
import CustomButton from "../components/Common/CustomButton";
import Loader from "../components/Common/Loader/Loader";
import { violenceDetectionVideo } from "../service/apiActions/AddressAction";
import { violenceDetectionDetail } from "../service/apiActions/AddressAction";
import HeaderTop from "../components/pages/OCR/HeaderTop";
import ImagePreview from "../components/pages/FaceMatch/ImagePreview";
import JSONCard from "../components/Common/JSONCard";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import DataSecurityCard from "../components/Common/DataSecurityCard";
import { Auth, Storage } from "aws-amplify";
import { StorageManagercomp } from "../components/Common/StorageManager/StorageManager";
import FileDragDrop from "../components/Common/FileDragDrop";
import ReactPlayer from "react-player";
import { url } from "inspector";
import { NSFW } from "../service/apiVariables";
import { nsfwApiAction, nsfwDeleteApi } from "../service/apiActions/FaceAction";
import loader from "../Icons/LoaderGIF/loader.gif";
import FeatureFrame from "../components/Common/FeatureFrame";
import LiveIcon from "../Icons/LiveIcon";
import VideoCameraIcon from "../Icons/VideoCameraIcon";
import MapIcon from "../Icons/MapIcon";
import Nsfw1 from "../Icons/Nsfw1";
import Nsfw2 from "../Icons/Nsfw2";
import Nsfw3 from "../Icons/Nsfw3";
// import { LoadingButton } from "@mui/lab";

type ResponseDatatype = {
  requestID: string;
  status: string;
};

type violenceDataType = {
  per: string;
  time: string;
}[];

type tagDataType = {
  explicit: [];
  violence: violenceDataType;
}[];

type respDataType = {
  status: string;
  tags: tagDataType;
};

type ResponseDetailtype = {
  video_name: string;
  resp: respDataType;
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const ViolenceDetection = () => {
  const [url, setUrl] = useState<string | undefined>("");
  const [videoName, setVideoName] = useState<string | undefined>("");
  const [formdata, setFormdata] = useState<string | undefined>("");
  const { handleChangeTitle, showSnackbar } =
    useOutletContext<OutletcontextType>();
  const [isLoading, setLoading] = useState(false);
  const [resetClicked, setResetClicked] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [processRes, setProcessRes] = useState(true);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);

  const handleInput = (event: any) => {
    if (event.target.files) {
      const files = Array.from(event.target.files) as File[];
      console.log(files[0].name, "files");
      setUrl(files[0].name);
    }
  };

  const handleResetUpload = async () => {
    setProcessRes(true);
    try {
      await nsfwDeleteApi(userEmail.email).then((res: any) => {
        console.log(res, "res");
        if (res.status === "success") {
          setResetClicked(true);
          console.log(res.facematch, "matched res.data");
          setLoading(false);
          showSnackbar("Resetted Successfully", "success");
        }
      });
    } catch (error: any) {
      console.log(error, "nsfw reset err");
      setLoading(false);
      showSnackbar(error.message);
    }
  };

  const handleUploadSuccess = () => {
    setResetClicked(true);
    setUploadSuccess(true);
  };

  const handleUploadError = () => {
    setUploadSuccess(false);
    showSnackbar("Error in uploading images");
  };

  useEffect(() => {
    document.title = "AiFise | Moderation Service";
    handleChangeTitle(
      "NSFW",
      "NSFW can detect inappropriate content of various kinds."
    );
  }, []);

  const userEmail = localStorage.getItem("userLoginData")
    ? JSON.parse(localStorage.getItem("userLoginData") as string)
    : null;

  const handleUpload = async () => {
    setLoading(true);
    setProcessRes(false);
    try {
      const { results } = await Storage.list(`nsfw-video/${userEmail.email}`);
      console.log(results, "results storage List");

      if (results.length === 0) {
        showSnackbar("Please upload a video", "error");
        setLoading(false);
        return;
      }

      const vdoKeys = results
        .filter(
          (item) =>
            item.key && (item.key.endsWith(".MP4") || item.key.endsWith(".mp4"))
        )
        .map((item) => item.key);

      if (vdoKeys.length > 0) {
        setLoading(false);
        console.log(vdoKeys, "vdoKeys length true");

        const vdourl = vdoKeys[0];
        if (vdourl) {
          const vdoName = vdourl.split("/");
          const vdo = vdoName[vdoName.length - 1];
          setVideoName(vdo);
          console.log(vdo, "VDONAMEEEEE");

          // setFormdata(vdourl);
          // console.log(vdourl, "vdourl");
          //

          const baseUrl = process.env.REACT_APP_IMAGES_BASE_URL;
          const videoUrls = `${baseUrl}public/${vdourl}`;
          console.log(videoUrls, "videoUrls");

          setUrl(videoUrls);
          console.log(videoName, "videoName");

          const body = {
            userid: userEmail.email,
            videoname: vdo,
          };

          const res: any = await nsfwApiAction(body);
          console.log(res.processed, "API RESPONSE");
          setFormdata(res);
          setProcessRes(res.processed);
          console.log(processRes, "res of Process");
        } else {
          console.error("vdourl is undefined");
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error, "errorororororo");
      setLoading(false);
    } finally {
      setLoading(false);
      // setResetClicked(false);
    }
  };

  useEffect(() => {
    console.log(processRes, "processRes1");

    if (!processRes) {
      const id = setInterval(() => {
        setLoading(false);
        handleUpload();
      }, 3000);
      return () => clearInterval(id);
    } else {
      console.log("Interval stopped!");
    }
  }, [processRes]);

  return (
    <>
      {url ? (
        <>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item lg={8} sm={6} xs={12}>
              <HeaderTop
                reset={() => {
                  setUrl("");
                  setVideoName("");
                  setProcessRes(true);
                  setFormdata("");
                  setResetClicked(false);
                  setUploadSuccess(false);
                }}
                // enableUpload
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="center" spacing={3} mt={2}>
            <Grid item lg={8.5} sm={6} xs={12}>
              <ReactPlayer
                controls
                url={url}
                style={{
                  marginTop: "16px",
                  marginBottom: "16px",
                }}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" spacing={3} mb={2}>
            <Grid item lg={8} sm={6} xs={12}>
              {processRes === true ? (
                <JSONCard data={formdata} />
              ) : (
                // <Card>
                // {" "}
                <Loader />
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12} md={12} lg={8} sm={6} justifyContent="center">
            <Typography
              noWrap
              component="div"
              color="rgba(0, 0, 0, 0.70)"
              fontSize="20px"
              fontWeight={600}
              lineHeight="24px"
              textAlign="center"
              mb={1}
            >
              Upload videos to find and classify inappropriate content
            </Typography>
            <Grid item>
            <LightTooltip
                  title={
                    !resetClicked && "Disabled: Reset first before upload"
                  }
                >
                  <span>
                    <div
                      style={{
                        pointerEvents: resetClicked ? "auto" : "none",
                        opacity: resetClicked ? 1 : 0.2,
                      }}
                    >
                      <StorageManagercomp
                        onUploadSuccess={handleUploadSuccess}
                        onUploadError={handleUploadError}
                      />
                    </div>
                  </span>
                </LightTooltip>
            </Grid>
            <Grid
              item
              display="flex"
              justifyContent="flex-end"
              lg={12}
              mt={3}
              gap={2}
            >
              <CustomButton
                variant="outlined"
                size="large"
                onClick={handleResetUpload}
                // loading={isLoading}
                disabled={resetClicked}
                sx={{
                  border: "1px solid",
                  textTransform: "capitalize",
                  borderRadius: 0.5,
                  boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)",
                }}
              >
                Reset
              </CustomButton>
              <CustomButton
                size="large"
                onClick={handleUpload}
                disabled={!uploadSuccess} // Disabling the Next button if Reset button is not clicked
                // loading={isLoading}
                color="primary"
                variant="contained"
                sx={{
                  border: uploadSuccess ? "1px solid" : "none",
                }}
              >
                Next
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      <FeatureFrame
        frameTitle="Elevate Content Moderation through AI Brilliance"
        frameDescription="Unlock the Power of AIFISE's Content Moderation AI!"
        icon1={<Nsfw1 />}
        icon2={<Nsfw2 />}
        icon3={<Nsfw3 />}
        iconDescription1="Instant monitoring and assessment of content as it is generated or uploaded."
        iconDescription2="Improved precision and correctness in the identification and evaluation of content."
        iconDescription3="Optimal use of time and resources, ensuring streamlined moderation"
        iconTitle1="Real-time Moderation"
        iconTitle2="Enhanced Accuracy"
        iconTitle3="Resource Efficiency"
      />
    </>
  );
};

export default ViolenceDetection;
