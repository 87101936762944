import React from "react";

const NoImage = () => {
  return (
    <svg
      width="60"
      height="64"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" fill="#D9D9D9" />
      <path
        d="M34.2824 31.7359C33.7771 30.5391 33.0439 29.4519 32.1235 28.535C31.2059 27.6155 30.1189 26.8824 28.9226 26.3761C28.9119 26.3708 28.9012 26.3681 28.8905 26.3627C30.5592 25.1574 31.644 23.194 31.644 20.9788C31.644 17.3092 28.6708 14.3359 25.0012 14.3359C21.3315 14.3359 18.3583 17.3092 18.3583 20.9788C18.3583 23.194 19.4431 25.1574 21.1119 26.3654C21.1012 26.3708 21.0905 26.3734 21.0798 26.3788C19.8798 26.885 18.803 27.6109 17.8789 28.5377C16.9593 29.4553 16.2262 30.5423 15.7199 31.7386C15.2226 32.9098 14.9543 34.1655 14.9298 35.4377C14.929 35.4663 14.9341 35.4948 14.9445 35.5214C14.955 35.548 14.9706 35.5723 14.9906 35.5927C15.0106 35.6132 15.0344 35.6295 15.0608 35.6406C15.0871 35.6517 15.1154 35.6574 15.144 35.6574H16.7512C16.869 35.6574 16.9628 35.5636 16.9655 35.4484C17.019 33.3806 17.8494 31.444 19.3173 29.9761C20.836 28.4574 22.853 27.6217 25.0012 27.6217C27.1494 27.6217 29.1664 28.4574 30.6851 29.9761C32.153 31.444 32.9833 33.3806 33.0369 35.4484C33.0396 35.5663 33.1333 35.6574 33.2512 35.6574H34.8583C34.8869 35.6574 34.9152 35.6517 34.9416 35.6406C34.9679 35.6295 34.9918 35.6132 35.0118 35.5927C35.0317 35.5723 35.0474 35.548 35.0579 35.5214C35.0683 35.4948 35.0733 35.4663 35.0726 35.4377C35.0458 34.1574 34.7806 32.9118 34.2824 31.7359V31.7359ZM25.0012 25.5859C23.7717 25.5859 22.6146 25.1065 21.744 24.2359C20.8735 23.3654 20.394 22.2083 20.394 20.9788C20.394 19.7493 20.8735 18.5922 21.744 17.7217C22.6146 16.8511 23.7717 16.3717 25.0012 16.3717C26.2306 16.3717 27.3878 16.8511 28.2583 17.7217C29.1289 18.5922 29.6083 19.7493 29.6083 20.9788C29.6083 22.2083 29.1289 23.3654 28.2583 24.2359C27.3878 25.1065 26.2306 25.5859 25.0012 25.5859Z"
        fill="black"
        fill-opacity="0.85"
      />
    </svg>
  );
};

export default NoImage;
