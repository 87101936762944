import React from 'react'

const Ckyc1 = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 57.5H47.5M19.3925 14.3925L24.6975 19.6975M24.6975 19.6975C26.104 18.291 28.0109 17.5 30 17.5M24.6975 19.6975C23.291 21.104 22.5 23.0109 22.5 25M22.5 25H15M22.5 25C22.5 26.9891 23.291 28.896 24.6975 30.3025M24.6975 30.3025L19.3925 35.6075M24.6975 30.3025C26.104 31.709 28.0109 32.5 30 32.5M30 32.5V40M30 32.5C31.9891 32.5 33.896 31.709 35.3025 30.3025M35.3025 30.3025L40.6075 35.6075M35.3025 30.3025C36.709 28.896 37.5 26.9891 37.5 25M45 25H37.5M37.5 25C37.5 23.0109 36.709 21.104 35.3025 19.6975M40.6075 14.3925L35.3025 19.6975M35.3025 19.6975C33.896 18.291 31.9891 17.5 30 17.5M30 17.5V10M2.5 47.5H57.5V2.5H2.5V47.5ZM20 57.5H40V47.5H20V57.5Z"
        stroke="black"
        stroke-opacity="0.85"
        stroke-width="4"
      />
    </svg>
  );
}

export default Ckyc1
