import { createSlice } from "@reduxjs/toolkit";

export const sessionExpireSlice = createSlice({
  name: "sessionExpire",
  initialState: { isSessionExpired: false },
  reducers: {
    changeSessionModal: (state, action) => {
      state.isSessionExpired = action.payload;
    },
  },
});

export const { changeSessionModal } = sessionExpireSlice.actions;
export default sessionExpireSlice.reducer;
