import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Drawer,
  Collapse,
  Typography,
  IconButton,
  Button,
  Popover,
  Divider,
} from "@mui/material";
import LogoIcon from "../../Icons/LogoIcon";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  allProductsApi,
  userSettingApi,
} from "../../service/apiActions/FaceAction";
import UserIcon from "../../Icons/UserIcon";
import { SettingsOutlined } from "@mui/icons-material";
import LogoutIcon from "../../Icons/LogoutIcon";
import SettingsIcon from "../../Icons/SettingsIcon";
import ProfileBox from "../Common/ProfileBox";
import style from "./LayoutStyle.module.css";

interface SidebarProps {
  drawerWidth: number;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  window?: () => Window;
}

const sidebar = [
  {
    title: "OCR Rashmi",
    externalUrl: false,
    to: "/dashboard/ocr",
    children: [],
  },
  {
    title: "Face Match",
    externalUrl: false,
    to: "/dashboard/face-match",
    children: [],
  },
  {
    title: "Face Analysis",
    externalUrl: false,
    to: "/dashboard/face-analysis",
    children: [],
  },
  {
    title: "Aadhar Masking",
    externalUrl: false,
    to: "/dashboard/aadhar-masking",
    children: [],
  },
  {
    title: "Liveliness",
    externalUrl: false,
    // to: "https://main.df64pxx7nxbca.amplifyapp.com/",
    to: "/dashboard/liveliness",
    children: [],
  },
  {
    title: "Video KYC",
    externalUrl: false,
    // to: "https://main.d1swmz3zcnor5j.amplifyapp.com/login",
    to: "/dashboard/vkyc",
    children: [],
  },
  //     {
  //         title: "Liveliness",
  //         externalUrl: true,
  //         to: "https://main.df64pxx7nxbca.amplifyapp.com/",
  //     children: []
  // },
  // {
  //     title: "Verification Solution",
  //     externalUrl: false,
  //     to: "/",
  //     children: [
  //         {
  //             title: "Voter ID Verification",
  //             externalUrl: false,
  //             to: "/dashboard/voter-id-verfication",
  //         }
  //     ]
  // },
  // {
  //     title: "More",
  //     externalUrl: false,
  //     to: "/",
  //     children: [
  //         {
  //             title: "GST OCR",
  //             externalUrl: false,
  //             to: "/dashboard/gst-ocr",
  //         }
  //     ]
  // },
  {
    title: "Address Match",
    externalUrl: false,
    to: "/dashboard/address-match",
    children: [],
  },
  // {
  //     title: "Voter ID",
  //     externalUrl: false,
  //     to: "/dashboard/voterid-verification",
  //     children: []
  // }
  {
    title: "GIST",
    externalUrl: true,
    to: "https://www.gistai.in",
    children: [],
    // }, {
    //     title: "CKYC Cropping",
    //     externalUrl: true,
    //     to: "/",
    //     children: []
  },
  {
    title: "SDK",
    externalUrl: false,
    //to: "https://www.gistai.in",
    to: "/dashboard/sdk",
    children: [],
  },
  {
    title: "More",
    externalUrl: false,
    to: "/",
    children: [
      {
        title: "Violence Detection",
        externalUrl: false,
        to: "/dashboard/violence-detection",
      },
    ],
  },
];

type resDataType = {
  children: any;
  isExternal: boolean;
  desc: string;
  id: string;
  logo_url: any;
  name: string;
}[];

const Sidebar = ({
  drawerWidth,
  handleDrawerToggle,
  mobileOpen,
  window,
}: SidebarProps) => {
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box
        component="nav"
        sx={{
          width: { md: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="AiFise-sidebar"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          className={style.sidebar}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "#F2F1F6",
              borderRight: "0.5px solid #9F9FA1",
              padding: "14px",
              border: "2px solid red",
            },
          }}
        >
          <DrawerList />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "#F2F1F6",
              // borderRight:" 0.5px solid #9F9FA1",
              // padding: "14px",
            },
          }}
          open
        >
          <DrawerList />
        </Drawer>
        {/* <style>
          {`.css-7vc89i-MuiDrawer-docked .MuiDrawer-paper {
       border-right:none;
  }`}
        </style> */}
      </Box>
    </>
  );
};

const DrawerList = () => {
  const [resData, setResData] = useState<resDataType>([]);
  const [productData, setProductData] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    allProductsApi()
      .then((res: any) => {
        console.log("res allProductsApi ", res, "res[1]", res[1]);
        setResData([...res]);
      })
      .catch((err) => {
        console.log("res allProductsApi err ", err);
      });
  }, []);

  useEffect(() => {
    userSettingApi()
      .then((res: any) => {
        const selectedProducts = res.selected_products.split(",");

        // Filtering resData based on selected_products
        const filteredResData = resData.filter((item: any) =>
          selectedProducts.includes(item.id)
        );
        // Setting filtered data to productData
        setProductData([...filteredResData]);
      })
      .catch((err) => {
        console.log("res userSettingApi err ", err);
      });
  }, [resData]);

  const userLoginData = localStorage.getItem("userLoginData");
  const isUserAdmin = userLoginData && JSON.parse(userLoginData).role === "adm";

  const customScrollStyle = {
    py: 0,
    height: "calc(100vh - 50px)",
    overflowY: "auto",
    padding: "14px 14px",

    // overflowY: "auto",
    // animation: `${animatedBackground} 5s linear `,
    // overflowY: "auto",
    // "&:hover::-webkit-scrollbar": {
    //   width: "8px",
    //   height: "5px",
    //   opacity: "0.5",

    // },
    "&::-webkit-scrollbar": {
      width: "2px",
      // opacity: "0",
      // animation: `${animatedBackground} 5s linear `,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#9F9FA1",
      borderRadius: "8px",
      // height: "50px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f0f0f0",
      opacity: "0.5",
      borderRadius: "8px",
      height: "5px",

    },
  };

  return (
    <>
      <div
        style={{ cursor: "pointer", padding: "14px" }}
        onClick={() => navigate("/")}
      >
        <LogoIcon />
      </div>
      <Divider />
      <List sx={customScrollStyle}>
        {isUserAdmin
          ? resData?.map((item, index) =>
              item.children?.length > 0 ? (
                <MultiLevel item={item} key={index} />
              ) : (
                <SingleLevel item={item} key={index} />
              )
            )
          : productData?.map((item: any, index: any) =>
              item.children?.length > 0 ? (
                <MultiLevel item={item} key={index} />
              ) : (
                <SingleLevel item={item} key={index} />
              )
            )}
      </List>
      <Divider />
      <Box
        sx={{
          padding: "0px 14px 14px 14px",
        }}
      >
        <ProfileBox />
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: "12px",
            color: "#8C8C8C",
            lineHeight: "16.8px",
            fontWeight: 400,
            fontStyle: "normal",
            paddingLeft: "8px",
          }}
        >
          Dashboard Version 1.1.0
        </Typography>
      </Box>
      <style>
        {`
   .css-1urfunn-MuiButtonBase-root-MuiButton-root:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
     background-color:transparent;
}
  `}
      </style>
    </>
  );
};

const SingleLevel = ({
  item,
  pl = "10px",
  activeBG = "#007AFE",
  activeColor = "#fff",
}: {
  item: any;
  activeBG?: string;
  pl?: string;
  activeColor?: string;
}) => {
  // const listItemProps = item.id ==='inno-gist' ? { component: 'a', "href": item.to='https://www.gistai.in', "target": "_blank" } : { component: NavLink, to:  getApiUrl(item.id)};

  // const listItemProps = item.id ==='inno-gist' ? { component: 'a', "href": item.to='https://www.gistai.in', "target" : "_blank" }
  //                                              : { component: NavLink, to:  getApiUrl(item.id)};

  let listItemProps = { component: NavLink, to: getApiUrl(item.id) };

  // if (item.id === "inno-gist") {
  //   listItemProps = {
  //     component: "a",
  //     href: (item.to = "https://www.gistai.in"),
  //     target: "_blank",
  //   };
  // } else if (item.id === "inno-ckyc-cropping") {
  //   listItemProps = {
  //     component: "a",
  //     href: (item.to =
  //       "https://ckyc-ui-bucket.s3-website-us-west-2.amazonaws.com/dashboard"),
  //     target: "_blank",
  //   };
  // } else listItemProps = { component: NavLink, to: getApiUrl(item.id) };

  return (
    <ListItem
      key={item.name}
      disablePadding
      {...listItemProps}
      sx={{
        color: "#000",
        borderRadius: "2px",
        "&.active": {
          background: "#007AFE",
          color: activeColor,
          boxShadow:
            "0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 0px 3px 0px rgba(0, 0, 0, 0.10), 0px 0px 6px 0px rgba(0, 0, 0, 0.09), 0px 0px 8px 0px rgba(0, 0, 0, 0.05), 0px 0px 9px 0px rgba(0, 0, 0, 0.01), 0px 0px 10px 0px rgba(0, 0, 0, 0.00)",
        },
      }}
    >
      <ListItemButton
        sx={{
          padding: "13px 10px",
          pl: pl,
        }}
      >
        <ListItemText
          primary={item.name}
          // primary={`itemtitle`}
          sx={{
            margin: "0",
            textTransform: "capitalize",
            "&.MuiListItemText-primary": {
              fontSize: "7px",
              fontWeight: "fontWeightMedium",
              fontStyle: "normal",
              lineHeight: "1",
            },
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

const getApiUrl = (type: string) => {
  console.log("type===", type);

  switch (type) {
    case "inno-fm":
      return `/dashboard/face-match`;
    case "inno-ocr":
      return `/dashboard/ocr`;
    case "inno-face-analysis":
      return `/dashboard/face-analysis`;
    case "inno-face-search":
      return `/dashboard/face-search`;
    case "inno-aadhar-masking":
      return `/dashboard/aadhar-masking`;
    case "inno-gist":
      return `/dashboard/gist`;
    case "inno-ckyc-cropping":
      return `/dashboard/ckyc`;
    case "inno-video-kyc":
      return `/dashboard/vkyc`;
    case "inno-vid-verification":
      return `/dashboard/voterid-verification`;
    case "inno-face-liveness":
      return `/dashboard/liveliness `;
    case "inno-address-match":
      return `/dashboard/address-match`;
    case "inno-sdks":
      return `/dashboard/sdk`;
    case "inno-moderation":
      return `/dashboard/violence-detection`;
    case "inno-spam-classifier":
      return `/dashboard/spam-classifier`;
    case "inno-aifise-onboarding":
      return `/dashboard/onboarding`;
  }
};

const MultiLevel = ({ item }: { item: any }) => {
  const { children } = item;
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    const isActive = item.children.find((child: any) => child.to === pathname);
    if (isActive !== undefined) {
      setOpen(true);
    }
  }, [item, pathname]);

  return (
    <React.Fragment>
      <ListItem
        disablePadding
        sx={{
          // color: open ? "#FFF" : "#000",
          color: open ? "#000" : "#000",
          // background: open ? "#007AFE" : "transparent",
          background: open ? "#ddd" : "transparent",
        }}
      >
        <ListItemButton
          sx={{
            padding: "13px 10px",
          }}
          onClick={handleClick}
        >
          <ListItemText
            primary={item.title}
            sx={{
              margin: "0",
              textTransform: "capitalize",
              "&.MuiListItemText-primary": {
                fontSize: "7px",
                fontWeight: "fontWeightRegular",
                fontStyle: "normal",
                lineHeight: "1",
              },
            }}
          />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((child: any, key: number) => (
            // <SingleLevel item={child} pl={'24px'} key={key} activeBG='' activeColor='#fff' />
            <SingleLevel
              item={child}
              pl={"24px"}
              key={key}
              activeBG="#007AFE"
              activeColor="#fff"
            />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

export default Sidebar;