import { Outlet } from "react-router-dom";
import {
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";
import React from "react";
import { VariantType, useSnackbar } from "notistack";
import AdminSidebar from "./AdminSidebar";

interface PageLayoutProps {
  window?: Window;
  children: React.ReactNode;
  isProfile?: boolean;
  title: string;
  subTitle: string;
}

export interface OutletcontextType {
  handleChangeTitle: (title: string, subTitle: string) => void;
  showSnackbar: (message: string, type?: VariantType) => void;
}

const profileSidbar = [
  {
    title: "Profile",
    externalUrl: false,
    to: "/profile",
    children: [],
  },
];

const sidebar = [
  {
    title: "User Management",
    externalUrl: false,
    to: "/admin/user-management",
    children: [],
  },
  {
    title: "Enquiries",
    externalUrl: false,
    to: "/admin/enquiries",
    children: [],
  },
];

const PageLayout = ({
  window,
  children,
  isProfile = false,
  title,
  subTitle,
}: PageLayoutProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerWidth = 260;
  return (
    <Box>
      <CssBaseline />
      <AppBar
        position="sticky"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          background: "#FFF",
          color: "#000",
          boxShadow: "none",
          pt: 2.5,
          pb: 1.5,
          top: "-1px",
        }}
      >
        <Toolbar>
          {/* <Button onClick={handleDrawerToggle}>
                        Toggle
                    </Button> */}
          <Box sx={{ display: "block", width: "100%" }}>
            <Typography
            
              noWrap
              component="div"
              color="#141414"
              fontSize="30px"
              fontWeight={700}
              lineHeight={1.2}
              // textAlign="center"
            >
              {title}
            </Typography>
            <Typography
              color="rgba(0, 0, 0, 0.70)"
              fontSize="16px"
              fontWeight="fontWeightRegular"
              lineHeight="24px"
              marginTop={0.5}
              // textAlign="center"
            >
              {subTitle}
            </Typography>
          </Box>
          {/* </Box> */}
        </Toolbar>
      </AppBar>
      <AdminSidebar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
        menuList={isProfile ? profileSidbar : sidebar}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: "6px",
          px: 3,
          ml: { md: "auto" },
          width: { md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Box sx={{ width: { xs: "100%", xl: "1000px" }, m: "auto" }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default PageLayout;
