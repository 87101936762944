import React from 'react'

const BarChartIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 25 24" fill="none">
            <path d="M22.572 19.5008H4.14342V2.78655C4.14342 2.66869 4.04699 2.57227 3.92913 2.57227H2.42913C2.31127 2.57227 2.21484 2.66869 2.21484 2.78655V21.2151C2.21484 21.333 2.31127 21.4294 2.42913 21.4294H22.572C22.6898 21.4294 22.7863 21.333 22.7863 21.2151V19.7151C22.7863 19.5973 22.6898 19.5008 22.572 19.5008ZM6.50056 17.358H8.00056C8.11842 17.358 8.21484 17.2616 8.21484 17.1437V13.2866C8.21484 13.1687 8.11842 13.0723 8.00056 13.0723H6.50056C6.3827 13.0723 6.28627 13.1687 6.28627 13.2866V17.1437C6.28627 17.2616 6.3827 17.358 6.50056 17.358ZM10.572 17.358H12.072C12.1898 17.358 12.2863 17.2616 12.2863 17.1437V8.57227C12.2863 8.45441 12.1898 8.35798 12.072 8.35798H10.572C10.4541 8.35798 10.3577 8.45441 10.3577 8.57227V17.1437C10.3577 17.2616 10.4541 17.358 10.572 17.358ZM14.6434 17.358H16.1434C16.2613 17.358 16.3577 17.2616 16.3577 17.1437V10.6616C16.3577 10.5437 16.2613 10.4473 16.1434 10.4473H14.6434C14.5256 10.4473 14.4291 10.5437 14.4291 10.6616V17.1437C14.4291 17.2616 14.5256 17.358 14.6434 17.358ZM18.7148 17.358H20.2148C20.3327 17.358 20.4291 17.2616 20.4291 17.1437V6.42941C20.4291 6.31155 20.3327 6.21512 20.2148 6.21512H18.7148C18.597 6.21512 18.5006 6.31155 18.5006 6.42941V17.1437C18.5006 17.2616 18.597 17.358 18.7148 17.358Z" fill="black" fillOpacity="0.85" />
        </svg>
    )
}

export default BarChartIcon