import React from "react";

const AppleIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{cursor: 'pointer'}}
    >
      <g id="apple">
        <path
          id="Vector"
          d="M26.2758 18.8332C26.2617 16.4355 27.348 14.6285 29.5418 13.2961C28.3148 11.5383 26.4586 10.5715 24.0117 10.3852C21.6949 10.2023 19.1602 11.7352 18.232 11.7352C17.2512 11.7352 15.0082 10.4484 13.2434 10.4484C9.60117 10.5047 5.73047 13.3523 5.73047 19.1461C5.73047 20.8582 6.04336 22.6266 6.66914 24.4477C7.50586 26.8453 10.5223 32.7199 13.6687 32.625C15.3141 32.5863 16.4777 31.4578 18.6187 31.4578C20.6965 31.4578 21.7723 32.625 23.6074 32.625C26.782 32.5793 29.5102 27.2391 30.3047 24.8344C26.0473 22.827 26.2758 18.9563 26.2758 18.8332ZM22.5809 8.11055C24.3633 5.99414 24.2016 4.06758 24.1488 3.375C22.5738 3.46641 20.7527 4.44727 19.7156 5.65313C18.573 6.94688 17.9016 8.54648 18.0457 10.35C19.7473 10.4801 21.3012 9.60469 22.5809 8.11055Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default AppleIcon;
