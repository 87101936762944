import { Box, Button, Typography } from '@mui/material'
import FileUploadIcon from '../../Icons/FileUploadIcon'
import { useState } from 'react'

type FileDragDropProps = {
    handleChange: (files: any, e: React.ChangeEvent<HTMLInputElement>) => void
}

const FileDragDrop = ({ handleChange }: FileDragDropProps) => {
    const [isBorderEnable, setBorderEnable] = useState(false);

    const handleDrop = (event: any) => {
        event.preventDefault();
        handleChange(event.dataTransfer.files, event);
    }

    const handleDragOver = (event: any) => {
        event.preventDefault();
    }

    const handleFileChange = (event: any) => {
        handleChange(event.target.files, event);
        event.target.value = "";
    }

    return (
        <Box>
            <Button
                variant='outlined'
                component="label"
                sx={{
                    width: "100%",
                    border: "1px dashed",
                    borderColor: isBorderEnable ? "#007AFE" : " #9E9E9E",
                    background: "#F5F5F5",
                    py: 2,
                    display: "flex",
                    flexDirection: "column",
                    "&:hover": {
                        border: "1px dashed #007AFE",
                    }
                }}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                draggable
                onDragEnter={() => setBorderEnable(true)}
                onDragLeave={() => setBorderEnable(false)}
            >
                <FileUploadIcon color="#007AFE" />
                <Typography
                    sx={{
                        color: "#616161",
                        fontSize: "16px",
                        fontWeight: "fontWeightRegular",
                        lineHeight: "24px",
                        textTransform: "none",
                        mt: 1.5
                    }}
                >
                    Click or drag file to this area to upload
                </Typography>
                <Typography
                    sx={{
                        color: "rgba(0, 0, 0, 0.45)",
                        fontSize: "14px",
                        fontWeight: "fontWeightLight",
                        lineHeight: "24px",
                        textTransform: "none",
                    }}
                >
                    *jpeg, jpg, png only
                </Typography>
                <input type="file" hidden onChange={handleFileChange} accept='image/png, image/jpg, image/jpeg,' />
            </Button>
        </Box>
    )
}

export default FileDragDrop