import React from 'react'

const Database = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 30 30"
      fill="none"
    >
      <g clip-path="url(#clip0_2098_27238)">
        <path
          d="M25.7129 0H4.28432C3.69169 0 3.21289 0.478795 3.21289 1.07143V28.9286C3.21289 29.5212 3.69169 30 4.28432 30H25.7129C26.3055 30 26.7843 29.5212 26.7843 28.9286V1.07143C26.7843 0.478795 26.3055 0 25.7129 0ZM5.62361 2.41071H24.3736V9.375H5.62361V2.41071ZM24.3736 18.4821H5.62361V11.5179H24.3736V18.4821ZM24.3736 27.5893H5.62361V20.625H24.3736V27.5893ZM8.03432 5.89286C8.03432 6.24806 8.17542 6.58871 8.42659 6.83987C8.67775 7.09104 9.0184 7.23214 9.37361 7.23214C9.72881 7.23214 10.0695 7.09104 10.3206 6.83987C10.5718 6.58871 10.7129 6.24806 10.7129 5.89286C10.7129 5.53766 10.5718 5.197 10.3206 4.94584C10.0695 4.69467 9.72881 4.55357 9.37361 4.55357C9.0184 4.55357 8.67775 4.69467 8.42659 4.94584C8.17542 5.197 8.03432 5.53766 8.03432 5.89286ZM8.03432 15C8.03432 15.3552 8.17542 15.6959 8.42659 15.947C8.67775 16.1982 9.0184 16.3393 9.37361 16.3393C9.72881 16.3393 10.0695 16.1982 10.3206 15.947C10.5718 15.6959 10.7129 15.3552 10.7129 15C10.7129 14.6448 10.5718 14.3041 10.3206 14.053C10.0695 13.8018 9.72881 13.6607 9.37361 13.6607C9.0184 13.6607 8.67775 13.8018 8.42659 14.053C8.17542 14.3041 8.03432 14.6448 8.03432 15ZM8.03432 24.1071C8.03432 24.4623 8.17542 24.803 8.42659 25.0542C8.67775 25.3053 9.0184 25.4464 9.37361 25.4464C9.72881 25.4464 10.0695 25.3053 10.3206 25.0542C10.5718 24.803 10.7129 24.4623 10.7129 24.1071C10.7129 23.7519 10.5718 23.4113 10.3206 23.1601C10.0695 22.909 9.72881 22.7679 9.37361 22.7679C9.0184 22.7679 8.67775 22.909 8.42659 23.1601C8.17542 23.4113 8.03432 23.7519 8.03432 24.1071Z"
          fill="black"
          fill-opacity="0.85"
        />
      </g>
      <defs>
        <clipPath id="clip0_2098_27238">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Database
