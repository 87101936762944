import React, { useEffect, useState } from "react";
import { Divider, Paper, TableSortLabel, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Switch from "@mui/material/Switch";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { useOutletContext } from "react-router-dom";
import { OutletcontextType } from "../components/Layout/Index";
import Grid from "@mui/material/Grid";
import OpenPageIcon from "../Icons/OpenPageIcon";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  alladminUserApi,
  alladminUserSearch,
  allUserPermission,
} from "../service/apiActions/FaceAction";
import { useNavigate } from "react-router-dom";
import WrongPasswordModal from "../components/pages/UserManagement/WrongPasswordModal";
import SessionTimeOutModal from "../components/pages/UserManagement/SessionTimeOutModal";
// import {
//   TablePagination,
//   tablePaginationClasses as classes,
// } from "@mui/base/TablePagination";
// import { styled } from "@mui/system";
import TablePagination from "@mui/material/TablePagination";

const label = { inputProps: { "aria-label": "Switch demo" } };

// type resDataType = {
//   id: string;
//   name: string;
//   email: string;
//   designation: string;
//   contactNumber: string;
//   consoleAccessEnabled: string;
//   role: string;
// }[];

const UserManagement = () => {
  const { handleChangeTitle, showSnackbar } =
    useOutletContext<OutletcontextType>();
  const [opendailog, setOpendailog] = useState(" ");
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [searchTerm, setSearchTerm] = useState<any>([]);
  const [isActive, setIsActive] = useState(false);
  const [isRevoke, setIsRevoke] = useState(false);
  const [password, setPassword] = useState("");
  const [resData, setResData] = useState<any[]>([]);
  const [resSearchData, setResSearchData] = useState("");
  const [conformPassword, setConformPassword] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [error, setError] = useState(false);
  const [conformToggle, setConformToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1); // Page number
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page
  const [orderBy, setOrderBy] = useState<string>(""); // Column to be sorted
  const [order, setOrder] = useState<"asc" | "desc">("asc"); // Sorting order
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleConfirm();
    }
  };

  useEffect(() => {
    document.title = "AiFise | User Management";
    handleChangeTitle(
      "User Management ",
      " Control user access with the ability to effortlessly enable/disable users and manage their API permissions."
    );
  }, []);

  const navigate = useNavigate();

  function handleClick(id: string) {
    navigate(`access/${id}`);
  }

  useEffect(() => {
    if (resSearchData) {
      alladminUserSearch(resSearchData)
        .then((res: any) => {
          console.log(res.users);
          setSearchTerm(res?.users);
          console.log(searchTerm, "search");
        })
        .catch((err: any) => {
          console.log("res alladminUserApi err ", err);
        });
    } else {
      alladminUserApi()
        .then((res: any) => {
          console.log("res alladminUserApi ", res, "res[1]", res.users[1]);
          setResData(res.users);
          console.log("conformPassword", conformPassword);
        })
        .catch((err) => {
          console.log("res alladminUserApi err ", err);
        });
    }
  }, [conformToggle, resSearchData]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setResSearchData(value);
  };

  const handleSwitchChange = (e: any, row: any) => {
    setOpendailog(e.target.checked);
    setOpen(true);
    setError(false);
    setName(row?.name);
    setSelectedUser(row);
  };

  const handleClose = () => {
    setOpen(false);
    setIsRevoke(false);
    setIsActive(false);
    setPassword("");
  };

  const handleActivate = () => {
    setIsActive(true);
  };

  const handleRevoke = () => {
    setIsRevoke(true);
  };

  const handleConfirm = async () => {
    const body: any = {
      email: selectedUser.email,
      password: password,
      console_access: opendailog,
    };
    setLoading(true);
    try {
      await allUserPermission(body);
      setConformToggle(!conformToggle);
      setOpen(false);
      setIsRevoke(false);
      setIsActive(false);
      setPassword("");
      setLoading(false);
    } catch (err) {
      setError(true);
      setOpen(false);
      setIsActive(false);
      setIsRevoke(false);
      setPassword("");
      setLoading(false);
    }
  };

  const modalButtonLabel = opendailog
    ? isActive
      ? "Activate"
      : "Confirm"
    : isRevoke
    ? "Revoke"
    : "Confirm";

  // Handlers for changing the page and rowsPerPage
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage + 1); // Adjusting the page number
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const createSortHandler = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div style={{ marginTop: "" }}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <TextField
            size="small"
            sx={{ marginBottom: "0px", borderRadius: "" }}
            variant="outlined"
            value={resSearchData}
            onChange={handleSearch}
            placeholder="Search User List"
            InputProps={{
              endAdornment: (
                <SearchIcon sx={{ color: "gray", marginRight: 1 }} />
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={2}>
        <Grid item xs={12}>
          <Paper>
            <TableContainer sx={{ marginTop: "16px", marginBottom: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "#fafafa",
                      borderBottom: "1px solid rgba(0,0,0,0.06)",
                    }}
                  >
                    <TableCell width={"20%"}>
                      <TableSortLabel
                        active={orderBy === "name"}
                        direction={orderBy === "name" ? order : "asc"}
                        onClick={() => createSortHandler("name")}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell width={"20%"}>Company</TableCell>
                    <TableCell width={"20%"}>Designation</TableCell>
                    <TableCell width={"20%"}>Access Level</TableCell>
                    <TableCell width={"20%"}>Access</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(resSearchData.length !== 0 ? searchTerm : resData)
                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                    .sort((a: any, b: any) => {
                      if (order === "asc") {
                        return a[orderBy]?.localeCompare(b[orderBy]);
                      } else {
                        return b[orderBy]?.localeCompare(a[orderBy]);
                      }
                    })
                    .map((row: any) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          <div
                            className="user-name"
                            style={{
                              cursor: "pointer",
                              color: "blue",
                              textDecoration: "underline",
                            }}
                            onClick={() => handleClick(row.id)}
                          >
                            {row.name}
                          </div>
                          {/* <Button  sx={{
                            height:"24px", width:"10px"
                          }}>
                            <OpenPageIcon />
                          </Button> */}
                        </TableCell>
                        <TableCell>{row.company}</TableCell>
                        <TableCell>{row.designation}</TableCell>
                        <TableCell>{row.role}</TableCell>
                        <TableCell>
                          <Switch
                            {...label}
                            checked={row.consoleAccessEnabled}
                            onChange={(e: any) => handleSwitchChange(e, row)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <CustomTablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              count={resData.length}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingRight: "5px",
              }}
            /> */}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={resData.length}
              page={page - 1}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Dialog
            open={open}
            onClose={(event: any, reason: any) => {
              if (reason !== "backdropClick") {
                handleClose();
              }
            }}
            disableEscapeKeyDown={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
              "& .MuiDialog-paper": {
                width: "100%",
                maxWidth: "500px",
                borderRadius: "0px",
                padding: "0px",
                margin: "0px",
                maxHeight: "500px",
              },
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                display: "flex",
                alignItems: "center",
                paddingX: "24px",
                fontSize: "16px",
                fontWeight: 600,
                color: "rgba(0, 0, 0, 0.85)",
              }}
            >
              {opendailog ? "Enable User Access" : " Revoke User Access"}
            </DialogTitle>
            <Divider sx={{ marginTop: "-2px" }} />
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {opendailog ? (
                  <div>
                    <div
                      style={{
                        color: "#333",
                        fontSize: "13px",
                        fontWeight: 400,
                        fontStyle: "normal",
                      }}
                    >
                      {" "}
                      Are you sure you want to enable access to {name}?
                    </div>
                    {isActive ? (
                      <>
                        <Typography
                          sx={{
                            mb: 1,
                            mt: 1.5,
                            fontWeight: "700",
                            color: "#333",
                            fontSize: "13px",
                            fontStyle: "normal",
                          }}
                        >
                          {" "}
                          Confirm your identity by entering your password.
                        </Typography>

                        {/* <TextField
                          id="filled-password-input"
                          type="password"
                          autoComplete="current-password"
                          placeholder="Password"
                          value={password}
                          required
                          onChange={(e) => setPassword(e.target.value)}
                          sx={{
                            width: "100%",
                            borderBottom: "none",
                            backgroundColor: "white",
                            "& input": {
                              backgroundColor: "white", // Text color
                            },
                          }}
                        /> */}
                        <FormControl sx={{ width: "100%" }} variant="outlined">
                          <OutlinedInput
                            size="small"
                            sx={{ width: "221px", mb: 1.7 }}
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={handleKeyDown}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        color: "#333",
                        fontSize: "13px",
                        fontWeight: 400,
                        fontStyle: "normal",
                      }}
                    >
                      Are you sure you want to revoke access to {name}?
                    </div>
                    {isRevoke ? (
                      <>
                        <Typography
                          sx={{
                            mb: 1,
                            mt: 1.5,
                            fontWeight: "700",
                            color: "#333",
                            fontSize: "13px",
                            fontStyle: "normal",
                          }}
                        >
                          {" "}
                          Confirm your identity by entering your password.
                        </Typography>

                        {/* <TextField
                          id="filled-password-input"
                          type="password"
                          autoComplete="current-password"
                          placeholder="Password"
                          value={password}
                          required
                          onChange={(e) => setPassword(e.target.value)}
                          sx={{
                            width: "100%",
                            borderBottom: "none",
                            backgroundColor: "white",
                            "& input": {
                              backgroundColor: "white", // Text color
                            },
                          }}
                        /> */}
                        <FormControl sx={{ width: "100%" }} variant="outlined">
                          <OutlinedInput
                            size="small"
                            sx={{ width: "221px", mb: 1.7 }}
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={handleKeyDown}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </>
                    ) : null}
                  </div>
                )}
              </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ mt: 1 }}>
              <Button
                onClick={handleClose}
                sx={{
                  borderRadius: "2px",
                  border: "1px solid #d9d9d9",
                  color: "rgba(0, 0, 0, 0.85)",
                  fontWeight: 400,
                  fontSize: "14px",
                  padding: "4px 24px",
                  textTransform: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                sx={
                  opendailog
                    ? {
                        backgroundColor: "#52C41A",
                        borderRadius: "2px",
                        border: "1px solid #52C41A",
                        background: "#52C41A",
                        fontSize: "14px",
                        fontWeight: 400,
                        padding: "4px 24px",
                        textTransform: "none",
                        color: "white",
                        "&:hover": { color: "white", backgroundColor: "green" },
                      }
                    : {
                        backgroundColor: "#FF4D4F",
                        borderRadius: "2px",
                        textTransform: "none",
                        padding: "4px 24px",
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "white",
                        "&:hover": { color: "white", backgroundColor: "red" },
                      }
                }
                style={{
                  borderRadius: "2px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
                onClick={
                  opendailog
                    ? isActive
                      ? handleConfirm
                      : handleActivate
                    : isRevoke
                    ? handleConfirm
                    : handleRevoke
                }
                className="hover"
              >
                {<div>{loading ? "Loading..." : modalButtonLabel}</div>}
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
      {error ? <WrongPasswordModal open={error} /> : ""}

      <style>
        {`.css-1yazjtg-MuiInputBase-root-MuiFilledInput-root{
     background-color:white
  }`}
        {`
    @media (min-width: 1536px) {
      .css-vi59no {
       width: 100%; 

      margin-bottom: 30px;
      }
    }

  `}
      </style>
    </div>
  );
};

// const CustomTablePagination = styled(TablePagination)`
//   & .${classes.toolbar} {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     gap: 10px;

//     @media (min-width: 768px) {
//       flex-direction: row;
//       align-items: center;
//     }
//   }

//   & .${classes.selectLabel} {
//     margin: 0;
//   }

//   & .${classes.displayedRows} {
//     margin: 0;

//     @media (min-width: 768px) {
//       margin-left: auto;
//     }
//   }

//   & .${classes.spacer} {
//     display: none;
//   }

//   & .${classes.actions} {
//     display: flex;
//     gap: 0.25rem;
//   }
// `;

export default UserManagement;
