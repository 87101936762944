import React from 'react'

const Gist3 = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 30C4.75 30 2.5 27.75 2.5 25V12.5C2.5 9.75 4.75 7.5 7.5 7.5H27.5C30.25 7.5 32.5 9.75 32.5 12.5V25C32.5 27.75 30.25 30 27.5 30H22.5V37.5L15 30H7.5ZM52.5 45C55.25 45 57.5 42.75 57.5 40V27.5C57.5 24.75 55.25 22.5 52.5 22.5H37.5V25C37.5 30.5 33 35 27.5 35V40C27.5 42.75 29.75 45 32.5 45H37.5V52.5L45 45H52.5Z"
        fill="black"
        fill-opacity="0.85"
      />
    </svg>
  );
}

export default Gist3
