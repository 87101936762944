import { Link, useOutletContext } from "react-router-dom";
import AndroidIcon from "../Icons/AndroidIcon";
import AppleIcon from "../Icons/AppleIcon";
import SwaggerIcon from "../Icons/SwaggerIcon";

export const prod_info = [
  {
    id: "inno-face-liveliness",
    name: "Face Liveliness",
    android_download: {
      icon: (
        <a href="https://drive.google.com/file/d/1QkhDGLLq46sWoU1sFKna_xAhFhsdN4ic/view?pli=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AndroidIcon />
        </a>
      ),
    },
    apple_doc_url: {
      icon: (
        <a
          href="https://docs.google.com/document/d/1GxlKsEGz-xdVRk8hxN3Ys8Ee3vHhLIXhSKcofRNE3m0/edit?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AppleIcon />
        </a>
      ),
    },
    android_doc_url: {
      icon: (
        <a
          href="https://docs.google.com/document/d/1aJ2VE3tyLx8L5CKT6RoqB_LlTs9TdbZD-6EB125iGZc/edit"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AndroidIcon />
        </a>
      ),
    },
    swagger_url: {
      icon: (
        <Link to="api-docs/inno-face-liveliness">
          <SwaggerIcon />
        </Link>
      ),
    },
  },
  {
    id: "inno-face-liveliness",
    name: "Face Recognition",
    android_download: {
      icon: (
        <a href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* <AndroidIcon /> */}
        </a>
      ),
    },
    apple_doc_url: {
      icon: (
        <a
          href="https://docs.google.com/document/d/150Ljq5A_Jm8ORy4GYzLYMSTmttO_Bbg8U4BGx6lnCAY/edit"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AppleIcon />
        </a>
      ),
    },
    android_doc_url: {
      icon: (
        <a
          href="https://docs.google.com/document/d/1NgAKYkXk3d1uh38ig95xF_-DKn6TnUhatKH2tBc4RmA/edit"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AndroidIcon />
        </a>
      ),
    },
    swagger_url: {
      icon: (
        <Link to="api-docs/inno-face-recognition">
          <SwaggerIcon />
        </Link>
      ),
    },
  },
  {
    id: "inno-fm",
    name: "Face Match",
    android_download: {
      icon: (
        <a href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* <AndroidIcon /> */}
        </a>
      ),
    },
    apple_doc_url: {
      icon: (
        <a
          href="https://docs.google.com/document/d/1XYLTOGAiTgt9vea_bLcRCpXV_f65-IMUJZUj4r2MoI4/edit"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AppleIcon />
        </a>
      ),
    },
    android_doc_url: {
      icon: (
        <a
          href="https://docs.google.com/document/d/19UGspalohQlfkfFR5jygNiVyD-AKsAJq1VjnY-1U-E0/edit"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AndroidIcon />
        </a>
      ),
    },
    swagger_url: {
      icon: (
        <Link to="api-docs/inno-fm">
          <SwaggerIcon />
        </Link>
      ),
    },
  },
  {
    id: "inno-blur-detector",
    name: "Blur Detector",
    android_download: {
      icon: (
        <a href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* <AndroidIcon /> */}
        </a>
      ),
    },
    apple_doc_url: {
      icon: (
        <a
          href="https://docs.google.com/document/d/1aCgYKulY_BKWoActI96Nrepz2MwVQOeWgVnO9ogvrio/edit"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AppleIcon />
        </a>
      ),
    },
    android_doc_url: {
      icon: (
        <a
          href="https://docs.google.com/document/d/1R6ejoPlEpUVaD13xs8M642hLOKuS9dEBnZwPoifCmTQ/edit"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AndroidIcon />
        </a>
      ),
    },
    swagger_url: {
      icon: (
        <Link to="api-docs/inno-blur-detector">
          <SwaggerIcon />
        </Link>
      ),
    },
  },
  {
    id: "inno-aadhar-ekyc",
    name: "Aadhar Offline eKYC",
    android_download: {
      icon: (
        <a href="https://drive.google.com/file/d/1_KeXESf1Ix4KAbly7J4czANZ7ClMIcJL/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AndroidIcon />
        </a>
      ),
    },
    apple_doc_url: {
      icon: (
        <a
          href="https://docs.google.com/document/d/1WRgn6mDP5BXWktJSPxtg8oQpFcC1T4nJgjNay256AHY/edit?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AppleIcon />
        </a>
      ),
    },
    android_doc_url: {
      icon: (
        <a
          href="https://docs.google.com/document/d/1FFZJLaZpftBcxO2b9tRaEZBKNSVYntVzmdBIaM52euY/edit"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AndroidIcon />
        </a>
      ),
    },
    swagger_url: {
      icon: (
        <Link to="api-docs/inno-aadhar-ekyc">
          <SwaggerIcon />
        </Link>
      ),
    },
  },
  {
    id: "inno-doc-scanner",
    name: "Document Scanner",
    android_download: {
      icon: (
        <a href="https://drive.google.com/file/d/1rB0MAVzmFX5MZxfFbungISFvbZn3_FaH/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AndroidIcon />
        </a>
      ),
    },
    apple_doc_url: {
      icon: (
        <a
          href="https://docs.google.com/document/d/1SIAHvuOup1noasDShiS-IORLOEqVSTnIkFtdPq09ZP0/edit"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AppleIcon />
        </a>
      ),
    },
    android_doc_url: {
      icon: (
        <a
          href="https://docs.google.com/document/d/19T2RHKrwkj3wqe6SS8D1cqwZKH0wKFgyKSg74lnDqLg/edit?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AndroidIcon />
        </a>
      ),
    },
    swagger_url: {
      icon: (
        <Link to="api-docs/inno-doc-scanner">
          <SwaggerIcon />
        </Link>
      ),
    },
  },
  {
    id: "inno-digilocker-setu",
    name: "Digilocker (Setu)",
    android_download: {
      icon: (
        <a href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* <AndroidIcon /> */}
        </a>
      ),
    },
    apple_doc_url: {
      icon: (
        <a
          href="https://docs.google.com/document/d/1abKOQIIlAeVpokzvrE6xM21DS32qbOln1002tvL7iwc/edit"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AppleIcon />
        </a>
      ),
    },
    android_doc_url: {
      icon: (
        <a
          href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* <AndroidIcon /> */}
        </a>
      ),
    },
    swagger_url: {
      icon: (
        <Link to="api-docs/inno-digilocker-setu">
          <SwaggerIcon />
        </Link>
      ),
    },
  },
  {
    id: "inno-digilocker-setu",
    name: "Digilocker (Browser)",
    android_download: {
      icon: (
        <a href="https://drive.google.com/file/d/1OqMmKFFq78e2E-1n3lVrFQhhTXvgPKBU/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AndroidIcon />
        </a>
      ),
    },
    apple_doc_url: {
      icon: (
        <a
          href="https://docs.google.com/document/d/1k8BwgGm5ovqfbhwoN_1WA4LJyQVH47rqqfyfDe855c0/edit"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AppleIcon />
        </a>
      ),
    },
    android_doc_url: {
      icon: (
        <a
          href="https://docs.google.com/document/d/1WJSzoQS9qVgWlm2IcPpmhe1rHKCzPWs7-GUkg6Ki4kk/edit"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AndroidIcon />
        </a>
      ),
    },
    swagger_url: {
      icon: (
        <Link to="api-docs/inno-digilocker-browser">
          <SwaggerIcon />
        </Link>
      ),
    },
  },
  {
    id: "inno-esign-setu",
    name: "Aadhar E-Sign (Setu)",
    android_download: {
      icon: (
        <a href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* <AndroidIcon /> */}
        </a>
      ),
    },
    apple_doc_url: {
      icon: (
        <a
          href="https://docs.google.com/document/d/1dybYQlCbYgSom1oRxpumurzh0sF4KScHTmKVHLHmgng/edit?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AppleIcon />
        </a>
      ),
    },
    android_doc_url: {
      icon: (
        <a
          href="https://docs.google.com/document/d/1iQO6AdDed2RQAUbarB6f1Fq4rDL0pVtam9KMWgsGgBM/edit"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AndroidIcon />
        </a>
      ),
    },
    swagger_url: {
      icon: (
        <Link to="api-docs/inno-esign-setu">
          <SwaggerIcon />
        </Link>
      ),
    },
  },
  {
    id: "inno-penny-drop",
    name: "Penny Drop",
    android_download: {
      icon: (
        <a href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* <AndroidIcon /> */}
        </a>
      ),
    },
    apple_doc_url: {
      icon: (
        <a
          href="https://docs.google.com/document/d/1kTLDPsmwR86M_XW82-sLWeCreYf2tC1rweycmybl0wA/edit?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AppleIcon />
        </a>
      ),
    },
    android_doc_url: {
      icon: (
        <a
          href="https://docs.google.com/document/d/17IACZTwmj8tI00nTU8WxY_7_qF4Nt9QKK3B8WCLCBIo/edit"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AndroidIcon />
        </a>
      ),
    },
    swagger_url: {
      icon: (
        <Link to="api-docs/inno-penny-drop">
          <SwaggerIcon />
        </Link>
      ),
    },
  },
  {
    id: "inno-onboarding",
    name: "Onboard SDK",
    android_download: {
      icon: (
        <a href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* <AndroidIcon /> */}
        </a>
      ),
    },
    apple_doc_url: {
      icon: (
        <a
          href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* <AppleIcon /> */}
        </a>
      ),
    },
    android_doc_url: {
      icon: (
        <a
          href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* <AndroidIcon /> */}
        </a>
      ),
    },
    swagger_url: {
      icon: (
        <Link to="api-docs/inno-onboarding">
          <SwaggerIcon />
        </Link>
      ),
    },
  },  
]

// export const prod_info = [
//   {
//     id: "inno-fm",
//     name: "Face Match",
//     android_download: {
//       icon: (
//         <a href="https://drive.google.com/file/d/1QkhDGLLq46sWoU1sFKna_xAhFhsdN4ic/view?usp=sharing"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     apple_doc_url: {
//       icon: (
//         <a
//           href="https://docs.google.com/document/d/1GxlKsEGz-xdVRk8hxN3Ys8Ee3vHhLIXhSKcofRNE3m0/edit?usp=sharing"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           <AppleIcon />
//         </a>
//       ),
//     },
//     android_doc_url: {
//       icon: (
//         <a
//           href="https://docs.google.com/document/d/1aJ2VE3tyLx8L5CKT6RoqB_LlTs9TdbZD-6EB125iGZc/edit?usp=sharing"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     swagger_url: {
//       icon: (
//         <Link to="api-docs/inno-fm">
//           <SwaggerIcon />
//         </Link>
//       ),
//     },
//   },
//   {
//     id: "doc-scanner",
//     name:"Document Scanner",
//     android_download: {
//       icon: (
//         <a
//           href="https://drive.google.com/file/d/1rB0MAVzmFX5MZxfFbungISFvbZn3_FaH/view?usp=sharing"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     apple_doc_url: {
//       icon: (
//         <a
//           href="https://docs.google.com/document/d/1SIAHvuOup1noasDShiS-IORLOEqVSTnIkFtdPq09ZP0/edit"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           <AppleIcon />
//         </a>
//       ),
//     },
//     android_doc_url: {
//       icon: (
//         <a
//           href="https://docs.google.com/document/d/19T2RHKrwkj3wqe6SS8D1cqwZKH0wKFgyKSg74lnDqLg/edit?usp=sharing"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     swagger_url: {
//       icon: (
//         <Link to="api-docs/doc-scanner">
//           <SwaggerIcon />
//         </Link>
//       ),
//     },
//   },
//   {
//     id: "inno-ocr",
//     android_download: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     apple_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AppleIcon />
//         </a>
//       ),
//     },
//     android_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     swagger_url: {
//       icon: (
//         <Link to="api-docs/inno-ocr">
//           <SwaggerIcon />
//         </Link>
//       ),
//     },
//   },
//   {
//     id: "inno-face-analysis",
//     android_download: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     apple_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AppleIcon />
//         </a>
//       ),
//     },
//     android_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     swagger_url: {
//       icon: (
//         <Link to="api-docs/inno-face-analysis">
//           <SwaggerIcon />
//         </Link>
//       ),
//     },
//   },
//   {
//     id: "inno-aadhar-masking",
//     android_download: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     apple_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AppleIcon />
//         </a>
//       ),
//     },
//     android_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     swagger_url: {
//       icon: (
//         <Link to="api-docs/inno-aadhar-masking">
//           <SwaggerIcon />
//         </Link>
//       ),
//     },
//   },
//   {
//     id: "inno-gist",
//     android_download: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     apple_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AppleIcon />
//         </a>
//       ),
//     },
//     android_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     swagger_url: {
//       icon: (
//         <Link to="api-docs/inno-gist">
//           <SwaggerIcon />
//         </Link>
//       ),
//     },
//   },
//   {
//     id: "inno-ckyc-cropping",
//     android_download: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     apple_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AppleIcon />
//         </a>
//       ),
//     },
//     android_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     swagger_url: {
//       icon: (
//         <Link to="api-docs/inno-ckyc-cropping">
//           <SwaggerIcon />
//         </Link>
//       ),
//     },
//   },
//   {
//     id: "inno-video-kyc",
//     android_download: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     apple_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AppleIcon />
//         </a>
//       ),
//     },
//     android_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     swagger_url: {
//       icon: (
//         <Link to="api-docs/inno-video-kyc">
//           <SwaggerIcon />
//         </Link>
//       ),
//     },
//   },
//   {
//     id: "inno-vid-verification",
//     android_download: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     apple_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AppleIcon />
//         </a>
//       ),
//     },
//     android_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     swagger_url: {
//       icon: (
//         <Link to="api-docs/inno-vid-verification">
//           <SwaggerIcon />
//         </Link>
//       ),
//     },
//   },
//   {
//     id: "inno-spam-classifier",
//     android_download: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     apple_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AppleIcon />
//         </a>
//       ),
//     },
//     android_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     swagger_url: {
//       icon: (
//         <Link to="api-docs/inno-spam-classifier">
//           <SwaggerIcon />
//         </Link>
//       ),
//     },
//   },
//   {
//     id: "inno-moderation",
//     android_download: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     apple_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AppleIcon />
//         </a>
//       ),
//     },
//     android_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     swagger_url: {
//       icon: (
//         <Link to="api-docs/inno-moderation">
//           <SwaggerIcon />
//         </Link>
//       ),
//     },
//   },
//   {
//     id: "inno-address-match",
//     android_download: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     apple_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AppleIcon />
//         </a>
//       ),
//     },
//     android_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     swagger_url: {
//       icon: (
//         <Link to="api-docs/inno-address-match">
//           <SwaggerIcon />
//         </Link>
//       ),
//     },
//   },
//   {
//     id: "inno-face-liveness",
//     android_download: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     apple_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AppleIcon />
//         </a>
//       ),
//     },
//     android_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     swagger_url: {
//       icon: (
//         <Link to="api-docs/inno-face-liveness">
//           <SwaggerIcon />
//         </Link>
//       ),
//     },
//   },
//   {
//     id: "inno-sdks",
//     android_download: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     apple_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AppleIcon />
//         </a>
//       ),
//     },
//     android_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     swagger_url: {
//       icon: (
//         <Link to="api-docs/inno-sdks">
//           <SwaggerIcon />
//         </Link>
//       ),
//     },
//   },
//   {
//     id: "inno-face-search",
//     android_download: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     apple_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AppleIcon />
//         </a>
//       ),
//     },
//     android_doc_url: {
//       icon: (
//         <a href="" target="_blank" rel="noopener noreferrer">
//           <AndroidIcon />
//         </a>
//       ),
//     },
//     swagger_url: {
//       icon: (
//         <Link to="api-docs/inno-face-search">
//           <SwaggerIcon />
//         </Link>
//       ),
//     },
//   },
// ];
