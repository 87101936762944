import React from 'react'

const Shield = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 30 31"
      fill="none"
    >
      <path
        d="M15 3.30078L5 7.05078V14.6633C5 20.9758 9.2625 26.8633 15 28.3008C20.7375 26.8633 25 20.9758 25 14.6633V7.05078L15 3.30078ZM22.5 14.6633C22.5 19.6633 19.3125 24.2883 15 25.7008C10.6875 24.2883 7.5 19.6758 7.5 14.6633V8.78828L15 5.97578L22.5 8.78828V14.6633Z"
        fill="black"
      />
    </svg>
  );
}

export default Shield
