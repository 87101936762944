// import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { Button, } from '@mui/material'
import type { ButtonProps } from '@mui/material'

const ColorPrimary = {
    background: "#007AFE",
    borderColor: "#007AFE",
    "&:hover": {
        background: "#0262CB",
        borderColor: "#0262CB",
        boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)"
    }
}

const ColorSuccess = {
    background: "#38B832",
    color: "white",
    "&:hover": {
        background: "#298E35",
        borderColor: "298E35",
        boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)"
    }
}

const OutlinedPrimary = {
    borderColor: '#D9D9D9',
    color: "rgba(0, 0, 0, 0.85)",
    "&:hover": {
        background: "#FFF",
        borderColor: "#0262CB",
        color: "#0262CB"
    }
}

const CustomButton = ({ sx, children, variant = "contained", color = "primary", ...rest }: ButtonProps) => {
    const customStyle = (variant === 'contained' && color === "primary") ?
        ColorPrimary
        : (variant === 'contained' && color === "success") ?
            ColorSuccess :
            OutlinedPrimary;
    return (
        <Button
            {...rest}
            sx={{
                border: "1px solid",
                textTransform: "capitalize",
                borderRadius: 0.5,
                boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)",
                ...customStyle,
                ...sx,
            }}
            variant={variant}
            color={color}
        >
            {children}
        </Button >
    )
}

// export const CustomLoadingButton = ({loading, sx, children, variant = "contained", color = "primary", ...rest }: LoadingButtonProps) => {
//     const customStyle = (variant === 'contained' && color === "primary") ?
//         ColorPrimary
//         : (variant === 'contained' && color === "success") ?
//             ColorSuccess :
//             OutlinedPrimary;
//     return (
//         <LoadingButton
//             {...rest}
//             sx={{
//                 border: "1px solid",
//                 textTransform: "capitalize",
//                 borderRadius: 0.5,
//                 boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)",
//                 ...customStyle,
//                 ...sx,
//             }}
//             variant={variant}
//             color={color}
//         >
//             {loading ? "Loading..." : children}
//         </LoadingButton >
//     )
// }

export default CustomButton