const config = {
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_IDENTIY_POOL_ID,
    region: "ap-south-1", //process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_AWS_S3_BUCKET,
      region: "ap-south-1", //process.env.REACT_APP_AWS_REGION,
      identityPoolId: process.env.REACT_APP_AWS_IDENTIY_POOL_ID,
    },
  },
};
export default config;
