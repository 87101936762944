import { resolve } from "path"
import api from "../api"
import { ocr } from "../apiVariables"
import { rejects } from "assert"

export const imageOcr = (body: any) => {
    return new Promise((resolve, reject) => {
        api({ ...ocr.imageAutoOcr, body }).then((res) => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })
    })
}

export const aadharMaskingApi = (body: any, extraParams:any)=>{
    return new Promise((resolve, reject) => {
        api({ ...ocr.aadharMasking, body, extraParams }).then((res) => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })
    })
}

export const voterID = (body: any) => {
    return new Promise((resolve, reject) => {
          api({...ocr.voterId, body}).then((res) => {
            resolve(res)
          }).catch((error)=> {
                reject(error)
          }) 
    })   
} 