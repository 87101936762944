import React from 'react'

const Nsfw3 = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.9966 22.3332C52.677 24.2691 53.0973 26.2867 53.2466 28.3332H29.1466L13.8299 46.7832C10.0351 43.0849 7.61211 38.2051 6.95954 32.9465C6.30696 27.688 7.46368 22.3639 10.2394 17.8502C13.0151 13.3365 17.2445 9.90199 22.2318 8.11171C27.2191 6.32142 32.6673 6.28199 37.6799 7.9999C37.8561 6.89444 38.1812 5.81797 38.6466 4.7999C32.9238 2.82108 26.699 2.8412 20.9891 4.85697C15.2792 6.87273 10.4214 10.765 7.20927 15.8981C3.99713 21.0313 2.6204 27.1019 3.30394 33.1185C3.98747 39.1351 6.69088 44.7421 10.9726 49.0239C15.2544 53.3056 20.8614 56.009 26.878 56.6926C32.8947 57.3761 38.9652 55.9994 44.0984 52.7872C49.2315 49.5751 53.1238 44.7174 55.1395 39.0074C57.1553 33.2975 57.1754 27.0728 55.1966 21.3499C54.1794 21.821 53.1029 22.1518 51.9966 22.3332ZM29.9966 53.3332C25.1112 53.3308 20.3513 51.785 16.3966 48.9166L30.7133 31.6666H53.2466C52.8255 37.5473 50.1938 43.0506 45.8806 47.0701C41.5673 51.0896 35.8924 53.3272 29.9966 53.3332Z"
        fill="black"
      />
      <path
        d="M49.9974 18.3334C54.5998 18.3334 58.3307 14.6025 58.3307 10.0001C58.3307 5.39771 54.5998 1.66675 49.9974 1.66675C45.395 1.66675 41.6641 5.39771 41.6641 10.0001C41.6641 14.6025 45.395 18.3334 49.9974 18.3334Z"
        fill="black"
      />
    </svg>
  );
}

export default Nsfw3
