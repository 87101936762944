import React from 'react'

const Pdf = () => {
  return (
    <svg
      width="242"
      height="60"
      viewBox="0 0 121 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9984 7.07344L14.4875 0.499219C14.382 0.39375 14.2672 0.304688 14.143 0.229688C14.1102 0.210938 14.0773 0.192188 14.0422 0.175781C14.0211 0.164062 13.9977 0.154688 13.9766 0.145313C13.7656 0.0515625 13.5336 0 13.2969 0H5.21094C4.27813 0 3.5 0.754688 3.5 1.6875V22.3125C3.5 23.2453 4.27813 24 5.21094 24H19.8125C20.7453 24 21.4766 23.2453 21.4766 22.3125V8.26172C21.4766 7.81641 21.3125 7.38984 20.9984 7.07344ZM14.1641 2.5875L18.8703 7.3125H14.1641V2.5875ZM19.7891 22.3125H5.1875V1.6875H12.4766V7.3125C12.4766 8.24531 13.2547 9 14.1875 9H19.7891V22.3125ZM8.70312 16.3242C8.70312 16.8914 8.52734 17.0602 8.18984 17.0602C7.97891 17.0602 7.75859 16.9242 7.60859 16.6266L6.89609 17.1562C7.21016 17.693 7.65313 17.9578 8.33281 17.9578C9.30781 17.9578 9.75781 17.257 9.75781 16.4062V13.5234H8.70312V16.3242ZM12.2492 13.5234H10.7422V17.8828H11.7734V16.4297H12.2797C13.1961 16.4297 13.993 15.9703 13.993 14.9391C13.993 13.8656 13.2078 13.5234 12.2492 13.5234ZM12.2305 15.6094H11.7734V14.3672H12.2C12.7039 14.3672 12.9828 14.5125 12.9828 14.9508C12.9828 15.375 12.7367 15.6094 12.2305 15.6094ZM16.5078 15.3984V16.2422H17.2109V16.9477C17.1172 17.0156 16.9531 17.0578 16.7961 17.0578C15.9922 17.0578 15.6078 16.5562 15.6078 15.6938C15.6078 14.8477 16.0695 14.3484 16.7117 14.3484C17.0703 14.3484 17.2977 14.4938 17.5086 14.6859L18.0641 14.0227C17.7664 13.7227 17.3117 13.4555 16.6766 13.4555C15.5141 13.4555 14.5414 14.2828 14.5414 15.7289C14.5414 17.1984 15.4789 17.9578 16.6859 17.9578C17.293 17.9578 17.8391 17.7188 18.1273 17.4281V15.3984H16.5078Z"
        fill="black"
        fill-opacity="0.85"
      />
      <g clip-path="url(#clip0_1969_19425)">
        <path
          d="M45.5585 11.4241H44.7415C44.6183 11.4241 44.5165 11.5232 44.5165 11.6491V17.5714C44.5165 17.6946 44.6156 17.7964 44.7415 17.7964H45.5585C45.6817 17.7964 45.7835 17.6973 45.7835 17.5714V11.6491C45.7808 11.5259 45.6817 11.4241 45.5585 11.4241ZM43.571 14.3571H41.2111C41.0879 14.3571 40.9861 14.4563 40.9861 14.5821V15.2196C40.9861 15.3429 41.0853 15.4446 41.2111 15.4446H42.4861V15.4634C42.4701 16.2643 41.8701 16.7973 40.9728 16.7973C39.9228 16.7973 39.2692 15.975 39.2692 14.617C39.2692 13.275 39.9094 12.458 40.9379 12.458C41.6906 12.458 42.2103 12.8196 42.4219 13.4839L42.446 13.5589H43.7638L43.7451 13.4357C43.5335 12.1045 42.4299 11.25 40.9379 11.25C39.0951 11.25 37.9111 12.5732 37.9111 14.6223C37.9111 16.6982 39.0817 18 40.954 18C42.679 18 43.7933 16.9205 43.7933 15.2437V14.5795C43.7933 14.4563 43.6942 14.3571 43.571 14.3571Z"
          fill="black"
          fill-opacity="0.85"
        />
        <path
          d="M53.6776 6.01607L47.9133 0.251786C47.7526 0.0910715 47.5357 0 47.308 0H35.9294C35.4553 0 35.0723 0.383036 35.0723 0.857143V23.1429C35.0723 23.617 35.4553 24 35.9294 24H53.0723C53.5464 24 53.9294 23.617 53.9294 23.1429V6.62411C53.9294 6.39643 53.8383 6.17679 53.6776 6.01607ZM46.9116 1.97679L51.9526 7.01786H46.9116V1.97679ZM52.0008 22.0714H37.0008V1.92857H45.0901V7.71429C45.0901 8.01265 45.2086 8.2988 45.4196 8.50978C45.6306 8.72076 45.9168 8.83929 46.2151 8.83929H52.0008V22.0714Z"
          fill="black"
          fill-opacity="0.85"
        />
        <path
          d="M47.0775 17.7774H47.9427C48.0659 17.7774 48.1677 17.6783 48.1677 17.5524V15.281H50.5195C50.6427 15.281 50.7445 15.1819 50.7445 15.056V14.373C50.7445 14.2498 50.6454 14.148 50.5195 14.148H48.1677V12.5703H50.7606C50.8838 12.5703 50.9856 12.4712 50.9856 12.3453V11.6274C50.9856 11.5042 50.8865 11.4024 50.7606 11.4024H47.0775C46.9543 11.4024 46.8525 11.5015 46.8525 11.6274V17.5497C46.8525 17.6783 46.9543 17.7774 47.0775 17.7774Z"
          fill="black"
          fill-opacity="0.85"
        />
      </g>
      <g clip-path="url(#clip1_1969_19425)">
        <path
          d="M77.6017 11.9223L75.5178 14.5795L74.4169 13.1759C74.3969 13.1503 74.3713 13.1296 74.342 13.1153C74.3128 13.1011 74.2807 13.0937 74.2482 13.0937C74.2156 13.0937 74.1835 13.1011 74.1543 13.1153C74.1251 13.1296 74.0995 13.1503 74.0794 13.1759L71.4062 16.583C71.3815 16.6146 71.3661 16.6525 71.3619 16.6924C71.3577 16.7323 71.3648 16.7726 71.3824 16.8086C71.4 16.8447 71.4274 16.875 71.4615 16.8962C71.4955 16.9175 71.5348 16.9287 71.5749 16.9286H81.4294C81.6089 16.9286 81.708 16.7223 81.5982 16.583L77.9419 11.9223C77.9216 11.8967 77.8957 11.8759 77.8663 11.8617C77.8368 11.8475 77.8045 11.8401 77.7718 11.8401C77.7391 11.8401 77.7068 11.8475 77.6773 11.8617C77.6479 11.8759 77.622 11.8967 77.6017 11.9223ZM72.4294 10.125C72.4294 10.4092 72.5423 10.6817 72.7432 10.8826C72.9442 11.0835 73.2167 11.1964 73.5008 11.1964C73.785 11.1964 74.0575 11.0835 74.2585 10.8826C74.4594 10.6817 74.5723 10.4092 74.5723 10.125C74.5723 9.84084 74.4594 9.56832 74.2585 9.36739C74.0575 9.16645 73.785 9.05357 73.5008 9.05357C73.2167 9.05357 72.9442 9.16645 72.7432 9.36739C72.5423 9.56832 72.4294 9.84084 72.4294 10.125ZM85.6776 6.01607L79.9133 0.251786C79.7526 0.0910715 79.5357 0 79.308 0H67.9294C67.4553 0 67.0723 0.383036 67.0723 0.857143V23.1429C67.0723 23.617 67.4553 24 67.9294 24H85.0723C85.5464 24 85.9294 23.617 85.9294 23.1429V6.62411C85.9294 6.39643 85.8383 6.17679 85.6776 6.01607ZM83.9526 7.01786H78.9116V1.97679L83.9526 7.01786ZM84.0008 22.0714H69.0008V1.92857H77.0901V7.71429C77.0901 8.01265 77.2086 8.2988 77.4196 8.50978C77.6306 8.72076 77.9168 8.83929 78.2151 8.83929H84.0008V22.0714Z"
          fill="black"
          fill-opacity="0.85"
        />
      </g>
      <g clip-path="url(#clip2_1969_19425)">
        <path
          d="M109.016 13.6714L109.024 13.6339C109.179 12.9938 109.375 12.1955 109.222 11.4723C109.12 10.9018 108.7 10.6795 108.341 10.6634C107.918 10.6446 107.54 10.8857 107.446 11.2366C107.269 11.8795 107.427 12.758 107.717 13.8777C107.352 14.7455 106.771 16.0071 106.345 16.7571C105.552 17.167 104.489 17.7991 104.331 18.5973C104.299 18.7446 104.336 18.9321 104.425 19.1009C104.524 19.2884 104.682 19.433 104.867 19.5027C104.947 19.5321 105.044 19.5562 105.156 19.5562C105.627 19.5562 106.391 19.1759 107.409 17.4295C107.564 17.3786 107.725 17.325 107.88 17.2714C108.609 17.025 109.364 16.7679 110.047 16.6527C110.802 17.0571 111.662 17.317 112.246 17.317C112.825 17.317 113.052 16.9741 113.138 16.7679C113.288 16.4062 113.216 15.9509 112.972 15.7071C112.619 15.3589 111.759 15.2679 110.419 15.4339C109.76 15.0321 109.329 14.4857 109.016 13.6714ZM106.077 17.7402C105.705 18.2812 105.424 18.5518 105.271 18.6696C105.451 18.3402 105.802 17.992 106.077 17.7402ZM108.424 11.4321C108.563 11.6705 108.544 12.3911 108.437 12.7554C108.306 12.2223 108.287 11.467 108.365 11.3786C108.386 11.3812 108.405 11.3973 108.424 11.4321ZM108.381 14.6598C108.668 15.1554 109.029 15.5813 109.428 15.8973C108.85 16.0286 108.322 16.2455 107.851 16.4384C107.738 16.4839 107.628 16.5295 107.521 16.5723C107.877 15.9268 108.175 15.1955 108.381 14.6598ZM112.549 16.4143C112.552 16.4196 112.554 16.4277 112.538 16.4384H112.533L112.527 16.4464C112.506 16.4598 112.286 16.5884 111.341 16.2161C112.428 16.1652 112.546 16.4116 112.549 16.4143ZM117.676 6.01607L111.911 0.251786C111.751 0.0910715 111.534 0 111.306 0H99.9275C99.4533 0 99.0703 0.383036 99.0703 0.857143V23.1429C99.0703 23.617 99.4533 24 99.9275 24H117.07C117.544 24 117.927 23.617 117.927 23.1429V6.62411C117.927 6.39643 117.836 6.17679 117.676 6.01607ZM115.951 7.01786H110.91V1.97679L115.951 7.01786ZM115.999 22.0714H100.999V1.92857H109.088V7.71429C109.088 8.01265 109.207 8.2988 109.418 8.50978C109.629 8.72076 109.915 8.83929 110.213 8.83929H115.999V22.0714Z"
          fill="black"
          fill-opacity="0.85"
        />
      </g>
      <defs>
        <clipPath id="clip0_1969_19425">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(32.5)"
          />
        </clipPath>
        <clipPath id="clip1_1969_19425">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(64.5)"
          />
        </clipPath>
        <clipPath id="clip2_1969_19425">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(96.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Pdf
