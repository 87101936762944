import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import FileDragDrop from "../components/Common/FileDragDrop";
import FilePreview from "../components/pages/FaceMatch/FilePreview";
import CustomButton from "../components/Common/CustomButton";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import Loader from "../components/Common/Loader/Loader";
import ImagePreview from "../components/pages/FaceMatch/ImagePreview";
import JSONCard from "../components/Common/JSONCard";
import HeaderTop from "../components/pages/OCR/HeaderTop";
import { OutletcontextType } from "../components/Layout/Index";
import DataSecurityCard from "../components/Common/DataSecurityCard";
import ContentCard from "../components/Common/ContectCard";
import BarChartIcon from "../Icons/BarChartIcon";
import { faceAnalyseApi } from "../service/apiActions/FaceAction";
import FeatureFrame from "../components/Common/FeatureFrame";
import UserFreindlyIcon from "../Icons/UserFreindlyIcon";
import FieldTimeIcon from "../Icons/FieldTimeIcon";
import BandwidthIcon from "../Icons/BandwidthIcon";

const json = {
  root: {
    facematch: {
      id: "400020MM97p110",
      match: "no",
      review: "no",
      score: "29.98763720194499",
    },
    status: "success",
    statusCode: 200,
  },
};

const FaceAnalysis = () => {
  const { handleChangeTitle, showSnackbar } =
    useOutletContext<OutletcontextType>();

  const [files, setFiles] = useState<any>({});
  const [isLoading, setLoading] = useState(false);
  const [result, setResult] = useState<any>({});

  useEffect(() => {
    document.title = "AiFise | Face Analysis";
    handleChangeTitle(
      "Face Analysis",
      "Identify a selfie’s Liveness, blurriness, objects and face counts."
    );
  }, []);

  const handleFileChange = (files: any) => {
    // setFiles(files[0]);
    console.log(files[0]);
    if (files.length > 0 && files[0].size > 1024 * 1024 * 3) {
      showSnackbar("File size cannot be greater than 3 MB");
      return;
    } else if (
      files.length > 0 &&
      files[0].type !== "image/png" &&
      files[0].type !== "image/jpeg" &&
      files[0].type !== "image/jpg"
    ) {
      showSnackbar("Only png, jpg and jpeg are allowed");
      return;
    }
    setFiles(files[0]);
    setLoading(true);
    let _formdata = new FormData();
    _formdata.append("image", files[0]);

    faceAnalyseApi(_formdata)
      .then((res: any) => {
        setLoading(false);
        if (res.statusCode === 200) {
          setResult(res);
        } else {
          showSnackbar("Something went wrong, please try again sometime later");
        }
      })
      .catch((error) => {
        setLoading(false);
        showSnackbar(
          error?.message ||
            "Something went wrong, please try again sometime later"
        );
      });
  };

  const handleUpload = () => {
    setLoading(true);
    setResult(json);
    setTimeout(() => setLoading(false), 4000);
  };

  const handleReset = () => {
    setResult({});
    setFiles({});
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {Object.keys(result).length > 0 ? (
        <>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} lg={8}>
              <HeaderTop title="Check Liveness" reset={handleReset} />
            </Grid>
          </Grid>
          <Box>
            <Grid container spacing={2} mt={0} justifyContent="center">
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <ImagePreview
                  title="Image Preview"
                  src={URL.createObjectURL(files)}
                  name={files?.name}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <JSONCard data={result} />
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6} md={6} lg={5}>
            {/* {
                            files?.name ?
                                <FilePreview
                                    name='mohitsarin_profile picture.jpg'
                                    filesize='98.9 KB'
                                    handleClose={() => { }}
                                    sx={{ mt: 0.5 }}
                                />
                                : */}
            <FileDragDrop handleChange={handleFileChange} />
            {/* } */}
            {/* <FormControl size='small' fullWidth sx={{
                            my: 2
                        }}>
                            <Select
                                sx={{
                                    "&.MuiSelect-outlined": {
                                        borderColor: "#007AFE"
                                    }
                                }}
                                value=""
                            >
                                <MenuItem value="">Select a processing option</MenuItem>
                            </Select>
                        </FormControl>
                        <Stack direction="row-reverse" gap={2}>
                            <CustomButton
                                startIcon={<FileUploadOutlinedIcon />}
                                size='large'
                                onClick={handleUpload}
                            >
                                Upload
                            </CustomButton>
                            <CustomButton
                                startIcon={<RefreshOutlinedIcon />}
                                size='large'
                                variant='outlined'
                            >
                                Reset
                            </CustomButton>
                        </Stack> */}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="text.secondary" align="center">
              <b>Note:</b> File size should be less than 3 MB and only png, jpg
              and jpeg are allowed
            </Typography>
          </Grid>
        </Grid>
      )}
      <FeatureFrame
        frameTitle="How We Achieve Effortless Excellence: Elevating Your User Experience"
        frameDescription="Here's the reason to make the most of our Face Analiyzer"
        icon1={<UserFreindlyIcon />}
        icon2={<FieldTimeIcon />}
        icon3={<BandwidthIcon />}
        iconDescription1="Detects a user's liveness using a simple selfie"
        iconDescription2="A Machine Trained For Recognizing Facial Differences"
        iconDescription3="As small as 200KB, it welcomes crisp, clear images"
        iconTitle1=" User-friendly"
        iconTitle2="Detect all face"
        iconTitle3="Bandwidth"
      />
    </>
  );
};

export default FaceAnalysis;
