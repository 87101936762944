import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import FileDragDrop from "../components/Common/FileDragDrop";
import HeaderTop from "../components/pages/OCR/HeaderTop";
import ImagePreviewCard from "../components/pages/OCR/ImagePreviewCard";
import DataSecurityCard from "../components/Common/DataSecurityCard";
import ContentCard from "../components/Common/ContectCard";
import BarChartIcon from "../Icons/BarChartIcon";
import LanguageIcon from "../Icons/LanguageIcon";
import JSONCard from "../components/Common/JSONCard";
import { OutletcontextType } from "../components/Layout/Index";
import { useOutletContext } from "react-router-dom";
import { imageOcr, voterID } from "../service/apiActions/OcrAction";
import Loader from "../components/Common/Loader/Loader";
import CustomButton from "../components/Common/CustomButton";
import FilePreview from "../components/pages/FaceMatch/FilePreview";
import CloudUpload from "../Icons/CloudUpload";
import Database from "../Icons/Database";
import UserThree from "../Icons/UserThree";
import FeatureFrame from "../components/Common/FeatureFrame";

const indianStates = [
  "Select State",
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];

const VoterId = () => {
  const [formdata, setFormdata] = useState<any>({});
  const [result, setResult] = useState<any>({});
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});
  const [selectedState, setSelectedState] = useState(indianStates[0]);
  const [filesize, setFileSize] = useState<any>(0);

  const { handleChangeTitle, showSnackbar } =
    useOutletContext<OutletcontextType>();

  useEffect(() => {
    document.title = "AiFise | Voter ID Verification";
    handleChangeTitle("Voter ID", "Extract data from Voter ID Card image.");
  }, []);

  const handleStateChange = (event: any) => {
    setSelectedState(event.target.value);
  };

  const handleFile = (files: any, e: any) => {
    if (files.length > 0 && files[0].size > 1024 * 1024 * 3) {
      showSnackbar("File size cannot be greater than 3 MB");
      return;
    } else if (
      files.length > 0 &&
      files[0].type !== "image/png" &&
      files[0].type !== "image/jpeg" &&
      files[0].type !== "image/jpg"
    ) {
      showSnackbar("Only png, jpg and jpeg are allowed");
      return;
    }
    setFormdata(files[0]);
    const sizeInMB = (files[0].size / (1024 * 1024)).toFixed(2);
    setFileSize(sizeInMB + " MB");
    setLoading(true);
    let _formdata = new FormData();
    _formdata.append("image", files[0]);
    voterID(_formdata)
      .then((res) => {
        setLoading(false);
        setResult(res);
      })
      .catch((error) => {
        setLoading(false);
        showSnackbar("Something went wrong");
      });
  };

  console.log("result===", result.Response?.voterid_no);

  const handleUpload = () => {
    if (formdata.name === undefined) {
      showSnackbar("Please choose file");
      return;
    }
    
    if (!selectedState) {
      showSnackbar("Please select state");
      return;
    }

    voterID({ state: selectedState, epicno: result.Response?.voterid_no })
      .then((res) => {
        setData(res);
        console.log("res===", res);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  console.log("data", data);

  if (isLoading) {
    return <Loader />;
  }

  const handleReset = () => {
    setResult({});
    setFormdata({});
    setData({});
  };

  return (
    <div  style = {{paddingBottom:"200px"}}>
      {Object.keys(result).length === 0 ? (
        <Grid container alignItems="center" direction={"column"} spacing={4}>
          <Grid item lg={5} md={6} sm={6} xs={12} sx={{ width: "43%" }}>
            {formdata.name ? (
              <FilePreview
                sx={{ mt: 1 }}
                name={formdata?.name}
                filesize={filesize}
                handleClose={() => setFormdata({})}
              />
            ) : (
              <FileDragDrop handleChange={handleFile} />
            )}
          </Grid>
          <Grid item lg={50} xs={6}>
            <Box sx={{ width: 365 }}>
              {/* <FormControl fullWidth>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  title="Select State"
                  value={selectedState}
                  onChange={handleStateChange}
                  defaultValue="select State"
                >
                  {indianStates.map((state, index) => (
                    <MenuItem key={index} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="text.secondary" align="center">
              <b>Note:</b> File size should be less than 3 MB and only png, jpg and jpeg are allowed.
            </Typography>
          </Grid>
          <Grid item>
            <CustomButton size="large" onClick={handleUpload}>
              Upload
            </CustomButton>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container justifyContent="center">
            <Grid item lg={8}>
              <HeaderTop
                handleFileChange={(e) => {
                  setResult({});
                  handleFile(e.target.files, e);
                }}
                reset={handleReset}
                enableUpload
              />
            </Grid>
          </Grid>
          <Box>
            <Grid container spacing={2} mt={0} justifyContent="center">
              <Grid item lg={4} md={5} sm={6} xs={12}>
                <ImagePreviewCard
                  src={URL.createObjectURL(formdata)}
                  imageName={formdata?.name}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={6} xs={12}>
                <JSONCard data={result} />
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      
      <FeatureFrame 
        frameTitle="How Does Voter Id Verification API Work?"
        frameDescription="Here's the reason to make the most of our Address Matching"
        icon1={<CloudUpload />}
        icon2={<Database />}
        icon3={<UserThree />}
        iconDescription1="Upload the image of Voter ID."
        iconDescription2="Realtime database check performed to fetch the information."
        iconDescription3="Instant Voter ID Verification is done and ready with response."
        iconTitle1="Easy Upload"
        iconTitle2="Realtime Check"
        iconTitle3="Instant Verification"
      />
    </div>
  );
};

export default VoterId;
