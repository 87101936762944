import React from 'react'

const Spam1 = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5406 57.5571C30.5477 57.9402 29.4478 57.9402 28.4549 57.5571C20.7007 54.5167 14.0426 49.2103 9.3487 42.33C4.65479 35.4497 2.14292 27.3146 2.14062 18.9857V6.42854C2.14062 5.29189 2.59215 4.20181 3.39588 3.39808C4.19961 2.59435 5.2897 2.14282 6.42634 2.14282H53.5692C54.7058 2.14282 55.7959 2.59435 56.5997 3.39808C57.4034 4.20181 57.8549 5.29189 57.8549 6.42854V18.9428C57.8612 27.2791 55.3534 35.4235 50.6589 42.3123C45.9644 49.2011 39.3016 54.514 31.5406 57.5571Z"
        stroke="black"
        stroke-opacity="0.85"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.1562 45.9858C14.1111 43.0447 16.7632 40.633 19.8761 38.9655C22.9891 37.298 26.4662 36.4265 29.9977 36.4286C37.442 36.4286 43.9991 40.2258 47.8434 45.9858M29.9977 30.0001C31.264 30.0001 32.5179 29.7507 33.6878 29.2661C34.8578 28.7815 35.9208 28.0712 36.8162 27.1757C37.7116 26.2803 38.4219 25.2173 38.9065 24.0474C39.3911 22.8774 39.6405 21.6235 39.6405 20.3572C39.6405 19.0909 39.3911 17.837 38.9065 16.6671C38.4219 15.4971 37.7116 14.4341 36.8162 13.5387C35.9208 12.6433 34.8578 11.933 33.6878 11.4484C32.5179 10.9638 31.264 10.7144 29.9977 10.7144C27.4402 10.7144 24.9875 11.7303 23.1791 13.5387C21.3708 15.3471 20.3548 17.7998 20.3548 20.3572C20.3548 22.9147 21.3708 25.3674 23.1791 27.1757C24.9875 28.9841 27.4402 30.0001 29.9977 30.0001Z"
        stroke="black"
        stroke-opacity="0.85"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default Spam1
