import React from 'react'

const Scalable = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.25 9.375V15H41.25V48.75H35.625V15H20.625V9.375H56.25Z"
        fill="black"
        fill-opacity="0.85"
      />
      <path
        d="M13.125 48.75V26.25H3.75V22.5H26.25V26.25H16.875V48.75H13.125Z"
        fill="black"
        fill-opacity="0.85"
      />
    </svg>
  );
}

export default Scalable
