import React from 'react'

const Customize = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.5 30.55C52.5 16.825 41.85 7.5 30 7.5C18.275 7.5 7.5 16.625 7.5 30.7C6 31.55 5 33.15 5 35V40C5 42.75 7.25 45 10 45C11.375 45 12.5 43.875 12.5 42.5V30.475C12.5 20.9 19.875 12.525 29.45 12.25C31.7934 12.1787 34.1272 12.5786 36.3132 13.4259C38.4992 14.2731 40.4929 15.5506 42.1762 17.1825C43.8594 18.8145 45.1979 20.7677 46.1124 22.9264C47.0269 25.0852 47.4987 27.4055 47.5 29.75V47.5H30C28.625 47.5 27.5 48.625 27.5 50C27.5 51.375 28.625 52.5 30 52.5H47.5C50.25 52.5 52.5 50.25 52.5 47.5V44.45C53.975 43.675 55 42.15 55 40.35V34.6C55 32.85 53.975 31.325 52.5 30.55Z"
        fill="black"
        fill-opacity="0.85"
      />
      <path
        d="M22.5 35C23.8807 35 25 33.8807 25 32.5C25 31.1193 23.8807 30 22.5 30C21.1193 30 20 31.1193 20 32.5C20 33.8807 21.1193 35 22.5 35Z"
        fill="black"
        fill-opacity="0.85"
      />
      <path
        d="M37.5 35C38.8807 35 40 33.8807 40 32.5C40 31.1193 38.8807 30 37.5 30C36.1193 30 35 31.1193 35 32.5C35 33.8807 36.1193 35 37.5 35Z"
        fill="black"
        fill-opacity="0.85"
      />
      <path
        d="M45.0021 27.575C44.406 24.0603 42.5856 20.8699 39.8632 18.5685C37.1408 16.267 33.692 15.0029 30.1271 15C22.5521 15 14.4021 21.275 15.0521 31.125C18.135 29.8635 20.8577 27.8587 22.9777 25.2895C25.0977 22.7202 26.5489 19.6663 27.2021 16.4C30.4771 22.975 37.2021 27.5 45.0021 27.575Z"
        fill="black"
        fill-opacity="0.85"
      />
    </svg>
  );
}

export default Customize
