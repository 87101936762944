import React from 'react'

const Gist1 = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.5 27.5V22.5H47.5V17.5C47.496 16.1751 46.968 14.9057 46.0312 13.9688C45.0943 13.032 43.8249 12.504 42.5 12.5H37.5V7.5H32.5V12.5H27.5V7.5H22.5V12.5H17.5C16.1751 12.504 14.9057 13.032 13.9688 13.9688C13.032 14.9057 12.504 16.1751 12.5 17.5V22.5H7.5V27.5H12.5V32.5H7.5V37.5H12.5V42.5C12.504 43.8249 13.032 45.0943 13.9688 46.0312C14.9057 46.968 16.1751 47.496 17.5 47.5H22.5V52.5H27.5V47.5H32.5V52.5H37.5V47.5H42.5C43.8249 47.496 45.0943 46.968 46.0312 46.0312C46.968 45.0943 47.496 43.8249 47.5 42.5V37.5H52.5V32.5H47.5V27.5H52.5ZM42.5 42.5H17.5V17.5H42.5V42.5Z"
        fill="black"
        fill-opacity="0.85"
      />
      <path
        d="M28.4031 20H25.0406L20.0156 40H22.5831L23.7431 35.3125H29.5331L30.6631 40H33.3181L28.4031 20ZM24.0806 33.31L26.6256 22.375H26.7406L29.1981 33.31H24.0806ZM35.6106 20H38.1106V40H35.6106V20Z"
        fill="black"
        fill-opacity="0.85"
      />
    </svg>
  );
}

export default Gist1
