import React from 'react'

const Ckyc2 = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.125 46.875H31.875M20.625 5.625H39.375C40.3696 5.625 41.3234 6.02009 42.0266 6.72335C42.7299 7.42661 43.125 8.38044 43.125 9.375V50.625C43.125 51.6196 42.7299 52.5734 42.0266 53.2766C41.3234 53.9799 40.3696 54.375 39.375 54.375H20.625C19.6304 54.375 18.6766 53.9799 17.9734 53.2766C17.2701 52.5734 16.875 51.6196 16.875 50.625V9.375C16.875 8.38044 17.2701 7.42661 17.9734 6.72335C18.6766 6.02009 19.6304 5.625 20.625 5.625Z"
        stroke="black"
        stroke-opacity="0.85"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default Ckyc2
