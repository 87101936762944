import { Box, Typography } from '@mui/material'
import Tooltop from '../../Common/Tooltop'

const Heading = () => {
    return (
        <Box
            sx={{
                background: "url('/images/bg.png')",
                height: "216px",
                width: "100%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
            }}
        >
           <div style={{
            paddingTop: "16px",
            paddingRight: "28px",
           }}>
           <Tooltop />
           </div>
            <Box
                sx={{
                    maxWidth: "600px",
                    margin: "auto",
                    height: "calc(100% - 100px)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                }}
            >
                <Typography
                    variant='h4'
                    sx={{
                        color: "#fff",
                        fontWeight: 500,
                        fontStyle: "normal",
                        textAlign: "center",
                        "& span": {
                            letterSpacing: "3px"
                        }
                    }}
                >
                    Welcome to the <span>AIFISE CONSOLE</span>
                </Typography>
                <Typography
                    variant='subtitle2'
                    sx={{
                        color: "rgba(255, 255, 255, 0.90)",
                        fontStyle: "normal",
                        fontWeight: "fontWeightRegular",
                        textAlign: "center",
                        lineHeight: "1.3",
                        fontSize: {xl: "20px", lg: "18px", md: "16px", sm: "14px", xs: "12px"},
                    }}
                >
                    Your gateway to all AIFISE products. Access our suite of offerings seamlessly and efficiently in one centralized platform.
                </Typography>
            </Box>
        </Box>
    )
}

export default Heading