import { createTheme } from '@mui/material'

const theme = createTheme({
    typography: {
        fontFamily: "'Open Sans', sans-serif",
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        fontWeightBold:700
    },
    components: {
        MuiCssBaseline:{
            styleOverrides: `
                @font-face {
                    font-family: "'Open Sans',sans-serif";
                    font-style: normal;
                    src: local('Open Sans'), local('OpenSans'),
                         url('/fonts/Open_Sans/static/OpenSans-Regular.ttf') format('truetype');
                  }
                  
                  @font-face {
                    font-family: "'Open Sans',sans-serif";
                    font-style: normal;
                    src: local('Open Sans'), local('OpenSans'),
                         url('/fonts/Open_Sans/static/OpenSans-Medium.ttf') format('truetype');
                  }
                  
                  @font-face {
                    font-family: "'Open Sans',sans-serif";
                    font-style: normal;
                    src: local('Open Sans'), local('OpenSans'),
                         url('/fonts/Open_Sans/static/OpenSans-SemiBold.ttf') format('truetype');
                  }
                  
                  @font-face {
                    font-family: "'Open Sans',sans-serif";
                    font-style: normal;
                    src: local('Open Sans'), local('OpenSans'),
                         url('/fonts/Open_Sans/static/OpenSans-Bold.ttf') format('truetype');
                  }
                  
                  @font-face {
                    font-family: "'Open Sans',sans-serif";
                    font-style: normal;
                    src: local('Open Sans'), local('OpenSans'),
                         url('/fonts/Open_Sans/static/OpenSans-ExtraBold.ttf') format('truetype');
                  }
            `}
    }
})

export default theme
