import React from "react";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Layout from "./components/Layout/Index";
import ImageOCR from "./pages/ImageOCR";
import FaceMatch from "./pages/FaceMatch";
import FaceAnalysis from "./pages/FaceAnalysis";
import GSTOCR from "./pages/GSTOCR";
import AadharMasking from "./pages/AadharMasking";
import AddressMatch from "./pages/AddressMatch";
import VoterId from "./pages/VoterId";
import Liveliness from "./pages/Liveliness";
import VideoKyc from "./pages/VideoKyc";
import SDKpage from "./pages/SDKpage";
import ViolenceDetection from "./pages/ViolenceDetection";
import Login from "./Login";
import Signup from "./Signup";
import APIDocumentation from "./pages/APIDocumentation";
import FaceSearch from "./pages/FaceSearch";
import APIControls from "./pages/APIControls";
import AdminLayout from "./components/Layout/AdminLayout";
import UserManagement from "./pages/UserManagement";
import Enquiries from "./pages/Enquiries";
import { ThemeProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";
import UserManagementDetail from "./pages/UserManagementDetail";
import EnquiryDetails from "./pages/EnquiryDetails";
import SpamClassifier from "./pages/SpamClassifier";
import Profile from "./pages/Profile";
import Onboarding from "./pages/Onboarding";
import Gist from "./pages/Gist";
import Ckyc from "./pages/Ckyc";
import { createContext, useContext, useState } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import SessionTimeOutModal from "./components/pages/UserManagement/SessionTimeOutModal";

Amplify.configure({ ...config });
console.log(config, "amplify config");

type AuthRouteProps = {
  component: React.ComponentType;
};

const AuthRoute = ({ component }: AuthRouteProps) => {
  console.log("authRoute");
  const auth = localStorage.getItem("isLoggedIn");
  if (auth !== "true") {
    return <Navigate to="/login" />;
  }
  let Component = component;
  return <Component />;
};

const AdminAuthRoute = ({ component }: AuthRouteProps) => {
  console.log("adminAuthRoute");
  const auth = localStorage.getItem("userLoginData")
    ? JSON.parse(localStorage.getItem("userLoginData") as string)
    : null;
  if (!auth || auth.role !== "adm") {
    return <Navigate to="/login" replace />;
  }
  let Component = component;
  return <Component />;
};

const ControlRoute = ({ component }: AuthRouteProps) => {
  console.log("controlRoute");
  const auth = localStorage.getItem("userLoginData")
    ? JSON.parse(localStorage.getItem("userLoginData") as string)
    : null;
  const signed = localStorage.getItem("isLoggedIn");
  if (auth) {
    return <Navigate to="/" replace />;
  } else if (signed && !auth) {
    let Component = component;
    return <Component />;
  }

  return <Navigate to="/login" replace />;
};

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<AuthRoute component={Homepage} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/controls"
              element={<ControlRoute component={APIControls} />}
            />
            <Route path="/dashboard" element={<Layout />}>
              <Route path="" element={<Navigate to="/login" />} />
              <Route
                path="aadhar-masking"
                element={<AuthRoute component={AadharMasking} />}
              />
              <Route path="ocr" element={<AuthRoute component={ImageOCR} />} />
              <Route
                path="face-match"
                element={<AuthRoute component={FaceMatch} />}
              />
              <Route
                path="face-analysis"
                element={<AuthRoute component={FaceAnalysis} />}
              />
              <Route
                path="gst-ocr"
                element={<AuthRoute component={GSTOCR} />}
              />
              <Route
                path="address-match"
                element={<AuthRoute component={AddressMatch} />}
              />
              <Route
                path="voterid-verification"
                element={<AuthRoute component={VoterId} />}
              />
              <Route
                path="liveliness"
                element={<AuthRoute component={Liveliness} />}
              />
              <Route path="vkyc" element={<AuthRoute component={VideoKyc} />} />
              <Route path="sdk" element={<AuthRoute component={SDKpage} />} />
              <Route
                path="violence-detection"
                element={<AuthRoute component={ViolenceDetection} />}
              />
              <Route
                path=":catId/api-docs/:prodId"
                element={<AuthRoute component={APIDocumentation} />}
              />
              <Route
                path="face-search"
                element={<AuthRoute component={FaceSearch} />}
              />
              <Route
                path="spam-classifier"
                element={<AuthRoute component={SpamClassifier} />}
              />
              <Route
                path="onboarding"
                element={<AuthRoute component={Onboarding} />}
              />
              <Route path="gist" element={<AuthRoute component={Gist} />} />
              <Route path="ckyc" element={<AuthRoute component={Ckyc} />} />
            </Route>
            <Route path="*" element={<>Not found</>} />
            <Route path="/admin" element={<AdminLayout />}>
              <Route
                path="user-management"
                element={<AdminAuthRoute component={UserManagement} />}
              />
              <Route
                path="enquiries"
                element={<AdminAuthRoute component={Enquiries} />}
              />
              <Route
                path="enquiries/enquiry-details/:id"
                element={<AdminAuthRoute component={EnquiryDetails} />}
              />
              <Route
                path="user-management/access/:id"
                element={<AuthRoute component={UserManagementDetail} />}
              />
            </Route>
            <Route
              path="/profile"
              element={<AuthRoute component={Profile} />}
            />
          </Routes>
          <SessionTimeOutModal />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
