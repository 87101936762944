import React from 'react'

const Span2 = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.3125 16.875C41.8658 16.875 43.125 15.6158 43.125 14.0625C43.125 12.5092 41.8658 11.25 40.3125 11.25C38.7592 11.25 37.5 12.5092 37.5 14.0625C37.5 15.6158 38.7592 16.875 40.3125 16.875Z"
        fill="black"
        fill-opacity="0.85"
      />
      <path
        d="M27.0262 37.5H16.875V27.3488L28.1812 16.0425C28.1444 15.6962 28.1256 15.3482 28.125 15C28.125 12.775 28.7848 10.5999 30.021 8.74984C31.2571 6.89979 33.0141 5.45785 35.0698 4.60636C37.1255 3.75487 39.3875 3.53209 41.5698 3.96617C43.7521 4.40025 45.7566 5.47171 47.33 7.04505C48.9033 8.61839 49.9748 10.623 50.4088 12.8052C50.8429 14.9875 50.6201 17.2495 49.7686 19.3052C48.9172 21.3609 47.4752 23.1179 45.6252 24.354C43.7751 25.5902 41.6 26.25 39.375 26.25C39.0268 26.2493 38.6788 26.2306 38.3325 26.1938L27.0262 37.5ZM20.625 33.75H25.4738L37.0425 22.1813L38.0138 22.3594C38.4622 22.4483 38.9179 22.4954 39.375 22.5C40.9432 22.5193 42.479 22.0526 43.7712 21.1639C45.0635 20.2752 46.0489 19.0082 46.5921 17.537C47.1354 16.0657 47.2097 14.4623 46.8049 12.9471C46.4002 11.4319 45.5362 10.0792 44.3317 9.07473C43.1272 8.07031 41.6413 7.46347 40.078 7.33758C38.5147 7.21169 36.9508 7.57293 35.6011 8.37166C34.2513 9.17039 33.182 10.3674 32.54 11.7984C31.898 13.2293 31.7149 14.8239 32.0156 16.3631L32.1938 17.3325L20.625 28.9013V33.75ZM52.5 37.5H35.625V41.25H52.5V52.5H7.5V41.25H11.25V37.5H7.5C6.50574 37.501 5.55249 37.8964 4.84945 38.5994C4.1464 39.3025 3.75099 40.2557 3.75 41.25V52.5C3.75099 53.4943 4.1464 54.4475 4.84945 55.1506C5.55249 55.8536 6.50574 56.249 7.5 56.25H52.5C53.4943 56.249 54.4475 55.8536 55.1506 55.1506C55.8536 54.4475 56.249 53.4943 56.25 52.5V41.25C56.249 40.2557 55.8536 39.3025 55.1506 38.5994C54.4475 37.8964 53.4943 37.501 52.5 37.5Z"
        fill="black"
        fill-opacity="0.85"
      />
      <path
        d="M13.125 48.75C14.1605 48.75 15 47.9105 15 46.875C15 45.8395 14.1605 45 13.125 45C12.0895 45 11.25 45.8395 11.25 46.875C11.25 47.9105 12.0895 48.75 13.125 48.75Z"
        fill="black"
        fill-opacity="0.85"
      />
    </svg>
  );
}

export default Span2
