import React from "react";

const WebIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="mdi:web">
        <path
          id="Vector"
          d="M24.54 21C24.66 20.01 24.75 19.02 24.75 18C24.75 16.98 24.66 15.99 24.54 15H29.61C29.85 15.96 30 16.965 30 18C30 19.035 29.85 20.04 29.61 21M21.885 29.34C22.785 27.675 23.475 25.875 23.955 24H28.38C26.9268 26.5024 24.6212 28.398 21.885 29.34ZM21.51 21H14.49C14.34 20.01 14.25 19.02 14.25 18C14.25 16.98 14.34 15.975 14.49 15H21.51C21.645 15.975 21.75 16.98 21.75 18C21.75 19.02 21.645 20.01 21.51 21ZM18 29.94C16.755 28.14 15.75 26.145 15.135 24H20.865C20.25 26.145 19.245 28.14 18 29.94ZM12 12H7.62C9.05828 9.49082 11.3622 7.59223 14.1 6.66C13.2 8.325 12.525 10.125 12 12ZM7.62 24H12C12.525 25.875 13.2 27.675 14.1 29.34C11.3679 28.3975 9.06727 26.5016 7.62 24ZM6.39 21C6.15 20.04 6 19.035 6 18C6 16.965 6.15 15.96 6.39 15H11.46C11.34 15.99 11.25 16.98 11.25 18C11.25 19.02 11.34 20.01 11.46 21M18 6.045C19.245 7.845 20.25 9.855 20.865 12H15.135C15.75 9.855 16.755 7.845 18 6.045ZM28.38 12H23.955C23.4855 10.1422 22.7904 8.34884 21.885 6.66C24.645 7.605 26.94 9.51 28.38 12ZM18 3C9.705 3 3 9.75 3 18C3 21.9782 4.58035 25.7936 7.3934 28.6066C8.78628 29.9995 10.4399 31.1044 12.2597 31.8582C14.0796 32.612 16.0302 33 18 33C21.9782 33 25.7936 31.4196 28.6066 28.6066C31.4196 25.7936 33 21.9782 33 18C33 16.0302 32.612 14.0796 31.8582 12.2597C31.1044 10.4399 29.9995 8.78628 28.6066 7.3934C27.2137 6.00052 25.5601 4.89563 23.7403 4.14181C21.9204 3.38799 19.9698 3 18 3Z"
          fill="#458ACA"
        />
      </g>
    </svg>
  );
};

export default WebIcon;
