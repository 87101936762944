import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  IconButton,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SignupApi } from "./service/apiActions/FaceAction";
import Loader from "./components/Common/Loader/Loader";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { VariantType, useSnackbar } from "notistack";
import LogoIcon from "./Icons/LogoIcon";
import CloseIcon from "@mui/icons-material/Close";

const Signup = () => {
  const [username, setUsername] = useState<any>("");
  const [password, setPassword] = useState<any>("");
  const [fullName, setFullName] = useState("");
  const [phoneNo, setPhoneNo] = useState<any>("");
  const [deg, setDeg] = useState<any>("");
  const [affiliations, setAffiliations] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [fNameError, setfNameError] = useState(false);
  const [degNameError, setDegNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [emptyPasswordError, setEmptyPasswordError] = useState(false);
  const [minLengthError, setMinLengthError] = useState(false);

  const navigate = useNavigate();

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const showSnackbar = (message: string, type: VariantType = "error") => {
    enqueueSnackbar(message, { variant: type  , action:  (key) => (
      <CloseIcon onClick={() => closeSnackbar(key)} />
    ), });
  };

  const handleFullNameChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    setFullName(value);
  };

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const onSubmit = async (
    username: any,
    password: string,
    fullName: any,
    deg: string,
    affiliations: any
  ) => {
    setLoading(true);

    const body = {
      name: fullName,
      designation: deg,
      email: username,
      organization: affiliations,
      phone: phoneNo,
      password: password,
      type: "email",
    };

    SignupApi(body)
      .then((res: any) => {
        if (res.status !== "success") return;
        localStorage.setItem("token", JSON.stringify(res.token));
        localStorage.setItem("name", JSON.stringify(body.name));
        localStorage.setItem("isLoggedIn", "true");
        navigate("/controls");
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        showSnackbar(error.message);
        setLoading(false);
      });
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    let isValid = true;

    if (!validateEmail(username)) {
      setEmailError(true);
      isValid = false;
    } else {
      setEmailError(false);
    }

    if (username.trim() === "") {
      setUsernameError(true);
      isValid = false;
    } else {
      setUsernameError(false);
    }

    if (password.trim() === "") {
      setPasswordError(true);
      isValid = false;
    } else {
      setPasswordError(false);
    }
    if (fullName.trim() === "") {
      setfNameError(true);
      isValid = false;
    } else {
      setfNameError(false);
    }

    if (deg.trim() === "") {
      console.log("deg", deg);
      setDegNameError(true);
      isValid = false;
    } else {
      setDegNameError(false);
    }

    // Checking if phoneNo is not empty before validating
    if (phoneNo !== "") {
      const phnRegex = /^\d{10}$/;
      if (!phnRegex.test(phoneNo)) {
        setPhoneError(true);
        isValid = false;
      } else {
        setPhoneError(false);
      }
    } else {
      setPhoneError(false); // Resetting phone number error if it's empty
    }

    // Empty password validation
    if (password.trim() === "") {
      setEmptyPasswordError(true);
      isValid = false;
    } else {
      setEmptyPasswordError(false);
    }

    // Password minimum length validation
    if (password.trim().length < 8) {
      setMinLengthError(true);
      isValid = false;
    } else {
      setMinLengthError(false);
    }

    if (isValid) {
      onSubmit(username, password, fullName, deg, affiliations);
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="auth-layout">
      <div
        style={{
          display: "flex",
          marginBottom: "16px",
          justifyContent: "space-around",
          marginTop: 20,
        }}
      >
        <LogoIcon />
      </div>
      <Grid container justifyContent="center">
        <Grid item xs={10} sm={6} md={4} mb={8}>
          <Paper elevation={3} style={{ padding: 20 }}>
            <Typography variant="h5" gutterBottom>
              Create Your Account
            </Typography>

            <form onSubmit={handleFormSubmit}>
              <Grid container spacing={2} direction="column" py={2}>
                <Grid item>
                  {/* <label>Full Name</label> */}
                  <InputLabel
                    htmlFor="component-outlined"
                    className="custom-required-label"
                  >
                    Full Name
                  </InputLabel>
                  <TextField
                    size="small"
                    placeholder="Your Full Name"
                    type="text"
                    variant="outlined"
                    fullWidth
                    value={fullName}
                    onChange={handleFullNameChange}
                    error={fNameError}
                    helperText={fNameError ? "This field is required" : ""}
                    required
                  />
                </Grid>
                <Grid item>
                  <InputLabel
                    htmlFor="component-outlined"
                    className="custom-required-label"
                  >
                    Email
                  </InputLabel>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={username}
                    placeholder="Email Address"
                    onChange={(e) => setUsername(e.target.value)}
                    error={emailError || usernameError}
                    helperText={
                      emailError
                        ? "Invalid email format"
                        : usernameError
                        ? "Username is required"
                        : ""
                    }
                  />
                </Grid>
                <Grid item>
                  <Grid container direction={"row"} columnSpacing={1.5}>
                    <Grid item lg={6}>
                      <InputLabel htmlFor="component-outlined">
                        Organization
                      </InputLabel>
                      <TextField
                        size="small"
                        placeholder="Organization"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={affiliations}
                        onChange={(e) => setAffiliations(e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <InputLabel
                        htmlFor="component-outlined"
                        className="custom-required-label"
                      >
                        Designation
                      </InputLabel>
                      <TextField
                        size="small"
                        placeholder="Designation"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={deg}
                        onChange={(e) => setDeg(e.target.value)}
                        error={degNameError}
                        helperText={
                          degNameError ? "This field is required" : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <InputLabel htmlFor="component-outlined">
                    Phone Number
                  </InputLabel>
                  <TextField
                    size="small"
                    placeholder="+91 "
                    type="text"
                    variant="outlined"
                    fullWidth
                    value={phoneNo}
                    onChange={(e) => setPhoneNo(e.target.value)}
                    error={phoneError}
                    helperText={phoneError ? "Invalid phone format" : ""}
                  />
                </Grid>
                <Grid item>
                  <InputLabel
                    htmlFor="component-outlined"
                    className="custom-required-label"
                  >
                    Password
                  </InputLabel>
                  <TextField
                    size="small"
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={emptyPasswordError || minLengthError}
                    helperText={
                      emptyPasswordError
                        ? "Password is required"
                        : minLengthError
                        ? "Password must be at least 8 characters long"
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                  >
                    Create Account
                  </Button>
                </Grid>
                <Grid item>
                  <Grid container justifyContent={"center"}>
                    <Typography justifyContent={"center"}>or</Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container justifyContent={"center"}>
                    <Grid item>
                      <Typography>
                        Have an account?{" "}
                        <strong
                          onClick={() => navigate("/login")}
                          style={{ color: "#9579E2", cursor: "pointer" }}
                        >
                          Log in
                        </strong>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Signup;
