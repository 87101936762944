import React from 'react'

const Onboarding3 = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.5 52.5H7.5C5.43187 52.5 3.75 50.8181 3.75 48.75V37.5H7.5V48.75H52.5V37.5H56.25V48.75C56.25 50.8181 54.5681 52.5 52.5 52.5Z"
        fill="black"
        fill-opacity="0.85"
      />
      <path
        d="M31.875 31.875V39.375C31.875 39.8723 32.0725 40.3492 32.4242 40.7008C32.7758 41.0525 33.2527 41.25 33.75 41.25H41.25C41.7473 41.25 42.2242 41.0525 42.5758 40.7008C42.9275 40.3492 43.125 39.8723 43.125 39.375V31.875C43.125 31.3777 42.9275 30.9008 42.5758 30.5492C42.2242 30.1975 41.7473 30 41.25 30H33.75C33.2527 30 32.7758 30.1975 32.4242 30.5492C32.0725 30.9008 31.875 31.3777 31.875 31.875ZM35.625 33.75H39.375V37.5H35.625V33.75ZM16.875 31.875V39.375C16.875 39.8723 17.0725 40.3492 17.4242 40.7008C17.7758 41.0525 18.2527 41.25 18.75 41.25H26.25C26.7473 41.25 27.2242 41.0525 27.5758 40.7008C27.9275 40.3492 28.125 39.8723 28.125 39.375V31.875C28.125 31.3777 27.9275 30.9008 27.5758 30.5492C27.2242 30.1975 26.7473 30 26.25 30H18.75C18.2527 30 17.7758 30.1975 17.4242 30.5492C17.0725 30.9008 16.875 31.3777 16.875 31.875ZM20.625 33.75H24.375V37.5H20.625V33.75ZM31.875 16.875V24.375C31.875 24.8723 32.0725 25.3492 32.4242 25.7008C32.7758 26.0525 33.2527 26.25 33.75 26.25H41.25C41.7473 26.25 42.2242 26.0525 42.5758 25.7008C42.9275 25.3492 43.125 24.8723 43.125 24.375V16.875C43.125 16.3777 42.9275 15.9008 42.5758 15.5492C42.2242 15.1975 41.7473 15 41.25 15H33.75C33.2527 15 32.7758 15.1975 32.4242 15.5492C32.0725 15.9008 31.875 16.3777 31.875 16.875ZM35.625 18.75H39.375V22.5H35.625V18.75ZM9.375 9.375V24.375C9.375 24.8723 9.57254 25.3492 9.92418 25.7008C10.2758 26.0525 10.7527 26.25 11.25 26.25H26.25C26.7473 26.25 27.2242 26.0525 27.5758 25.7008C27.9275 25.3492 28.125 24.8723 28.125 24.375V9.375C28.125 8.87772 27.9275 8.40081 27.5758 8.04918C27.2242 7.69754 26.7473 7.5 26.25 7.5H11.25C10.7527 7.5 10.2758 7.69754 9.92418 8.04918C9.57254 8.40081 9.375 8.87772 9.375 9.375ZM13.125 11.25H24.375V22.5H13.125V11.25Z"
        fill="black"
        fill-opacity="0.85"
      />
    </svg>
  );
}

export default Onboarding3
