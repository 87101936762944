import React from "react";
import { OutletcontextType } from "../components/Layout/Index";
import { useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import {
  Grid,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from "react";
// import {
//   TablePagination,
//   tablePaginationClasses as classes,
// } from "@mui/base/TablePagination";
// import { styled } from "@mui/system";
import { prod_info } from "../utils/sdkProdInfo";
import TablePagination from "@mui/material/TablePagination";


type Product = {
  id: string;
  name: string;
};

const SDKpage = () => {
  const { handleChangeTitle } = useOutletContext<OutletcontextType>();

  useEffect(() => {
    document.title = "AiFise | SDK";

    handleChangeTitle(
      "Software Development Kit",
      "Your All-in-One Destination for all Your Applications."
    );
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1); // Page number
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page

  const handleSearch = () => {
    console.log("Searching for:", searchTerm);
  };
  const storedProductList = JSON.parse(
    localStorage.getItem("prod_list") || "[]"
  );
  //console.log(storedProductList, 'products')

  // Filtering the storedProductList based on the search term
  const filteredProductList = prod_info.filter((item: Product) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handlers for changing the page and rowsPerPage
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage + 1); // Adjusting the page number
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <div style={{paddingBottom:"200px"}}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            size="small"
            style={{ width: 264 }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ cursor: "pointer" }}
                  onClick={handleSearch}
                >
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
            }}
            label="Search SDK"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} mb={2}>
          <Paper>
            <TableContainer sx={{ marginTop: "16px", marginBottom: "20px" }}>
              <Table>
                <TableHead
                  sx={{
                    background: "#FAFAFA",
                    borderBottom: "1px solid  rgba(0, 0, 0, 0.06)",
                  }}
                >
                  <TableRow>
                    <TableCell width={"20%"}>SDK</TableCell>
                    <TableCell width={"20%"}>Downloads</TableCell>
                    <TableCell width={"20%"}>Documents</TableCell>
                    <TableCell width={"20%"}>Links</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProductList
                    .slice(
                      (page - 1) * rowsPerPage,
                      (page - 1) * rowsPerPage + rowsPerPage
                    )
                    ?.map((item: any) => (
                      <TableRow key={item.name}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.android_download.icon}</TableCell>
                        <TableCell>
                          {item.android_doc_url.icon}
                          {item.apple_doc_url.icon}
                          {/* {item.swagger_url?.icon} */}
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <CustomTablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              count={filteredProductList.length}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingRight: "5px",
              }}
            /> */}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={filteredProductList.length}
              page={page - 1}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

// const CustomTablePagination = styled(TablePagination)`
//   & .${classes.toolbar} {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     gap: 10px;

//     @media (min-width: 768px) {
//       flex-direction: row;
//       align-items: center;
//     }
//   }

//   & .${classes.selectLabel} {
//     margin: 0;
//   }

//   & .${classes.displayedRows} {
//     margin: 0;

//     @media (min-width: 768px) {
//       margin-left: auto;
//     }
//   }

//   & .${classes.spacer} {
//     display: none;
//   }

//   & .${classes.actions} {
//     display: flex;
//     gap: 0.25rem;
//   }
// `;

export default SDKpage;
