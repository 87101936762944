import React, { useEffect, useState } from "react";
import Loader from "../components/Common/Loader/Loader";
import { OutletcontextType } from "../components/Layout/Index";
import { Link, useOutletContext } from "react-router-dom";
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import JSONCard from "../components/Common/JSONCard";
import CustomButton from "../components/Common/CustomButton";
import DataSecurityCard from "../components/Common/DataSecurityCard";
import { spamClassification } from "../service/apiActions/FaceAction";
import FeatureFrame from "../components/Common/FeatureFrame";
import LiveIcon from "../Icons/LiveIcon";
import VideoCameraIcon from "../Icons/VideoCameraIcon";
import MapIcon from "../Icons/MapIcon";
import AdharIcon1 from "../Icons/AdharIcon1";
import Spam1 from "../Icons/Spam1";
import Span2 from "../Icons/Span2";

const SpamClassifier = () => {
  const { handleChangeTitle, showSnackbar } =
    useOutletContext<OutletcontextType>();
  const [isLoading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [textAreaData, setTextAreaData] = useState("");
  const [result, setResult] = useState<any>({});

  useEffect(() => {
    document.title = "AiFise | Spam Classifier";
    handleChangeTitle("Spam Classifier", "Your Anti-Spam Solution.");
  }, []);

  const reset = () => {
    setContent("");
    setTextAreaData("");
    setResult({});
  };

  const handleTextAreaChange = (e: any) => {
    // console.log(e.target.value);
    setTextAreaData(e.target.value);
  };

  const handleNextBtn = async () => {
    if (textAreaData.trim() === "") {
      showSnackbar("Please enter some text", "error");
      return;
    }

    const jsonBody = {
      message: textAreaData,
    };
    setLoading(true);
    try {
      await spamClassification(jsonBody).then((res: any) => {
        // console.log(res, "spam api response");
        if (res.Status === "Success") {
          setLoading(false);
          setContent(textAreaData);
          setResult(res);
        } else {
          showSnackbar(res.Message, "error");
          setLoading(false);
        }
      });
    } catch (error: any) {
      setLoading(false);
      showSnackbar(error.message, "error");
    }
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      {Object.keys(result).length > 0 ? (
        <>
          <Grid container spacing={2} justifyContent="center">
            <Grid item lg={8}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  rowGap: 1.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1.5,
                    flexWrap: "wrap",
                  }}
                >
                  <CustomButton
                    variant="contained"
                    size="large"
                    onClick={reset}
                    // component="label"
                  >
                    New Content
                  </CustomButton>
                </Box>
                <Button
                  variant="contained"
                  size="large"
                  color="success"
                  // component={Link}
                  // to={"api-docs/inno-spam-classifier"}
                  sx={{
                    borderRadius: 0.5,
                    border: "1px solid #38B832",
                    background: "#38B832",
                    textTransform: "capitalize",
                    color: "white",
                    boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)",
                    "&:hover": {
                      background: "#298E35",
                      borderColor: "298E35",
                      boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  API Documentation
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" spacing={3} mt={2}>
            <Grid item lg={8} sm={6} xs={12}>
              <Box sx={{ display: "block", width: "100%" }} mb={1}>
                <Typography
                  variant="body2"
                  component="div"
                  color="rgba(0, 0, 0, 0.85)"
                  // fontFamily="Avenir LT Std"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="350"
                  lineHeight="normal"
                  mb={1}
                >
                  Content
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  color="rgba(0, 0, 0, 0.85)"
                  // fontFamily="Avenir LT Std"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="400"
                  lineHeight="normal"
                  mb={2}
                >
                  {content}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" spacing={3}>
            <Grid item lg={8} sm={6} xs={12}>
              <JSONCard data={result} />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container spacing={2} justifyContent="center">
            <Grid item lg={10} md={12} xs={12}>
              <Box sx={{ display: "block", width: "100%" }} mb={1}>
                <Typography
                  noWrap
                  component="div"
                  color="rgba(0, 0, 0, 0.70)"
                  fontSize="20px"
                  fontWeight={600}
                  lineHeight="24px"
                  textAlign="center"
                >
                  Protecting against spam isn't simple, but we make it seem
                  effortless.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} md={12} lg={8} sm={6} justifyContent="center">
              <Grid item>
                <TextField
                  id="outlined-basic"
                  label="Your content goes here..."
                  fullWidth
                  multiline
                  rows={6}
                  onChange={handleTextAreaChange}
                />
              </Grid>
              <Grid
                item
                display="flex"
                justifyContent="flex-end"
                lg={12}
                mt={3}
              >
                <CustomButton
                  size="large"
                  onClick={handleNextBtn}
                  //  loading={isLoading}
                >
                  Next
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      <FeatureFrame
        frameTitle="Spam Filtering Made Effortless!"
        frameDescription="Discover the AIFISE Advantage in AI Spam Filtering."
        icon1={<AdharIcon1/>}
        icon2={<Spam1 />}
        icon3={<Span2/>}
        iconDescription1="Measures to safeguard and protect personal information."
        iconDescription2="Strategies and protocols in place to prevent falling victim to scams."
        iconDescription3="Assurance of overall digital security and protection measures."
        iconTitle1="Secure Personal Info"
        iconTitle2="Scam Protection"
        iconTitle3="Digital Security"
      />
    </>
  );
};

export default SpamClassifier;
