import { Outlet } from "react-router-dom";
import { Box, CssBaseline, AppBar, Toolbar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import Sidebar from "./Sidebar";
import { VariantType, useSnackbar } from "notistack";

interface LayoutProps {
  window?: Window;
}

export interface OutletcontextType {
  handleChangeTitle: (title: string, subTitle: string) => void;
  showSnackbar: (message: string, type?: VariantType) => void;
}

const Layout = ({ window }: LayoutProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [headerText, setHeaderText] = React.useState({
    title: "",
    subTitle: "",
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleChangeTitle = (title: string, subTitle: string) => {
    setHeaderText({
      title,
      subTitle,
    });
  };

  const showSnackbar = (message: string, type: VariantType = "error") => {
    enqueueSnackbar(message, {
      variant: type,
      action: (key) => <CloseIcon onClick={() => closeSnackbar(key)} />,
    });
  };

  const drawerWidth = 260;
  // const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box>
      <CssBaseline />
      <AppBar
        position="sticky"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          background: "#FFF",
          color: "#000",
          boxShadow: "none",
          pt: 2.5,
          pb: 1.5,
          top: "-1px",
        }}
      >
        <Toolbar>
          {/* <Box

                    >
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { md: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton> */}
          <Box sx={{ display: "block", width: "100%" }}>
            <Typography
              noWrap
              component="div"
              color="#141414"
              fontSize="30px"
              fontWeight={700}
              lineHeight={1.2}
              textAlign="center"
            >
              {headerText.title}
            </Typography>
            <Typography
              color="rgba(0, 0, 0, 0.70)"
              fontSize="16px"
              fontWeight="fontWeightRegular"
              lineHeight="24px"
              marginTop={0.5}
              textAlign="center"
            >
              {headerText.subTitle}
            </Typography>
          </Box>
          {/* </Box> */}
        </Toolbar>
      </AppBar>
      <Sidebar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: "6px",
          px: 3,
          ml: { md: "auto" },
          width: { md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Box sx={{ width: { lg: "900px", xs: "100%" }, m: "auto" }}>
          <Outlet context={{ handleChangeTitle, showSnackbar }} />
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
