import React from 'react'

const ImageFileIcon = ({ style = {}, color }: { style?: React.CSSProperties, color: string }) => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M18.7734 12.3751V4.78137H8.15625V31.2189H27.8438V13.8517H20.25C19.8584 13.8517 19.4828 13.6961 19.2059 13.4192C18.929 13.1423 18.7734 12.7667 18.7734 12.3751ZM14.0625 14.1329C14.8395 14.1329 15.4688 14.7622 15.4688 15.5392C15.4688 16.3161 14.8395 16.9454 14.0625 16.9454C13.2855 16.9454 12.6562 16.3161 12.6562 15.5392C12.6562 14.7622 13.2855 14.1329 14.0625 14.1329ZM24.4688 24.4689H11.5348C11.2992 24.4689 11.1691 24.1982 11.3133 24.0154L14.8219 19.5435C14.8482 19.5099 14.8818 19.4827 14.9202 19.464C14.9585 19.4453 15.0007 19.4356 15.0434 19.4356C15.086 19.4356 15.1282 19.4453 15.1665 19.464C15.2049 19.4827 15.2385 19.5099 15.2648 19.5435L16.7098 21.3857L19.4449 17.8982C19.4716 17.8645 19.5055 17.8373 19.5442 17.8186C19.5828 17.7999 19.6252 17.7902 19.6682 17.7902C19.7111 17.7902 19.7535 17.7999 19.7922 17.8186C19.8308 17.8373 19.8647 17.8645 19.8914 17.8982L24.6902 24.0154C24.8344 24.1982 24.7043 24.4689 24.4688 24.4689Z" fill="#E6F7FF" />
            <path d="M30.0445 10.1462L22.4789 2.58059C22.268 2.36965 21.9832 2.25012 21.6844 2.25012H6.75C6.12773 2.25012 5.625 2.75286 5.625 3.37512V32.6251C5.625 33.2474 6.12773 33.7501 6.75 33.7501H29.25C29.8723 33.7501 30.375 33.2474 30.375 32.6251V10.9443C30.375 10.6454 30.2555 10.3572 30.0445 10.1462ZM21.1641 4.84465L27.7805 11.4611H21.1641V4.84465ZM27.8438 31.2189H8.15625V4.78137H18.7734V12.3751C18.7734 12.7667 18.929 13.1423 19.2059 13.4192C19.4828 13.6961 19.8584 13.8517 20.25 13.8517H27.8438V31.2189Z" fill={color} />
            <path d="M19.4452 17.8982L16.71 21.3857L15.2651 19.5435C15.2388 19.5099 15.2052 19.4827 15.1668 19.464C15.1284 19.4453 15.0863 19.4356 15.0436 19.4356C15.0009 19.4356 14.9588 19.4453 14.9204 19.464C14.8821 19.4827 14.8485 19.5099 14.8221 19.5435L11.3135 24.0154C11.2811 24.0568 11.2609 24.1066 11.2554 24.1589C11.2499 24.2113 11.2592 24.2642 11.2823 24.3115C11.3054 24.3588 11.3414 24.3986 11.3861 24.4265C11.4308 24.4543 11.4824 24.469 11.535 24.4689H24.469C24.7046 24.4689 24.8347 24.1982 24.6905 24.0154L19.8917 17.8982C19.865 17.8645 19.8311 17.8373 19.7924 17.8186C19.7538 17.7999 19.7114 17.7902 19.6684 17.7902C19.6255 17.7902 19.5831 17.7999 19.5445 17.8186C19.5058 17.8373 19.4719 17.8645 19.4452 17.8982ZM12.6565 15.5392C12.6565 15.9122 12.8047 16.2698 13.0684 16.5336C13.3321 16.7973 13.6898 16.9454 14.0628 16.9454C14.4357 16.9454 14.7934 16.7973 15.0571 16.5336C15.3209 16.2698 15.469 15.9122 15.469 15.5392C15.469 15.1662 15.3209 14.8085 15.0571 14.5448C14.7934 14.2811 14.4357 14.1329 14.0628 14.1329C13.6898 14.1329 13.3321 14.2811 13.0684 14.5448C12.8047 14.8085 12.6565 15.1662 12.6565 15.5392Z" fill={color} />
        </svg>
    )
}

export default ImageFileIcon