import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Loader from "../components/Common/Loader/Loader";
import { OutletcontextType } from "../components/Layout/Index";
import FeatureFrame from "../components/Common/FeatureFrame";
import LiveIcon from "../Icons/LiveIcon";
import VideoCameraIcon from "../Icons/VideoCameraIcon";
import MapIcon from "../Icons/MapIcon";
import CustomButton from "../components/Common/CustomButton";
import ArrowLeftIcon from "../Icons/ArrowLeftIcon";

const VideoKyc = () => {
  const { handleChangeTitle } = useOutletContext<OutletcontextType>();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "AiFise | Video KYC";
    handleChangeTitle(
      "Video KYC",
      "Make Customer Onboarding a Comfortable Experience in Their Homes."
    );
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Grid container justifyContent="center" pt={3}>
        <Grid item lg={10} md={6} sm={6} xs={12}>
          <Typography
           
                
                  sx={{
                    color: "rgba(0, 0, 0, 0.70)",
                    textAlign: "center",
                    // fontFamily: "Avenir LT Std",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "24px",
                  }}
          
          >
            Deliver unparalleled trust and convenience when customers sign up
            for your services
          </Typography>
        </Grid>
      </Grid>

      <div style={{ paddingTop: "24px" }}>
        <CustomButton
          onClick={() => {
            window.open("https://main.d1swmz3zcnor5j.amplifyapp.com/login");
          }}
          sx={{
            display: "flex",
            padding: "10px 10px 10px 26px",
            alignItems: "center",
            gap: "16px",
            borderRadius: "200px",
            border: "1px solid #0D93F0",
            background: "#0D93F0",
            margin: "auto",
          }}
        >
          <div
            style={{
              color: "#FFF",

              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "350",
              lineHeight: "28px",
            }}
          >
            See it in Action
          </div>
          <div
            style={{
              display: "flex",
              padding: "10px",
              alignItems: "center",
              gap: "10px",
              borderRadius: "200px",
              background: "#FFFF",
            }}
          >
            <ArrowLeftIcon />
          </div>
        </CustomButton>
      </div>

      <FeatureFrame
        frameTitle="Meets all compliance requirements"
        frameDescription="Essential Video KYC Advantages"
        icon1={<LiveIcon />}
        icon2={<VideoCameraIcon />}
        icon3={<MapIcon />}
        iconDescription1="Real-time Video Streaming with Secure End-to-End Encryption Between Host and Server."
        iconDescription2="High-Quality Video for Absolute Customer Identity Varification."
        iconDescription3="Real-time Location Verification to Confirm the Customer's Physical Presence in India."
        iconTitle1="Live Streaming"
        iconTitle2="Video Quality"
        iconTitle3="Live Location"
      />
      <style>{`.css-3ef1jy-MuiGrid-root{  padding-top: 0px; }`}</style>
    </>
  );
};

export default VideoKyc;
