import { Box, Button } from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import CustomButton from "../../Common/CustomButton";

type HeaderTopProps = {
  handleFileChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  enableUpload?: boolean;
  reset?: () => void;
  docUrl?: string;
  title?: string;
};

const HeaderTop = ({
  handleFileChange,
  reset,
  enableUpload = false,
  title = "",
}: HeaderTopProps) => {
  const params = useParams();
  console.log(params, "params");

  const location = useLocation();
  const lastEndPoint = location.pathname.split("/").filter(Boolean).pop();
  console.log(lastEndPoint, "lastEndPoint");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        rowGap: 1.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1.5,
          flexWrap: "wrap",
        }}
      >
        {title !== "" && (
          <Box
            sx={{
              borderRadius: "2px",
              border: "1px solid rgba(115, 209, 61, 0.40)",
              background: "rgba(115, 209, 61, 0.40)",
              padding: "8px 16px",
              color: "#000000D9",
              fontSize: 14,
              fontWeight: "fontWeightMedium",
              lineHeight: "26px",
              textTransform: "capitalize",
            }}
          >
            {title}
          </Box>
        )}
        {enableUpload && (
          <CustomButton
            startIcon={<FileUploadOutlinedIcon />}
            component="label"
            size="large"
          >
            New Image
            <input type="file" hidden onChange={handleFileChange} />
          </CustomButton>
        )}
        <CustomButton
          variant="outlined"
          startIcon={<RefreshOutlinedIcon />}
          size="large"
          onClick={reset}
        >
          Reset
        </CustomButton>
      </Box>
      <Button
        variant="contained"
        size="large"
        color="success"
        // component={Link}
        // to={`api-docs/`}
        // to={`${getAPIDoc(lastEndPoint)}`}
        sx={{
          borderRadius: 0.5,
          border: "1px solid #38B832",
          background: "#38B832",
          textTransform: "capitalize",
          color: "white",
          boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)",
          "&:hover": {
            background: "#298E35",
            borderColor: "298E35",
            boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)",
          },
        }}
      >
        API Documentation
      </Button>
    </Box>
  );
};

const getAPIDoc = (lastEndPoint: string | undefined) => {
  switch (lastEndPoint) {
    case "face-match":
      return `api-docs/inno-fm`;
    case "ocr":
      return `api-docs/inno-ocr`;
    case "face-analysis":
      return `api-docs/inno-face-analysis`;
    case "aadhar-masking":
      return `api-docs/inno-aadhar-masking`;
    case "ckyc-cropping":
      return `api-docs/inno-ckyc-cropping`;
    case "voterid-verification":
      return `api-docs/inno-vid-verification`;
    case "violence-detection":
      return `api-docs/inno-moderation`;
    default:
      return `api-docs/inno-fm`;
  }
};

export default HeaderTop;
