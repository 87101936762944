import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import FileDragDrop from "../components/Common/FileDragDrop";
import Loader from "../components/Common/Loader/Loader";
import HeaderTop from "../components/pages/OCR/HeaderTop";
import JSONCard from "../components/Common/JSONCard";
import ImagePreviewCard from "../components/pages/OCR/ImagePreviewCard";
import { aadharMaskingApi, imageOcr } from "../service/apiActions/OcrAction";
import { OutletcontextType } from "../components/Layout/Index";
import BarChartIcon from "../Icons/BarChartIcon";
import ContentCard from "../components/Common/ContectCard";
import LanguageIcon from "../Icons/LanguageIcon";
import DataSecurityCard from "../components/Common/DataSecurityCard";
import axios from "axios";
import FeatureFrame from "../components/Common/FeatureFrame";
import LiveIcon from "../Icons/LiveIcon";
import VideoCameraIcon from "../Icons/VideoCameraIcon";
import MapIcon from "../Icons/MapIcon";
import AdharIcon1 from "../Icons/AdharIcon1";
import AdharIcon2 from "../Icons/AdharIcon2";
import AdharIcon3 from "../Icons/AdharIcon3";

const AadharMasking = () => {
  const { handleChangeTitle, showSnackbar } =
    useOutletContext<OutletcontextType>();
  const [formdata, setFormdata] = useState<any>({});
  const [isLoading, setLoading] = useState(false);
  const [result, setResult] = useState<any>(null);

  useEffect(() => {
    document.title = "AiFise | Aadhar Masking";
    handleChangeTitle(
      "Aadhar Masking",
      "Masking aadhar number from Aadhar image."
    );
  }, []);

  const handleFileChange = async (files: any, e: any) => {
    if (files.length > 0 && files[0].size > 1024 * 1024 * 3) {
      showSnackbar("File size cannot be greater than 3 MB");
      return;
    } else if (
      files.length > 0 &&
      files[0].type !== "image/png" &&
      files[0].type !== "image/jpeg" &&
      files[0].type !== "image/jpg"
    ) {
      showSnackbar("Only png, jpg and jpeg are allowed");
      return;
    }
    setFormdata(files[0]);
    setLoading(true);
    let _formdata = new FormData();
    _formdata.append("file", files[0]);

    const auth = localStorage.getItem("userLoginData")
      ? JSON.parse(localStorage.getItem("userLoginData") as string)
      : null;
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token") as string)
      : null;

    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/masking`, _formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization":
            auth?.token !== undefined
              ? `Bearer ${auth?.token}`
              : `Bearer ${token}`,
        },
        responseType: "arraybuffer",
      })
      .then((res: any) => {
        setLoading(false);
        var blob = new Blob([res.data], { type: "image/*" });
        setResult(blob);
      })
      .catch(() => {
        setLoading(false);
        showSnackbar("Something went wrong");
      });
  };

  const handleReset = () => {
    setResult(null);
    setFormdata({});
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {result === null ? (
        <Grid container justifyContent="center">
          <Grid item lg={5} md={6} sm={6} xs={12}>
            <FileDragDrop handleChange={handleFileChange} />
          </Grid>
          <Grid item xs={12} mt={2}>
            <Typography variant="body2" color="text.secondary" align="center">
              <b>Note:</b> File size should be less than 3 MB and only png, jpg
              and jpeg are allowed.
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container justifyContent="center">
            <Grid item lg={8}>
              <HeaderTop
                handleFileChange={(e) => {
                  setResult(null);
                  handleFileChange(e.target.files, e);
                }}
                reset={handleReset}
                enableUpload
              />
            </Grid>
          </Grid>
          <Box>
            <Grid container spacing={2} mt={0} justifyContent="center">
              <Grid item lg={4} md={5} sm={6} xs={12}>
                <ImagePreviewCard
                  src={URL.createObjectURL(formdata)}
                  imageName={formdata?.name}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={6} xs={12}>
                <ImagePreviewCard src={URL.createObjectURL(result)} />
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      {/* <Grid xs={12}>
                    <ContentCard>
                        <ContentCard.Title>
                            Why choose AIFISE's Image to Text Converter?
                        </ContentCard.Title>
                        <ContentCard.Text>
                            Here's why you should utilize our OCR tool to scan and extract text from your files
                        </ContentCard.Text>
                        <ContentCard.Text>
                            Incorporate Optical Character Recognition (OCR) capabilities into your applications using our APIs.
                        </ContentCard.Text>
                        <Box
                            sx={{
                                background: "#BAE7FF",
                                borderRadius: "8px",
                                p: "36px",
                                mt: "16px"
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <ContentCard.BoxCard
                                        icons={<BarChartIcon />}
                                        title="Result"
                                        subTitle='Highly accurate results'
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <ContentCard.BoxCard
                                        icons={
                                            <BarChartIcon />
                                        }
                                        title="Multiple imports"
                                        subTitle='JPG, PNG, JPEG and more'
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <ContentCard.BoxCard
                                        icons={<LanguageIcon />}
                                        title="Languages"
                                        subTitle='Multiple support'
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </ContentCard>
                </Grid> */}

      <FeatureFrame
        frameTitle="Unlocking the Power of Aadhaar Masking"
        frameDescription="Discover Why Our Solution is Essential!"
        icon1={<AdharIcon1 />}
        icon2={<AdharIcon2 />}
        icon3={<AdharIcon3 />}
        iconDescription1="Masks your Aadhaar number, your valuable digits remain concealed, minimizing misuse and data exposure."
        iconDescription2="Integrate our easy-to-use APIs and SDKs anywhere - on-premises or cloud - for instant document privacy."
        iconDescription3="Take control of your content, share with confidence."
        iconTitle1="Enhanced Security"
        iconTitle2="Seamless Integration"
        iconTitle3="Secure Sharing"
      />
    </>
  );
};

export default AadharMasking;
