

const ExclamationCircle = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
  <g clip-path="url(#clip0_2947_1987)">
    <path d="M11 0.5C4.92545 0.5 0 5.42545 0 11.5C0 17.5746 4.92545 22.5 11 22.5C17.0746 22.5 22 17.5746 22 11.5C22 5.42545 17.0746 0.5 11 0.5ZM11 20.6339C5.9567 20.6339 1.86607 16.5433 1.86607 11.5C1.86607 6.4567 5.9567 2.36607 11 2.36607C16.0433 2.36607 20.1339 6.4567 20.1339 11.5C20.1339 16.5433 16.0433 20.6339 11 20.6339Z" fill="#FFC53D"/>
    <path d="M9.82031 15.8214C9.82031 16.134 9.94448 16.4338 10.1655 16.6548C10.3865 16.8758 10.6863 17 10.9989 17C11.3115 17 11.6112 16.8758 11.8323 16.6548C12.0533 16.4338 12.1775 16.134 12.1775 15.8214C12.1775 15.5089 12.0533 15.2091 11.8323 14.9881C11.6112 14.767 11.3115 14.6429 10.9989 14.6429C10.6863 14.6429 10.3865 14.767 10.1655 14.9881C9.94448 15.2091 9.82031 15.5089 9.82031 15.8214ZM10.4096 13.0714H11.5882C11.6962 13.0714 11.7846 12.983 11.7846 12.875V6.19643C11.7846 6.08839 11.6962 6 11.5882 6H10.4096C10.3016 6 10.2132 6.08839 10.2132 6.19643V12.875C10.2132 12.983 10.3016 13.0714 10.4096 13.0714Z" fill="#FFC53D"/>
  </g>
  <defs>
    <clipPath id="clip0_2947_1987">
      <rect width="22" height="22" fill="white" transform="translate(0 0.5)"/>
    </clipPath>
  </defs>
</svg>
    </div>
  )
}

export default ExclamationCircle
