import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Loader from "../components/Common/Loader/Loader";
import { OutletcontextType } from "../components/Layout/Index";
import AvailBox from "../components/Common/AvailBox";
import UserFreindlyIcon from "../Icons/UserFreindlyIcon";
import FieldTimeIcon from "../Icons/FieldTimeIcon";
import BandwidthIcon from "../Icons/BandwidthIcon";
import FeatureFrame from "../components/Common/FeatureFrame";

const Liveliness = () => {
  const { handleChangeTitle } = useOutletContext<OutletcontextType>();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "AiFise | Liveliness";
    handleChangeTitle(
      "Liveness Detection",
      "Identify the authenticity of a selfie."
    );
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div style={{ paddingBottom: "200px" }}>
      <Grid container justifyContent="center" pt={3}>
        <Grid item lg={10} md={6} sm={6} xs={12}>
          <Typography
            variant="h6"
            sx={{
              color: "rgba(0, 0, 0, 0.70)",
              textAlign: "center",
              // fontFamily: "Avenir LT Std",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "24px",
            }}
          >
            Protect your business using AIFISE's cutting-edge Liveness Detection
            technology, capable of instantly detecting all forms of spoofing
            fraud.
          </Typography>
        </Grid>
      </Grid>

      <AvailBox />

      <FeatureFrame
        frameTitle="How We Achieve Effortless Excellence: Elevating Your User Experience"
        frameDescription="Here's the reason to make the most of our Liveness Detection"
        icon1={<UserFreindlyIcon />}
        icon2={<FieldTimeIcon />}
        icon3={<BandwidthIcon />}
        iconDescription1="Detects a user's liveness using a simple selfie"
        iconDescription2="A Machine Trained For Recognizing Facial Differences"
        iconDescription3="As small as 200KB, it welcomes crisp, clear images"
        iconTitle1=" User-friendly"
        iconTitle2="Detect all face"
        iconTitle3="Bandwidth"
      />

      <Grid container justifyContent="center" p={5}>
        <Grid item lg={12} md={12} xs={12}>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Typography
              sx={{
                color: "#000",
                textAlign: "center",
                // fontFamily: "Avenir LT Std",
                fontSize: "24px",
                // fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "28.8px",
                maxWidth: "602px",
              }}
            >
              Instant Verification
            </Typography>
            <Typography
              sx={{
                color: "rgba(0, 0, 0, 0.70)",
                textAlign: "center",
                // fontFamily: "Avenir LT Std",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "fontWeightRegular",
                lineHeight: "19.2px",
                padding: "16px 0px",
              }}
            >
               With a simple head movement, your users can instantly verify
              themselves on their smartphone or webcam, achieving pass rates of
              up to 99% and ensuring happier clients.
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={10} md={12} xs={12} paddingTop={2}>
          {/* <Box> */}
          <Grid container justifyContent={"center"}>
            <Grid item paddingRight={1}>
              <img
                src="/images/Liveliness.png"
                alt="liveliness"
                style={{
                  maxHeight: "256px",
                  borderRadius: "4.923px",
                  boxShadow:
                    "0px 3.44615px 7.38462px 0px rgba(0, 0, 0, 0.10), 0px 13.78462px 13.78462px 0px rgba(0, 0, 0, 0.09), 0px 30.52308px 18.21539px 0px rgba(0, 0, 0, 0.05), 0px 54.15385px 21.66154px 0px rgba(0, 0, 0, 0.01), 0px 84.67693px 23.63077px 0px rgba(0, 0, 0, 0.00)",
                }}
              />
            </Grid>
            <Grid item>
              <Box
                marginTop={{ md: 6, lg: 6, xl: 6, sm: 2 }}
                marginLeft={{ sm: 0, md: 6, lg: 6, xl: 6 }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    // fontFamily: "Avenir LT Std",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: "fontWeightBold",
                    lineHeight: "28.8px",
                  }}
                >
                  Perfectly compatible with:
                </Typography>
                <Box>
                  <ul
                    style={{
                      color: "rgba(0, 0, 0, 0.70)",
                      // fontFamily: "Avenir LT Std",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "fontWeightRegular",
                      lineHeight: "33.2px",
                    }}
                  >
                    <li>Glasses, beards, and makeup</li>
                    <li>Various nationalities</li>
                    <li>Slow internet connection</li>
                    <li>All modern smart devices and web cameras</li>
                  </ul>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {/* </Box> */}
        </Grid>
      </Grid>
      <style>{`.css-3ef1jy-MuiGrid-root{  padding-top: 0px; }`}</style>
    </div>
  );
};

export default Liveliness;
