import { Box, IconButton, Stack, Typography } from '@mui/material'
import ImageFileIcon from '../../../Icons/ImageFileIcon'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { SxProps } from '@mui/system';

type FilePreviewProps = {
    name: string,
    filesize: string,
    handleClose: () => void,
    sx?: SxProps
}

const FilePreview = ({ name, filesize, handleClose, sx = {} }: FilePreviewProps) => {
    return (
        <Stack
            direction="row"
            alignItems="center"
            sx={{
                border: "0.5px solid #9F9FA1",
                borderRadius: "2px",
                position: "relative",
                width: 'calc(100% - 9px)',
                ...sx
            }}
        >
            <Box
                sx={{
                    background: "#F2F1F6",
                    padding: "6px",
                    borderRight: "0.5px solid #9F9FA1"
                }}
            >
                <ImageFileIcon color="#007AFE" style={{ verticalAlign: "middle" }} />
            </Box>
            <Box
                sx={{
                    background: "#FFF",
                    p: "7px 10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "8px",
                    border: ""
                }}
            >
                <Typography
                    variant='subtitle2'
                    sx={{
                        fontSize: 14,
                        fontWeight: "fontWeightRegular",
                        color: "#000",
                        lineHeight: 1
                    }}
                >
                    {name}
                </Typography>
                <Typography
                    variant='subtitle2'
                    sx={{
                        fontSize: 12,
                        fontWeight: "fontWeightLight",
                        color: "rgba(0, 0, 0, 0.45)",
                        lineHeight: 1
                    }}
                >
                    {filesize}
                </Typography>
            </Box>
            <IconButton
                onClick={handleClose}
                sx={{
                    position: "absolute",
                    top: "-12px",
                    right: "-12px",
                    padding: "0px"
                }}
            >
                <CancelRoundedIcon sx={{ color: "#595959", }} />
            </IconButton>
        </Stack>
    )
}

export default FilePreview