import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Box,
  InputLabel,
  Button,
  Divider,
  FilledInput,
  OutlinedInput,
  InputAdornment,
  FormControl,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import {
  getUserProfileApi,
  updateProfileApi,
  updateUserPassowrdApi,
} from "../../service/apiActions/FaceAction";
import { VariantType, useSnackbar } from "notistack";
import SaveChanges from "../../Icons/SaveChanges";
import UpdatePassword from "../../Icons/UpdatePassword";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../Common/CustomButton";
import DisabledSaveIcon from "../../Icons/DisabledSaveIcon";
import { useDispatch, useSelector } from "react-redux";
import { changeProfileName } from "../../reduxSlice/profile/profileSlice";

const ProfileComponent = () => {
  const dispatch = useDispatch();

  interface FormData {
    name: string;
    // lastName: string;
    phone: string;
    email: string;
    organization: string;
    designation: string;
  }
  interface FormErrors {
    name?: string;
    // lastName?: string;
    phone?: string;
    email?: string;
    organization?: string;
    designation?: string;
  }

  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  // const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [formErrorPassword, setFormErrorPassword] = useState<{
    [key: string]: string;
  }>({});
  const [oldPassword, setOldPassword] = useState("");
  const [formErrorOldPassword, setFormErrorOldPassword] = useState<string>("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isFormModified, setIsFormModified] = useState(false);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const [initialFormData, setInitialFormData] = useState<FormData>({
    name: "",
    phone: "",
    email: "",
    organization: "",
    designation: "",
  });
  const [isProfileUpdated, setIsProfileUpdated] = useState(false);
  const [usersubmit, setUserSubmit] = useState(false);

  // const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword = () => {
    setShowPassword((prevShow) => !prevShow);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const showSnackbar = (message: string, type: VariantType = "error") => {
    enqueueSnackbar(message, {
      variant: type,
      action: (key) => <CloseIcon onClick={() => closeSnackbar(key)} />,
    });
  };
  const reduxState = useSelector((state: any) => state);
  console.log("reduxState", reduxState);

  const [formData, setFormData] = useState<FormData>({
    name: "",
    // lastName: "",
    phone: "",
    email: "",
    organization: "",
    designation: "",
  });
  // const user = JSON.parse(localStorage.getItem("userLoginData") as string);
  console.log(formData.name, "userDatafrom");

  useEffect(() => {
    const fetchUser = async () => {
      getUserProfileApi()
        .then((res: any) => {
          console.log(res);
          const initialData = {
            name: res.name,
            phone: res.phone,
            email: res.email,
            organization: res.organization,
            designation: res.designation,
          };
          setFormData(initialData);
          setInitialFormData(initialData);
          setIsInitialDataLoaded(true);
        })
        .catch((err: any) => {
          console.log(err);
        });
    };
    fetchUser();
  }, [isProfileUpdated]);

  useEffect(() => {
    // Checking if the form data has been modified, but only after the initial data is loaded
    if (isInitialDataLoaded) {
      const isModified =
        formData.name !== initialFormData.name ||
        formData.phone !== initialFormData.phone ||
        formData.email !== initialFormData.email ||
        formData.organization !== initialFormData.organization ||
        formData.designation !== initialFormData.designation;

      setIsFormModified(isModified);
    }
  }, [formData, isInitialDataLoaded, initialFormData]);

  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const validateForm = () => {
    const errors: FormErrors = {};

    // Validate first name
    if (!formData.name.trim()) {
      errors.name = "First Name is required";
    }

    // Validate last name
    if (!formData.designation.trim()) {
      errors.designation = "Designation is required";
    }

    // Validate contact number
    if (!formData.phone.trim()) {
      errors.phone = "Contact Number is required";
    } else if (!/^\d{10}$/.test(formData.phone.trim())) {
      errors.phone = "Invalid phone number (must be 10 digits)";
    }

    // Validate email
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      errors.email = "Invalid email address";
    }

    // Validate organization
    if (!formData.organization.trim()) {
      errors.organization = "Organization is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  console.log(formData, "formData");
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (validateForm()) {
      const { email, ...formDataWithoutEmail } = formData;
      console.log("Form submitted successfully!", formDataWithoutEmail);
      updateProfileApi(formDataWithoutEmail)
        .then((res: any) => {
          console.log(res);
          showSnackbar("Your information was successfully updated!", "success");
          setIsProfileUpdated((prev) => !prev);
          const userInfoString = localStorage.getItem("userLoginData");

          if (userInfoString) {
            const userInfo = JSON.parse(userInfoString);
            dispatch(changeProfileName(formData.name));

            const newUpdatedUserInfo = {
              ...userInfo,
              name: formData.name,
            };

            localStorage.setItem(
              "userLoginData",
              JSON.stringify(newUpdatedUserInfo)
            );
          } else {
            console.error("Error: Unable to parse userInfo from localStorage");
          }
          setUserSubmit(true);
          // dispatch(changeState(true));
          // dispatch(changeState({ profileValue: true }));
        })
        .catch((err: any) => {
          console.log(err);
          showSnackbar(err.message, "error");
        });
    } else {
      console.log("Form has validation errors");
    }
  };

  useEffect(() => {
    // Reset formErrors when formData changes
    setFormErrors({});
  }, [formData]);

  // ========================================================= password ===========================================================  //

  const validateFormPassword = () => {
    const errors: { [key: string]: string } = {};

    // Validate new password
    if (!newPassword.trim()) {
      errors.newPassword = "New Password is required";
    }

    // Validate old password
    if (!oldPassword.trim()) {
      errors.oldPassword = "Old Password is required";
    }

    setFormErrorPassword(errors);

    // Return true only if there are no errors other than old and new passwords being the same
    return (
      Object.keys(errors).length === 0 ||
      (Object.keys(errors).length === 1 &&
        errors.oldPassword === "Old and new password cannot be the same")
    );
  };

  const handleSubmitpassword = async (e: React.FormEvent) => {
    e.preventDefault();

    if (validateFormPassword()) {
      // Perform your password update logic here
      console.log("Password updated successfully!");

      if (oldPassword === newPassword) {
        setFormErrorOldPassword("Old and new password cannot be the same");
        showSnackbar("Old and new password cannot be the same", "error");
        return;
      }

      const passwordData = {
        email: formData.email,
        password: newPassword,
        oldpassword: oldPassword,
      };

      updateUserPassowrdApi(passwordData)
        .then((res: any) => {
          console.log(res);
          showSnackbar("Password Update Successfully!", "success");
          setIsProfileUpdated((prev) => !prev);
        })
        .catch((err: any) => {
          console.log(err);
          showSnackbar(err.message, "error");
        });
    } else {
      console.log("Form has validation errors");
      showSnackbar("Form has validation errors", "error");
    }
  };

  useEffect(() => {
    // Reset formErrorPassword when input values change
    setFormErrorPassword({});
  }, [newPassword, oldPassword]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography
              sx={{
                color: "#000",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              Personal details
            </Typography>
          </Grid>
          <Grid item xs={8.5}>
            <Grid item xs={12} sx={{ display: "flex" }} spacing={2}>
              <Grid
                item
                xs={8}
                spacing={2}
                sx={{ display: "flex", flexDirection: "column", mr: 6 }}
              >
                <InputLabel> Full Name</InputLabel>
                <TextField
                  size="small"
                  required
                  id="name"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  sx={{ marginTop: "8px" }}
                />
                {formErrors.name && (
                  <Typography color="error">{formErrors.name}</Typography>
                )}
              </Grid>
              {/* <Grid
                item
                xs={3.7}
                spacing={2}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <InputLabel> Last Name</InputLabel>
                <TextField
                  // required
                  id="lastName"
                  value={formData.lastName}
                  onChange={(e) =>
                    setFormData({ ...formData, lastName: e.target.value })
                  }
                />
              </Grid> */}
            </Grid>
            <Grid
              item
              xs={8}
              sx={{ display: "flex", flexDirection: "column", mt: 3 }}
            >
              <InputLabel>Contact Number</InputLabel>
              <TextField
                size="small"
                required
                id="phone"
                value={formData.phone}
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
                sx={{ marginTop: "8px" }}
              />
              {formErrors.phone && (
                <Typography color="error">{formErrors.phone}</Typography>
              )}
            </Grid>
            <Grid
              item
              xs={8}
              sx={{ display: "flex", flexDirection: "column", mt: 3 }}
            >
              <InputLabel>Email</InputLabel>
              <TextField
                // contentEditable={false}
                size="small"
                disabled
                required
                id="email"
                value={formData.email}
                // onChange={(e) =>
                //   setFormData({ ...formData, email: e.target.value })
                // }
                sx={{ marginTop: "8px" }}
              />
              {formErrors.email && (
                <Typography color="error">{formErrors.email}</Typography>
              )}
            </Grid>
          </Grid>

          <Grid item xs={3}>
            {" "}
            <Typography
              sx={{
                color: "#000",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "24px",
                mt: 5,
              }}
            >
              Affiliations
            </Typography>
          </Grid>

          <Grid item xs={8.5}>
            <Grid
              item
              xs={8}
              sx={{ display: "flex", flexDirection: "column", mt: 5 }}
            >
              <InputLabel>Organization</InputLabel>
              <TextField
                size="small"
                required
                id="organization"
                value={formData.organization}
                onChange={(e) =>
                  setFormData({ ...formData, organization: e.target.value })
                }
                sx={{ marginTop: "8px" }}
              />
              {formErrors.organization && (
                <Typography color="error">{formErrors.organization}</Typography>
              )}
            </Grid>
            <Grid
              item
              xs={8}
              sx={{ display: "flex", flexDirection: "column", mt: 5 }}
            >
              <InputLabel>Designation</InputLabel>
              <TextField
                size="small"
                required
                id="designation"
                value={formData.designation}
                onChange={(e) =>
                  setFormData({ ...formData, designation: e.target.value })
                }
                sx={{ marginTop: "8px" }}
              />
              {formErrors.designation && (
                <Typography color="error">{formErrors.designation}</Typography>
              )}
            </Grid>
            <Grid
              item
              xs={8}
              sx={{ display: "flex", mt: 3, mb: 3 }}
              justifyContent="flex-end"
            >
              <CustomButton
                type="submit"
                variant="contained"
                disabled={!isFormModified}
                sx={{
                  border: "none",
                 
                }}
              >
                {isFormModified ? <SaveChanges /> : <DisabledSaveIcon />}
                <Typography sx={{ ml: 1, textTransform: "none" }}>
                  {" "}
                  Save Changes
                </Typography>

              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Divider sx={{ width: "80%" }} />
      <form onSubmit={handleSubmitpassword} style={{marginBottom:"60px"}}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography
              sx={{
                color: "#000",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "24px",
                mt: 5,
              }}
            >
              Update Password
            </Typography>
          </Grid>
          <Grid item xs={8.5}>
            <Grid
              item
              xs={8}
              sx={{ display: "flex", flexDirection: "column", mt: 5 }}
            >
              <InputLabel>Old Password</InputLabel>
              <FormControl sx={{ mt: 1 }} variant="outlined">
                <OutlinedInput
                size="small"
                  id="oldPassword"
                  type={showPassword ? "text" : "password"}
                  value={oldPassword}
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                    setFormErrorOldPassword(""); // Clear old password error on input change
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formErrorOldPassword && (
                  <Typography color="error">{formErrorOldPassword}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={8}
              sx={{ display: "flex", flexDirection: "column", mt: 5 }}
            >
              <InputLabel>New Password</InputLabel>
              <FormControl sx={{ mt: 1 }} variant="outlined">
                <OutlinedInput
                size="small"
                  id="newPassword"
                  type={showPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formErrorPassword.newPassword && (
                  <Typography color="error">
                    {formErrorPassword.newPassword}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={8} mb={2} display="flex" justifyContent="flex-end">
              <CustomButton
                type="submit"
                variant="contained"
                sx={{ display: "flex", mt: 2 }}
              >
                <UpdatePassword />{" "}
                <Typography sx={{ ml: 1, textTransform: "none" }}>
                  Update Password
                </Typography>
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </form>

      <style>
        {`.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input  {
          padding: 12px
        }`}

        {`.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
          padding: 12px
        }`}
      </style>
    </div>
  );
};

export default ProfileComponent;
