import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Loader from "../components/Common/Loader/Loader";
import { OutletcontextType } from "../components/Layout/Index";
import FeatureFrame from "../components/Common/FeatureFrame";
import LiveIcon from "../Icons/LiveIcon";
import VideoCameraIcon from "../Icons/VideoCameraIcon";
import MapIcon from "../Icons/MapIcon";
import CustomButton from "../components/Common/CustomButton";
import ArrowLeftIcon from "../Icons/ArrowLeftIcon";
import Gist1 from "../Icons/Gist1";
import Gist2 from "../Icons/Gist2";
import Gist3 from "../Icons/Gist3";

const Gist = () => {
  const { handleChangeTitle } = useOutletContext<OutletcontextType>();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "AiFise | Gist";
    handleChangeTitle(
      "GIST",
      "Your PDF AI."
    );
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Grid container justifyContent="center" pt={3}>
        <Grid item lg={10} md={6} sm={6} xs={12}>
          <Typography
            sx={{
              color: "rgba(0, 0, 0, 0.70)",
              textAlign: "center",
              // fontFamily: "Avenir LT Std",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "24px",
            }}
          >
            Deliver unparalleled trust and convenience when customers sign up
            for your services
          </Typography>
        </Grid>
      </Grid>

      <div style={{ paddingTop: "24px" }}>
        <CustomButton
          onClick={() => {
            window.open("https://www.gistai.in");
          }}
          sx={{
            display: "flex",
            padding: "10px 10px 10px 26px",
            alignItems: "center",
            gap: "16px",
            borderRadius: "200px",
            border: "1px solid #0D93F0",
            background: "#0D93F0",
            margin: "auto",
          }}
        >
          <div
            style={{
              color: "#FFF",

              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "350",
              lineHeight: "28px",
            }}
          >
            See it in Action
          </div>
          <div
            style={{
              display: "flex",
              padding: "10px",
              alignItems: "center",
              gap: "10px",
              borderRadius: "200px",
              background: "#FFFF",
            }}
          >
            <ArrowLeftIcon />
          </div>
        </CustomButton>
      </div>

      <FeatureFrame
        frameTitle="We're here to help you effortlessly manage your files as you engage with your PDFs!"
        frameDescription="Essential Customer On-boarding Advantages"
        icon1={<Gist1 />}
        icon2={<Gist2 />}
        icon3={<Gist3 />}
        iconDescription1="Beyond text search. AI dives deep into your documents, surfacing relevant answers."
        iconDescription2="Easily upload the PDF documents you'd like to chat with."
        iconDescription3="Skip the scroll, embrace the revelation. AI navigation transforms document exploration."
        iconTitle1="AI-Enhanced"
        iconTitle2="Document Upload"
        iconTitle3="Immediate Responses"
      />
      <style>{`.css-3ef1jy-MuiGrid-root{  padding-top: 0px; }`}</style>
    </>
  );
};

export default Gist;
