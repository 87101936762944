import {
  Box,
  Button,
  Divider,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import React, { useEffect, useState } from "react";
import UserIcon from "../../Icons/UserIcon";
import SettingsIcon from "../../Icons/SettingsIcon";
import LogoutIcon from "../../Icons/LogoutIcon";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { changeProfileName } from "../../reduxSlice/profile/profileSlice";
import { RootState } from "../../redux/store";

const ProfileBox = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [name, setName] = useState("");
  const [userImage, setUserImage] = useState("");

  // Setting Name BG Color

  // const bgColors = ["#009933", "#999900", "#33ccff", "#FF0000", "#734d26"];

  // const getRandomColor = () => {
  //   return bgColors[Math.floor(Math.random() * bgColors.length)];
  // };

  //  const currentProfileValue = useSelector(
  //    (state:any) => state.profileSlice.profileValue
  //  );
  // const storeName = useSelector((state: RootState) => state.profileName);
  const storeName = useSelector(
    (state: /* Add the correct type for your Redux state */ RootState) =>
      state.profile.profileName
  );

  console.log("storeName :", storeName);

  useEffect(() => {
    // const name = localStorage.getItem("userLoginData")
    //   ? JSON.parse(localStorage.getItem("userLoginData") as string)
    //   : setName(JSON.parse(localStorage.getItem("name") as string));
    // if (name) {
    //   name.name === "" ? setName("User Name") : setName(name.name);
    // }
    const name = storeName;
    const image = localStorage.getItem("userLoginData")
      ? JSON.parse(localStorage.getItem("userLoginData") as string)
      : null;
    if (image) {
      setUserImage(image.image);
    }
  }, []);

  const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingRight: "2px",
        mt: "10px",
        mb: "10px",
        // width: "100%",
        // ":hover": {
        //   background: "rgba(0, 0, 0, 0.04)",
        // },
      }}
    >
      <Button
        onClick={handlePopoverClick}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          columnGap: 1,
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "calc(100% - 48px)",
          }}
        >
          {/* <img
            src={userImage ? userImage : "https://source.unsplash.com/random"}
            alt="profile-img"
            width="35px"
            height="35px"
          /> */}
          <div
            style={{
              width: "35px",
              height: "35px",
              borderRadius: "50%",
              backgroundColor: "#009933",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              fontWeight: 500,
              fontSize: "16px",
              textTransform: "uppercase",
            }}
          >
            {storeName.charAt(0).toUpperCase() + storeName.slice(1, 2)}
          </div>
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: "14px",
              fontWeight: "fontWeightRegular",
              color: "#000",
              lineHeight: 1,
              pl: "8px",
              textTransform: "none",
              textOverflow: "ellipsis",
              width: "calc(100% - 35px)",
              overflow: "hidden",
              textAlign: "left",
            }}
          >
            {storeName.split(" ")[0]}
          </Typography>
        </div>
        <IconButton
          aria-describedby={id}
          onClick={handlePopoverClick}
          sx={{
            // marginLeft: "70px",
            ":hover": {
              background: "transparent",
            },
            ":active": {
              background: "transparent",
            },
          }}
        >
          <MoreHorizIcon fontSize="medium" />
        </IconButton>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            width: "230px",
            height: "auto",
            borderRadius: "5px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Box
          sx={{
            width: "auto",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            // cursor: "pointer",
            padding: "5px",
          }}
        >
          <div>
            <Button
              variant="text"
              startIcon={<UserIcon />}
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "140%",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                padding: "10px 10px",
              }}
              onClick={() => {
                navigate("/profile");
              }}
            >
              <Typography variant="body2" sx={{ textTransform: "none" }}>
                User Profile
              </Typography>
            </Button>
          </div>

          {localStorage.getItem("userLoginData") &&
            JSON.parse(localStorage.getItem("userLoginData") as string).role ===
              "adm" && (
              <div>
                <Button
                  variant="text"
                  sx={{
                    color: "#000",
                    fontFamily: "Open Sans",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "140%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    padding: "10px 10px",
                  }}
                  startIcon={<SettingsIcon />}
                  onClick={() => {
                    navigate("/admin/user-management");
                  }}
                >
                  <Typography variant="body2" sx={{ textTransform: "none" }}>
                    Admin
                  </Typography>
                </Button>
              </div>
            )}
          <Divider
            variant="fullWidth"
            sx={{
              paddingTop: "5px",
            }}
          />
          <div>
            <Button
              variant="text"
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "140%",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                padding: "10px 10px",
                marginTop: "5px",
              }}
              startIcon={<LogoutIcon />}
              onClick={() => {
                // localStorage.getItem("isLoggedIn") &&
                //   localStorage.removeItem("isLoggedIn");
                // localStorage.getItem("userLoginData") &&
                //   localStorage.removeItem("userLoginData");
                // localStorage.getItem("token") &&
                //   localStorage.removeItem("token");
                localStorage.clear();
                navigate("/login", { replace: true });
              }}
            >
              <Typography variant="body2" sx={{ textTransform: "none" }}>
                Log out
              </Typography>
            </Button>
          </div>
        </Box>
      </Popover>
    </Box>
  );
};

export default ProfileBox;
