import React from "react";

const UserFreindlyIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="BorderOuter">
        <path
          id="Vector"
          d="M27.3217 1.60742H2.67885C2.08622 1.60742 1.60742 2.08622 1.60742 2.67885V27.3217C1.60742 27.9143 2.08622 28.3931 2.67885 28.3931H27.3217C27.9143 28.3931 28.3931 27.9143 28.3931 27.3217V2.67885C28.3931 2.08622 27.9143 1.60742 27.3217 1.60742ZM25.9824 25.9824H4.01814V4.01814H25.9824V25.9824ZM14.0628 10.1119H15.9378C16.0851 10.1119 16.2056 9.99135 16.2056 9.84403V7.96903C16.2056 7.82171 16.0851 7.70117 15.9378 7.70117H14.0628C13.9155 7.70117 13.7949 7.82171 13.7949 7.96903V9.84403C13.7949 9.99135 13.9155 10.1119 14.0628 10.1119ZM7.96903 16.2056H9.84403C9.99135 16.2056 10.1119 16.0851 10.1119 15.9378V14.0628C10.1119 13.9155 9.99135 13.7949 9.84403 13.7949H7.96903C7.82171 13.7949 7.70117 13.9155 7.70117 14.0628V15.9378C7.70117 16.0851 7.82171 16.2056 7.96903 16.2056ZM20.1565 16.2056H22.0315C22.1789 16.2056 22.2994 16.0851 22.2994 15.9378V14.0628C22.2994 13.9155 22.1789 13.7949 22.0315 13.7949H20.1565C20.0092 13.7949 19.8887 13.9155 19.8887 14.0628V15.9378C19.8887 16.0851 20.0092 16.2056 20.1565 16.2056ZM14.0628 16.2056H15.9378C16.0851 16.2056 16.2056 16.0851 16.2056 15.9378V14.0628C16.2056 13.9155 16.0851 13.7949 15.9378 13.7949H14.0628C13.9155 13.7949 13.7949 13.9155 13.7949 14.0628V15.9378C13.7949 16.0851 13.9155 16.2056 14.0628 16.2056ZM14.0628 22.2994H15.9378C16.0851 22.2994 16.2056 22.1789 16.2056 22.0315V20.1565C16.2056 20.0092 16.0851 19.8887 15.9378 19.8887H14.0628C13.9155 19.8887 13.7949 20.0092 13.7949 20.1565V22.0315C13.7949 22.1789 13.9155 22.2994 14.0628 22.2994Z"
          fill="black"
          fill-opacity="0.85"
        />
      </g>
    </svg>
  );
};

export default UserFreindlyIcon;
