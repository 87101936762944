import { configureStore } from "@reduxjs/toolkit";
import api from "../../service/api";
import profileSlice from "../../reduxSlice/profile/profileSlice";
import sessionExpireSlice from "../../reduxSlice/sessionExpire/sessionExpireSlice";


const store = configureStore({
    reducer: {
        profile: profileSlice,
        sessionExpire: sessionExpireSlice,
    },
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;