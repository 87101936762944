import {
  FormControlLabel,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Grid, Stack, TextField, Button, Box } from "@mui/material";
import { Send, Restore } from "@mui/icons-material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import CustomButton from "../components/Common/CustomButton";
import YamlDragDrop from "../components/Common/YamlDragDrop";
import { ChangeEvent, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { OutletcontextType } from "../components/Layout/Index";
import {
  addressMatch,
  addressMatchDetail,
} from "../service/apiActions/AddressAction";
import JSONCard from "../components/Common/JSONCard";
import Loader from "../components/Common/Loader/Loader";
import FilePreview from "../components/pages/FaceMatch/FilePreview";
import FeatureFrame from "../components/Common/FeatureFrame";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import DataSecurityCard from "../components/Common/DataSecurityCard";
import Shield from "../Icons/Shield";
import CloudUpload from "../Icons/CloudUpload";
import UserThree from "../Icons/UserThree";

const AddressMatch = () => {
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [category, setCategory] = useState("without");
  const [isLoading, setLoading] = useState(false);
  const [resData, setResData] = useState<any>({});
  const [formdata, setFormdata] = useState<any>({});
  const [filesize, setFilesize] = useState<any>(0);

  const { handleChangeTitle, showSnackbar } =
    useOutletContext<OutletcontextType>();

  useEffect(() => {
    document.title = "AiFise | Address Matching";
    handleChangeTitle(
      "Address Matching",
      "Identifies address components and validates them."
    );
  }, []);

  const handleUpload = () => {
    if (address1.trim() === "" || address2.trim() === "") {
      showSnackbar("Please fill required fields");
      return;
    }

    let _formdata = new FormData();
    _formdata.append("system_address", address1);
    _formdata.append("kyc_address", address2);
    setLoading(true);

    if (formdata.name) {
      _formdata.append("config", formdata);
    }

    let apiCategory = addressMatch;
    if (category === "with") {
      apiCategory = addressMatchDetail;
    }

    apiCategory(_formdata)
      .then((res): any => {
        setLoading(false);
        setResData(res);
      })
      .catch((error) => {
        setLoading(false);
        showSnackbar("Something went wrong");
        console.log("error", error);
      });
  };

  const handleReset = () => {
    setAddress1("");
    setAddress2("");
    setResData({});
    setFormdata({});
    setCategory("without");
  };

  const handleFile = (files: any, e: any) => {
    if (files.length > 0 && files[0].size > 1024 * 1024 * 3) {
      showSnackbar("File size cannot be greater than 3 MB");
      return;
    } else if (files.length > 0) {
      let ext = files[0].name.split(".");
      ext = ext[ext.length - 1];
      console.log(ext);
      if (ext !== "yaml") {
        showSnackbar("Only yaml allowed");
        return;
      }
    }
    setFormdata(files[0]);
    const sizeInKB = (files[0].size / 1024).toFixed(2);
    setFilesize(sizeInKB + " KB");
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div style={{paddingBottom:"200px"}}>
      {Object.keys(resData).length === 0 ? (
        <Grid container justifyContent="center" spacing={3}>
          <Grid item justifyContent="center" lg={8} md={12} sm={6} xs={12}>
            <RadioGroup
              row
              sx={{ justifyContent: "center" }}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="category"
              onChange={({ target: { value } }) => setCategory(value)}
              defaultValue={category}
            >
              <FormControlLabel
                value="without"
                control={<Radio />}
                label="Without Breakdown"
              />
              <FormControlLabel
                value="with"
                control={<Radio />}
                label="With Breakdown"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} lg={8} md={12} sm={8}>
            <Grid container justifyContent={"center"} columnSpacing={2}>
              <Grid item lg={6}>
                <InputLabel sx={{ color: "#343434" }}>Address 1</InputLabel>
                <TextField
                  fullWidth
                  sx={{ mt: 2 }}
                  placeholder="Enter Address 1"
                  variant="outlined"
                  onChange={(e) => setAddress1(e.target.value)}
                />
              </Grid>
              <Grid item lg={6}>
                <InputLabel sx={{ color: "#343434" }}>Address 2</InputLabel>
                <TextField
                  fullWidth
                  sx={{ mt: 2 }}
                  placeholder="Enter Address 2"
                  variant="outlined"
                  onChange={(e) => setAddress2(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={8} sm={6} justifyContent="center">
            {/* <Grid item>
              <label style={{ color: "#343434" }}>
                Dragger<label style={{ color: "#aaa" }}>(optional) :</label>
              </label>
              <br />
              <div>
                {formdata.name ? (
                  <FilePreview
                    sx={{ mt: 1 }}
                    name={formdata?.name}
                    filesize={filesize}
                    handleClose={() => setFormdata({})}
                  />
                ) : (
                  <YamlDragDrop handleChange={handleFile} />
                )}
              </div>
            </Grid>
            <Grid item xs={12} mt={1}>
              <Typography variant="body2" color="text.secondary" align="center">
                <b>Note:</b> File size should be less than 3 MB and only yaml
                are allowed.
              </Typography>
            </Grid> */}
            <Grid item display="flex" justifyContent="flex-end" lg={12} mt={3}>
              <CustomButton
                // startIcon={<FileUploadOutlinedIcon />}
                size="large"
                onClick={handleUpload}
              >
                Match Address
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          rowSpacing={2}
          container
          alignItems={"center"}
          flexDirection="column"
        >
          <Grid
            container
            justifyContent={"space-between"}
            lg={8}
            sx={{ marginTop: 2 }}
          >
            <Grid item>
              <Grid container columnSpacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ bgcolor: "#007AFE" }}
                    onClick={(e: any) => {
                      setResData({});
                    }}
                  >
                    New Address
                  </Button>
                </Grid>

                <Grid item>
                  <CustomButton
                    sx={{ width: 120 }}
                    size="large"
                    variant="outlined"
                    startIcon={<RefreshOutlinedIcon />}
                    onClick={handleReset}
                  >
                    Reset
                  </CustomButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <CustomButton
                sx={{
                  textTransform: "uppercase",
                  backgroundColor: "#38B832",
                  color: "#fff",
                  fontWeight: "fontWeightRegular",
                }}
                size="large"
                variant="outlined"
                onClick={handleReset}
                type="button"
              >
                Api documentation
              </CustomButton>
            </Grid>
          </Grid>

          <Grid container lg={8} pt={2} direction={"column"} rowGap={2}>
            <Grid item lg={4} md={5} sm={6} xs={12}>
              <InputLabel sx={{ fontSize: 14, color: "#282C35" }}>
                Address 1
              </InputLabel>
              <InputLabel sx={{ fontSize: 20, color: "#343434" }}>
                {address1}
              </InputLabel>
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ fontSize: 14, color: "#282C35" }}>
                Address 2
              </InputLabel>
              <InputLabel sx={{ fontSize: 20, color: "#343434" }}>
                {address2}
              </InputLabel>
            </Grid>
            <Grid item lg={4} md={5} sm={6} xs={12}>
              <JSONCard data={resData} />
            </Grid>
          </Grid>
        </Grid>  
      )}
      <FeatureFrame
        frameTitle="How We Achieve Effortless Excellence: Elevating Your User Experience"
        frameDescription="Here's the reason to make the most of our Address Matching"
        icon1={<Shield/>}
        icon2={<CloudUpload /> }
        icon3={<UserThree/>}
        iconDescription1="Instantly verify addresses as you receive them"
        iconDescription2="Standardize and format addresses before they enter your database"
        iconDescription3="Ensure address validation without impacting the customer experience."
        iconTitle1="Validation"
        iconTitle2="Seamless Upload"
        iconTitle3="User Experience"
      />
    </div>
  );
};

export default AddressMatch;