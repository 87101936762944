import React from "react";
import { useNavigate, useOutletContext } from "react-router";
import { useEffect } from "react";
import { useState } from "react";
import { OutletcontextType } from "../components/Layout/Index";
import {
  Grid,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Select,
  MenuItem,
  InputLabel
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { EnquiriesApi } from "../service/apiActions/FaceAction";
// import {
//   TablePagination,
//   tablePaginationClasses as classes,
// } from "@mui/base/TablePagination";
// import { styled } from "@mui/system";
import TablePagination from "@mui/material/TablePagination";

const Enquiries = () => {
  const { handleChangeTitle } = useOutletContext<OutletcontextType>();

  useEffect(() => {
    document.title = "AiFise | Enquiries";
    handleChangeTitle("Enquiries", "AIFISE Product enquiry list.");
  }, []);
  const [enquiries, setEnquiries] = useState<any[]>([]);
  const [searchInput, setSearchInput] = useState(""); // Search Input
  const [page, setPage] = useState(1); // Page number
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page
  const [orderBy, setOrderBy] = useState<string>(""); // Column to be sorted
  const [order, setOrder] = useState<"asc" | "desc">("asc"); // Sorting order
  const [orderByDate, setOrderByDate] = useState<string>("");
  

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  
  const currentDate: Date = new Date();
  const currentYear: number = currentDate.getFullYear();
  const currentMonth: number = currentDate.getMonth() + 1;
  
  
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  
  useEffect(() => {
    if (selectedYear !== null && selectedMonth !== null) {
      const body = {
        yyyymm: `${selectedYear}${selectedMonth < 10 ? '0' : ''}${selectedMonth}`,
      };
      EnquiriesApi(body, { headers: { authorization: "Bearer token" } })
        .then((res: any) => {
          setEnquiries(res.enquiries);
          localStorage.setItem("enquiries", JSON.stringify(res.enquiries));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedYear, selectedMonth]);

  const navigate = useNavigate();

  const handleEnquiryClick = (id: any) => {
    navigate(`enquiry-details/${id}`);
  };

  // Filtering the enquiries based on searchInput
  const filteredEnquiries = enquiries.filter(
    (item) =>
      item.name.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.email.toLowerCase().includes(searchInput.toLowerCase())
  );

  // Handlers for changing the page and rowsPerPage
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage + 1); // Adjusting the page number
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    // Handling sorting by "Schedule Date"
    if (property === "schedule") {
      const isAscDate = orderByDate === property && order === "asc";
      setOrder(isAscDate ? "desc" : "asc");
      setOrderByDate(property);
    }
  };

  return (
    <div>
      <Grid container spacing={6}>
        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
          <TextField
            size="small"
            variant="outlined"
            style={{ width: 264 }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ cursor: "pointer", color: "gray" }}
                >
                  <SearchIcon color="action" sx={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
            placeholder="Search Enquiry List"
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <div>
          
          <Select
            size="small"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value as number)}
            placeholder="Select Month"
            style={{width: 120, marginRight: 10}}
           
          >
            <MenuItem disabled>
             Select Month
            </MenuItem>
            {monthNames.map((month, index) => (
              <MenuItem key={index + 1} value={index + 1}>{month}</MenuItem>
            ))}
          </Select>
          
          <Select
            size="small"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value as number)}
            placeholder="Select Year"
            style={{width: 120, marginLeft: 10}}
          >
            <MenuItem  disabled>
              Select Year
            </MenuItem>
            {[...Array(10)].map((_, index) => (
              <MenuItem key={currentYear - index} value={currentYear - index}>{currentYear - index}</MenuItem>
            ))}
          </Select>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={6}>
        <Grid item xs={12}>
          <Paper>
            <TableContainer sx={{ marginTop: "16px", marginBottom: "20px" }}>
              <Table>
                <TableHead
                  sx={{
                    background: "#FAFAFA",
                    borderBottom: "1px solid  rgba(0, 0, 0, 0.06)",
                  }}
                >
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "name"}
                        direction={orderBy === "name" ? order : "asc"}
                        onClick={() => handleSort("name")}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Designation</TableCell>
                    <TableCell>Email ID</TableCell>
                    <TableCell>Contact Number</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderByDate === "schedule"}
                        direction={orderByDate === "schedule" ? order : "asc"}
                        onClick={() => handleSort("schedule")}
                      >
                        Schedule Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Product</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredEnquiries
                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                    .sort((a, b) => {
                      if (order === "asc") {
                        return a[orderBy]?.localeCompare(b[orderBy]);
                      } else {
                        return b[orderBy]?.localeCompare(a[orderBy]);
                      }
                    })
                    ?.map((item: any) => (
                      <TableRow key={item.id}>
                        <TableCell
                          onClick={() => handleEnquiryClick(item.id)}
                          style={{
                            cursor: "pointer",
                            color: "blue",
                            textDecoration: "underline",
                          }}
                        >
                          {item.name}
                        </TableCell>
                        <TableCell>{item.company}</TableCell>
                        <TableCell>{item.designation}</TableCell>
                        <TableCell>{item.email}</TableCell>
                        <TableCell>{item.contactNumber}</TableCell>
                        <TableCell>{item.schedule}</TableCell>
                        <TableCell>{item.product}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <CustomTablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              count={filteredEnquiries.length}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingRight: "5px",
              }}
              // slotProps={{
              //   select: {
              //     'aria-label': 'rows per page',
              //   },
              //   actions: {
              //     showFirstButton: true,
              //     showLastButton: true,
              //   },
              // }}
            /> */}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={filteredEnquiries.length}
              page={page - 1}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <style>
        {`.css-1yazjtg-MuiInputBase-root-MuiFilledInput-root{
     background-color:white
  }`}
        {`
    @media (min-width: 1536px) {
      .css-vi59no {
       width: 100%; 

      margin-bottom: 30px;
      }
    }

  `}
      </style>
    </div>
  );
};

// const CustomTablePagination = styled(TablePagination)`
//   & .${classes.toolbar} {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     gap: 10px;

//     @media (min-width: 768px) {
//       flex-direction: row;
//       align-items: center;
//     }
//   }

//   & .${classes.selectLabel} {
//     margin: 0;
//   }

//   & .${classes.displayedRows} {
//     margin: 0;

//     @media (min-width: 768px) {
//       margin-left: auto;
//     }
//   }

//   & .${classes.spacer} {
//     display: none;
//   }

//   & .${classes.actions} {
//     display: flex;
//     gap: 0.25rem;
//   }
// `;
export default Enquiries;
