import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseCircle from "../../../Icons/CloseCircle";
import { Divider } from "@mui/material";
const WrongPasswordModal = (prop: any) => {
  const [open, setOpen] = React.useState(prop.open);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={(event: any, reason: any) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "500px",
          borderRadius: "0px",
          padding: "0px",
          margin: "0px",
          maxHeight: "500px",
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          display: "flex",
          alignItems: "center",
          paddingX: "24px",
          fontSize: "16px",
          fontWeight: 600,
          color: "rgba(0, 0, 0, 0.85)",
        }}
      >
        <CloseCircle />
        <div style={{ marginLeft: "16px" }}> {"Incorrect Password"}</div>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            color: "rgba(0, 0, 0, 0.85)",
            fontSize: "14px",
            fontWeight: 400,
            fontStyle: "normal",
            marginLeft: "36px",
          }}
        >
          The password you entered is incorrect. Please try again!
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
          sx={{
            borderRadius: "2px",
            border: "1px solid #d9d9d9",
            // color: "rgba(0, 0, 0, 0.85)",
            fontWeight: 400,
            fontSize: "14px",
            padding: "4px 24px",
            textTransform: "none",
            boxShadow: "none",
            ":hover": {
              boxShadow: "none",
            },
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WrongPasswordModal;
