import api from '../api'
import { faceApi, loginApi, allProducts, signupApi, enquiriesApi, settingsApi ,UserPermission ,adminUser,UserProduct, selfieMatchApi,adminSearch, NSFW, SpamClassificationApi } from '../apiVariables'

export const faceMatch = (body: any) => {
    return new Promise(async (resolve, reject) => {
        await api({ ...faceApi.faceMatch, body }).then((res: any) => {
            resolve(res)
        }).catch((error) => {
            reject(error);
        })
    })
}

export const faceAnalyseApi = (body: any) => {
    return new Promise(async (resolve, reject) => {
        await api({ ...faceApi.faceAnalyse, body }).then((res: any) => {
            resolve(res)
        }).catch((error) => {
            reject(error);
        })
    })
}

export const LoginApi = (body: any) => {
    return new Promise(async (resolve, reject) => {
        await api({ ...loginApi.login, body, isAuthentic: false }).then((res: any) => {
            resolve(res)
        }).catch((error) => {
            reject(error);
        })
    })
}

export const SignupApi = (body: any) => {
    return new Promise(async (resolve, reject) => {
        await api({ ...signupApi.login, body, isAuthentic: false}).then((res: any) => {
            resolve(res)
        }).catch((error) => {
            reject(error);
        })
    })
}

export const EnquiriesApi = (body: any, headers: any) => {
    return new Promise(async (resolve, reject) => {
        await api({ ...enquiriesApi.enquiries, body, extraParams: headers}).then((res: any) => {
            resolve(res)
        }).catch((error) => {
            reject(error);
        })
    })
}




export const alladminUserApi = () => {
    return new Promise(async (resolve, reject) => {
        await api({ ...adminUser.user}).then((res: any) => {
            resolve(res)
        }).catch((error) => {
            reject(error);
        })
    })
}

export const alladminUserSearch = (queary :string) => {

    const { search } =  adminSearch
    search.param = queary

    return new Promise(async (resolve, reject) => {
        await api({ ...adminSearch.search }).then((res: any) => {
            resolve(res)
        }).catch((error) => {
            reject(error);
        })
    })
}


export const allProductsApi = () => {
    return new Promise(async (resolve, reject) => {
        await api({ ...allProducts.products}).then((res: any) => {
            resolve(res)
        }).catch((error) => {
            reject(error);
        })
    })
}


export const userSettingUpdateApi = (body: any) => {
    return new Promise(async (resolve, reject) => {
        await api({ ...settingsApi.settingsUpdate, body }).then((res: any) => {
            resolve(res)
        }).catch((error) => {
            reject(error);
        })
    })
}

export const userSettingApi = () => {
    return new Promise(async (resolve, reject) => {
        await api({ ...settingsApi.settings}).then((res: any) => {
            resolve(res)
        }).catch((error) => {
            reject(error);
        })
    })
}


export const allUserProduct = (body:any) => {
    return new Promise(async (resolve, reject) => {
        await api({ ...UserProduct.userdetail, body }).then((res: any) => {
            resolve(res)
        }).catch((error) => {
            reject(error);
        })
    })
}

export const selfieMatchingApi = (body:any) => {
    return new Promise(async (resolve, reject) => {
        await api({ ...selfieMatchApi.selfie, body }).then((res: any) => {
            resolve(res)
        }).catch((error) => {
            reject(error);
        })
    })
}

export const selfieMatchDeleteApi = (id:any) => {
    return new Promise(async (resolve, reject) => {
        await api({ api: `/fs/user/${id}`, method: 'delete', baseurl:'selfie' }).then((res: any) => {
            resolve(res)
        }).catch((error) => {
            reject(error);
        })
    })
}

export const allUserPermission = (body:any) => {
    return new Promise(async (resolve, reject) => {
        await api({ ...UserPermission.userpermission, body }).then((res: any) => {
            resolve(res)
        }).catch((error) => {
            reject(error);
        })
    })
}

export const nsfwApiAction = (body:any) =>{
    return new Promise(async (resolve, reject) => {
        await api({ ...NSFW.nsfwApi, body }).then((res: any) => {
            resolve(res)
        }).catch((error) => {
            reject(error);
        })
    })
}
export const spamClassification = (body:any) => {
    return new Promise(async(resolve,reject)=>{
        await api({...SpamClassificationApi.spamclassification, body}).then((res:any)=>{
            resolve(res)
        }).catch((error)=>{
            reject(error)
        })
    })
}
export const nsfwDeleteApi = (id:any) => {
    return new Promise(async (resolve, reject) => {
        await api({ api: `/nsfw/user/${id}`, method: 'delete', baseurl:'selfie' }).then((res: any) => {
            resolve(res)
        }).catch((error) => {
            reject(error);
        })
    })
}

export const getUserProfileApi = () => {
    return new Promise(async (resolve, reject) => {
        await api({ api:'/user/profile', method:'get', baseurl:'baseurl2', isBearer: true }).then((res: any) => {
            resolve(res)
        }).catch((error) => {
            reject(error);
        })
    })
}

export const updateProfileApi = (body:any) => {
    return new Promise(async (resolve, reject) => {
        await api({ api:'/user/profile', body:body, method:'post', baseurl:'baseurl2', isBearer: true }).then((res: any) => {
            resolve(res)
        }).catch((error) => {
            reject(error);
        })
    })
}

export const updateUserPassowrdApi = (body:any) => {
    return new Promise(async (resolve, reject) => {
        await api({ api:'/user/change/password', body:body, method:'post', baseurl:'baseurl2', isBearer: true }).then((res: any) => {
            resolve(res)
        }).catch((error) => {
            reject(error);
        })
    })
}