import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Loader from "../components/Common/Loader/Loader";
import { OutletcontextType } from "../components/Layout/Index";
import FeatureFrame from "../components/Common/FeatureFrame";
import LiveIcon from "../Icons/LiveIcon";
import VideoCameraIcon from "../Icons/VideoCameraIcon";
import MapIcon from "../Icons/MapIcon";
import CustomButton from "../components/Common/CustomButton";
import ArrowLeftIcon from "../Icons/ArrowLeftIcon";
import Onboarding1 from "../Icons/Onboarding1";
import Onboarding2 from "../Icons/Onboarding2";
import Onboarding3 from "../Icons/Onboarding3";

const Onboarding = () => {
  const { handleChangeTitle } = useOutletContext<OutletcontextType>();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "AiFise | Onboarding Process";
    handleChangeTitle(
      "Onboarding Process",
      "Make Customer Onboarding a Comfortable Experience in Their Homes."
    );
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Grid container justifyContent="center" pt={3}>
        <Grid item lg={10} md={6} sm={6} xs={12}>
          <Typography
                   noWrap
                  component="div"
                  color="rgba(0, 0, 0, 0.70)"
                  fontSize="20px"
                  fontWeight={600}
                  lineHeight="24px"
                  textAlign="center"
          >
            Deliver unparalleled trust and convenience when customers sign up
            for your services
          </Typography>
        </Grid>
      </Grid>

      <div style={{ paddingTop: "24px" }}>
        <CustomButton
          onClick={() => {
            window.open("https://main.d38izpmlj2uww3.amplifyapp.com");
          }}
          sx={{
            display: "flex",
            padding: "10px 10px 10px 26px",
            alignItems: "center",
            gap: "16px",
            borderRadius: "200px",
            border: "1px solid #0D93F0",
            background: "#0D93F0",
            margin: "auto",
          }}
        >
          <div
            style={{
              color: "#FFF",

              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "350",
              lineHeight: "28px",
            }}
          >
            See it in Action
          </div>
          <div
            style={{
              display: "flex",
              padding: "10px",
              alignItems: "center",
              gap: "10px",
              borderRadius: "200px",
              background: "#FFFF",
            }}
          >
            <ArrowLeftIcon />
          </div>
        </CustomButton>
      </div>

      <FeatureFrame
        frameTitle="Elevate Enterprise Efficiency with AI-Driven Onboarding Solutions"
        frameDescription="Explore the AIFISE Advantage in Onboarding AI Excellence!"
        icon1={<Onboarding1 />}
        icon2={<Onboarding2 />}
        icon3={<Onboarding3/>}
        iconDescription1="Extend Reach with Remote Onboarding, Instant Verifications, No Footwork Needed."
        iconDescription2="Effortless Integration with Ready-to-Use APIs, Minimizing Development Time."
        iconDescription3="Robust, Scalable Platform for Enterprise-level Operations"
        iconTitle1="Expand Network"
        iconTitle2="Pre-integrated APIs"
        iconTitle3="Enterprise Platform"
      />
      <style>{`.css-3ef1jy-MuiGrid-root{  padding-top: 0px; }`}</style>
    </>
  );
};

export default Onboarding;
