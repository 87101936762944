import api from "../api";
import { addressApi, violencDetectioneApi } from "../apiVariables";

export const addressMatch = (body: any) => {
    return new Promise((resolve, reject) => {
        api({ ...addressApi.addressMatch, body })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const addressMatchDetail = (body: any) => {
    return new Promise((resolve, reject) => {
        api({ ...addressApi.addressMatchDetail, body })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

// violence detection
export const violenceDetectionDetail = (body: any) => {
    return new Promise((resolve, reject) => {
        api({ ...violencDetectioneApi.violencDetectione, body })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const violenceDetectionVideo = (body: any) => {
    return new Promise((resolve, reject) => {
        api({ ...violencDetectioneApi.violencDetectioneVideo, body })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
