import React from 'react'

const CloseCircle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
  <g clip-path="url(#clip0_3104_4059)">
    <path d="M15.2574 7.64174C15.2574 7.53371 15.169 7.44531 15.0609 7.44531L13.4404 7.45268L10.9998 10.3623L8.56161 7.45513L6.93862 7.44777C6.83058 7.44777 6.74219 7.53371 6.74219 7.6442C6.74219 7.69085 6.75937 7.73505 6.78884 7.77188L9.98326 11.5777L6.78884 15.381C6.75917 15.417 6.74271 15.4621 6.74219 15.5087C6.74219 15.6167 6.83058 15.7051 6.93862 15.7051L8.56161 15.6978L10.9998 12.7882L13.4379 15.6953L15.0585 15.7027C15.1665 15.7027 15.2549 15.6167 15.2549 15.5062C15.2549 15.4596 15.2377 15.4154 15.2083 15.3786L12.0187 11.5752L15.2132 7.76942C15.2426 7.73504 15.2574 7.68839 15.2574 7.64174Z" fill="#FF4D4F"/>
    <path d="M11 0.523438C4.92545 0.523438 0 5.44888 0 11.5234C0 17.598 4.92545 22.5234 11 22.5234C17.0746 22.5234 22 17.598 22 11.5234C22 5.44888 17.0746 0.523438 11 0.523438ZM11 20.6574C5.9567 20.6574 1.86607 16.5667 1.86607 11.5234C1.86607 6.48013 5.9567 2.38951 11 2.38951C16.0433 2.38951 20.1339 6.48013 20.1339 11.5234C20.1339 16.5667 16.0433 20.6574 11 20.6574Z" fill="#FF4D4F"/>
  </g>
  <defs>
    <clipPath id="clip0_3104_4059">
      <rect width="22" height="22" fill="white" transform="translate(0 0.5)"/>
    </clipPath>
  </defs>
</svg>
  )
}

export default CloseCircle
