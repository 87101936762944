import React from "react";

const ArrowLeftIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ArrowLeft">
        <path
          id="Vector"
          d="M2.35672 13.0173L18.029 13.0173L8.64868 21.1602C8.49868 21.2914 8.58975 21.5352 8.78797 21.5352L11.1585 21.5352C11.263 21.5352 11.3621 21.4977 11.4398 21.4307L21.5621 12.6477C21.6548 12.5673 21.7291 12.4679 21.7801 12.3563C21.8311 12.2447 21.8574 12.1235 21.8574 12.0008C21.8574 11.8781 21.8311 11.7568 21.7801 11.6452C21.7291 11.5336 21.6548 11.4343 21.5621 11.3539L11.3808 2.5173C11.3406 2.48247 11.2924 2.46373 11.2415 2.46373L8.79065 2.46372C8.59243 2.46372 8.50136 2.71015 8.65136 2.83872L18.029 10.9816L2.35672 10.9816C2.23886 10.9816 2.14243 11.078 2.14243 11.1959L2.14243 12.803C2.14243 12.9209 2.23886 13.0173 2.35672 13.0173Z"
          fill="black"
          fill-opacity="0.85"
        />
      </g>
    </svg>
  );
};

export default ArrowLeftIcon;
