import React from "react";

const FieldTimeIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="FieldTime">
        <g id="Vector">
          <path
            d="M29.4964 11.6536H20.9249C20.7776 11.6536 20.6571 11.7742 20.6571 11.9215V13.5286C20.6571 13.676 20.7776 13.7965 20.9249 13.7965H29.4964C29.6437 13.7965 29.7642 13.676 29.7642 13.5286V11.9215C29.7642 11.7742 29.6437 11.6536 29.4964 11.6536ZM25.0098 16.2072H20.9249C20.7776 16.2072 20.6571 16.3277 20.6571 16.4751V18.0822C20.6571 18.2295 20.7776 18.3501 20.9249 18.3501H25.0098C25.1571 18.3501 25.2776 18.2295 25.2776 18.0822V16.4751C25.2776 16.3277 25.1571 16.2072 25.0098 16.2072ZM13.8368 8.65699H12.387C12.1794 8.65699 12.012 8.8244 12.012 9.03199V17.3356C12.012 17.4561 12.0689 17.5666 12.166 17.6369L17.1515 21.2731C17.3189 21.3936 17.5533 21.3601 17.6738 21.1927L18.5343 20.0175V20.0141C18.6549 19.8467 18.618 19.6123 18.4506 19.4918L14.2084 16.4248V9.03199C14.2118 8.8244 14.041 8.65699 13.8368 8.65699Z"
            fill="black"
            fill-opacity="0.85"
          />
          <path
            d="M24.8033 20.4142H22.868C22.6805 20.4142 22.5031 20.5113 22.4026 20.6721C21.9768 21.3471 21.4771 21.9726 20.9127 22.537C19.941 23.5119 18.789 24.2886 17.521 24.8238C16.2051 25.3796 14.8089 25.6609 13.3692 25.6609C11.9261 25.6609 10.5299 25.3796 9.21738 24.8238C7.9484 24.2881 6.80666 23.518 5.82564 22.537C4.84461 21.556 4.07452 20.4142 3.53881 19.1453C2.983 17.8328 2.70175 16.4366 2.70175 14.9935C2.70175 13.5504 2.983 12.1576 3.53881 10.8417C4.07452 9.57273 4.84461 8.43099 5.82564 7.44996C6.80666 6.46894 7.9484 5.69884 9.21738 5.16313C10.5299 4.60733 11.9294 4.32608 13.3692 4.32608C14.8122 4.32608 16.2085 4.60733 17.521 5.16313C18.7899 5.69884 19.9317 6.46894 20.9127 7.44996C21.4771 8.01436 21.9768 8.63987 22.4026 9.31492C22.5031 9.47563 22.6805 9.57273 22.868 9.57273H24.8033C25.0343 9.57273 25.1817 9.33166 25.0779 9.12742C22.8948 4.78478 18.4685 1.96224 13.5265 1.90532C6.29104 1.81492 0.280994 7.73791 0.267601 14.9667C0.254208 22.2089 6.12363 28.085 13.3658 28.085C18.3714 28.085 22.8714 25.2524 25.0779 20.8596C25.1014 20.8127 25.1126 20.7606 25.1103 20.7082C25.108 20.6559 25.0924 20.6049 25.0648 20.5603C25.0373 20.5157 24.9988 20.4788 24.9531 20.4533C24.9073 20.4277 24.8557 20.4143 24.8033 20.4142Z"
            fill="black"
            fill-opacity="0.85"
          />
        </g>
      </g>
    </svg>
  );
};

export default FieldTimeIcon;
