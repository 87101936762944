import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, Modal } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import Tooltop from "../components/Common/Tooltop";
import { useNavigate } from "react-router";
import {
  allProductsApi,
  userSettingUpdateApi,
} from "../service/apiActions/FaceAction";
import { VariantType, useSnackbar } from "notistack";
import CustomButton from "../components/Common/CustomButton";
import CloseIcon from "@mui/icons-material/Close";
import LogoIcon from "../Icons/LogoIcon";
const APIControls = () => {
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [products, setProducts] = useState<{ name: string; id: string }[]>([]);
  const [name, setName] = useState("");

  const [buttonColors, setButtonColors] = useState<Record<string, string>>({});
  const [selectedBtnTxtColor, setSelectedBtnTxtColor] = useState<Record<string, string>>({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  const showSnackbar = (message: string, type: VariantType = "error") => {
    enqueueSnackbar(message, {
      variant: type,
      action: (key) => <CloseIcon onClick={() => closeSnackbar(key)} />,
    });
  };

  useEffect(() => {
    const name = localStorage.getItem("name")
      ? JSON.parse(localStorage.getItem("name") as string)
      : null;
    if (name) {
      setName(name);
    }
    allProductsApi()
      .then((res: any) => {
        const structuredProducts = res.map((item: any) => ({
          name: item.name,
          id: item.id,
        }));
        setProducts(structuredProducts);
        // })
      })
      .catch((err) => {
        console.log("res allProductsApi err ", err);
      });
  }, []);

  const handleClick = (product: any) => {
    setSelectedProducts((prevSelectedProducts) => {
      const index = prevSelectedProducts.indexOf(product.id);
      if (index === -1) {
        // If not already selected, adding to the array
        return [...prevSelectedProducts, product.id];
      } else {
        // If already selected, removing from the array
        const newSelectedProducts = [...prevSelectedProducts];
        newSelectedProducts.splice(index, 1);
        return newSelectedProducts;
      }
    });

    // Toggling the background color
    setButtonColors((prevButtonColors) => ({
      ...prevButtonColors,
      [product.id]: prevButtonColors[product.id] === "#007AFE" ? "" : "#007AFE",
    }));

    // Toggling the text color
    setSelectedBtnTxtColor((prevSelectedBtnTxtColor) => ({
      ...prevSelectedBtnTxtColor,
      [product.id]: prevSelectedBtnTxtColor[product.id] === "#FFF" ? "" : "#FFF",
    }));
  };

  const handleProceed = () => {
    if (selectedProducts.length === 0) {
      showSnackbar("Please select atleast one product");
      return;
    }
    console.log("Proceeding to next page");
    console.log(selectedProducts, "selectedProducts");
    // navigate("/");
    const stringBody = selectedProducts.toString();
    console.log(stringBody, "body");
    const token = localStorage.getItem("token");
    const body = {
      selected_products: stringBody,
    };

    userSettingUpdateApi(body)
      .then((res: any) => {
        // navigate("/");
        console.log("res userSettingsApi ", res);

        if (res.status === "success") {
          setOpen(true);
        } else {
          showSnackbar(res.message);
        }
      })
      .catch((err) => {
        console.log("res userSettingsApi err ", err);
        showSnackbar(err.message);
      });
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          paddingLeft: "20px",
          marginLeft: { lg: "180px", md: "80px", sm: "0px", xs: "0px" },
        }}
      >
        <div
          style={{
            paddingRight: "24px",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <Tooltop title={name} textColor="#000000" />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "115px",
            maxHeight: "45px",
            paddingLeft: "90px",
            paddingBottom: "30px",
            marginBottom: "30px",
          }}
        >
          {/* <img src="newlog.png" alt="logo" /> */}
          <LogoIcon />
        </div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              color: "rgba(0, 0, 0, 0.85)",
              // fontFamily: "Avenir LT Std",
              fontSize: "48px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "67.2px",
            }}
          >
            Hi {name ? name : "User Name"},
          </Typography>
          <Typography
            variant="h3"
            sx={{
              color: " rgba(0, 0, 0, 0.60)",
              // fontFamily: "Avenir LT Std",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "28.8px",
            }}
          >
            Welcome to AIFISE Console.
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            marginTop: "50px",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: "rgba(0, 0, 0, 0.85)",
              // fontFamily: "Avenir LT Std,",
              fontSize: "28px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "33.6px",
            }}
          >
            Select the features you want to use.
          </Typography>
          <Grid
            container
            spacing={3}
            // columnSpacing={3}
            direction="column"
            mt={"0.2rem"}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
              maxWidth: { lg: "653px", md: "553px", sm: "100%", xs: "100%" },
              // width:"max-content"
            }}
          >
            {products.map((product) => (
              <Grid item key={product.id}>
                <button
                  // variant="contained"
                  className={`product-button ${selectedProducts.includes(product.id)? "selected" : ""}`} 
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #D1D1D1",
                    background: buttonColors[product.id] || "#FFF",
                    color: "#333333",
                    textAlign: "center",
                    // fontfamily: "Nunito Sans",
                    // fontsize: "17px",
                    // fontstyle: "normal",
                    // fontweight: 400,
                    // lineheight: "23.8px",
                    width: "max-content",
                    padding: "8px 12px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleClick(product)}
                >
                  <div style={{color:selectedBtnTxtColor[product.id] || "black"}}>{product.name}</div>
                </button>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            marginTop: "50px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            endIcon={<ArrowForward />}
            sx={{
              borderRadius: "2px",
              border: "1px solid #007AFE",
              background: "#007AFE",
              boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)",
              maxWidth: "150px",
              marginBottom: "100px",
              height: "40px",
              width: "137px",
            }}
            onClick={handleProceed}
          >
            <Typography
              variant="body2"
              sx={{
                color: "#FFFFFF",
                // fontFamily: "Avenir LT Std",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "140%",
                textTransform: "none",
              }}
            >
              Submit
            </Typography>
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2" sx={{
                lineHeight:"140%"
              }}>
                Submitted Successfully
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 1, lineHeight:"140%" }}>
                Thanks for your interest, our team will connect with you
              </Typography>
              <CustomButton
                onClick={() => {
                  navigate("/login");
                  localStorage.clear();
                }}
                sx={{
                  marginTop: "24px",
                  borderRadius: "2px",
                  border: "1px solid #007AFE",
                  background: "#007AFE",
                  boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)",
                  maxWidth: "150px",
                  display: "block",
                  marginLeft: "auto",
                }}
              >
                OK
              </CustomButton>
            </Box>
          </Modal>
        </Box>
      </Box>
    </>
  );
};

export default APIControls;
