import React, { useState } from "react";
import {
  Button,
  Card,
  Divider,
  Flex,
  Icon,
  Image,
  Loader,
  Text,
} from "@aws-amplify/ui-react";
import { StorageManager } from "@aws-amplify/ui-react-storage";

import { Amplify } from "aws-amplify";
import config from "../../../aws-exports";
import { useLocation } from "react-router-dom";
import { log } from "console";

Amplify.configure({ ...config });
console.log(config, "------amplify config");

type StorageManagerProps = {
  studio_id?: string;
  event_id?: string;
  onUploadSuccess: () => void;
  onUploadError: () => void;
};

export const StorageManagercomp = ({
  studio_id,
  event_id,
  onUploadSuccess,
  onUploadError,
}: StorageManagerProps) => {
  const pathname = useLocation();
  console.log(pathname, "Pathname");
  const endPoint = pathname.pathname.split("/");
  let lastElement = endPoint[endPoint.length - 1];
  console.log(lastElement, "lastElement");
  console.log(endPoint, "endPoint");
  let sub_event_id = "";
  if (typeof window !== "undefined") {
    sub_event_id = localStorage.getItem("subevent") || "";
  }
  console.log("sub_event_id", sub_event_id, studio_id, event_id);
  const userEmail = localStorage.getItem("userLoginData")
    ? JSON.parse(localStorage.getItem("userLoginData") as string)
    : null;
  console.log("userEmail", userEmail.email);

  return (
    <StorageManager
      acceptedFileTypes={
        lastElement === "violence-detection"
          ? ["video/mp4"]
          : ["application/zip"]
      }
      accessLevel="public"
      maxFileCount={100}
      onUploadSuccess={(data) => {
        console.log("onUploadSuccess", data);
        // Calling the callback function passed from ViolenceDetection and Facesearch
        onUploadSuccess();
      }}
      onUploadError={(data) => {
        console.log("onUploadError", data);
        // Calling the callback function passed from ViolenceDetection and Facesearch
        onUploadError();
      }}
      processFile={({ file, key }) => {
        console.log(
          "key",
          // `S3-STU_${studio_id}-EVT_${event_id}/S3-SUB_${sub_event_id}/${key}`
          file,
          key,
          `${userEmail.email}${lastElement === "nsfw" && "videos"}/${key}`
        );
        return {
          key: `${lastElement === "violence-detection" ? "nsfw-video/" : ""}${
            userEmail.email
          }/${key}`,
          file,
        };
      }}
      // components={{
      //   Container({ children }) {
      //     return (
      //       <Card
      //         placeholder={""}
      //         variation="elevated"
      //         draggable
      //         onDragEnter={() => setBorderEnable(true)}
      //         onDragLeave={() => setBorderEnable(false)}
      //         style={{
      //           width: "100%",
      //           border: `1px dashed ${isBorderEnable ? "#007AFE" : "#9E9E9E"}`,
      //           borderColor: isBorderEnable ? "#007AFE" : "#9E9E9E",
      //           background: "#F5F5F5",
      //           paddingTop: 2,
      //           display: "flex",
      //           flexDirection: "column",
      //           // Add hover styles separately
      //           ...(isBorderEnable && {
      //             "&:hover": {
      //               border: "1px dashed #007AFE",
      //             },
      //           }),
      //         }}
      //       >
      //         {children}
      //       </Card>
      //     );
      //   },
      //   DropZone({ children, displayText, inDropZone, onDrop, ...rest }) {
      //     return (
      //       <Flex
      //         placeholder={""}
      //         alignItems="center"
      //         direction="column"
      //         padding="medium"
      //         width="100%"
      //         // backgroundColor="#f7f9fc"
      //         backgroundColor={"transparent"}
      //         borderRadius="small"
      //         {...rest}
      //       >
      //         <Text placeholder={""} className="my-4">
      //           Drop files here
      //         </Text>
      //         <Divider
      //           placeholder={""}
      //           size="small"
      //           label="or"
      //           maxWidth="10rem"
      //           style={{ color: "#000" }}
      //           className="bg-transparent text-black"
      //         />
      //         {children}
      //       </Flex>
      //     );
      //   },
      //   FilePicker({ onClick }) {
      //     return (
      //       <Button placeholder={""} className="my-4" onClick={onClick}>
      //         Browse Files
      //       </Button>
      //     );
      //   },
      //   FileList({ files, onCancelUpload, onDeleteUpload }) {
      //     return (
      //       <Flex placeholder={""} direction="row" width={"100%"}>
      //         {files.map(({ file, key, progress, id, status, uploadTask }) => (
      //           <Flex
      //             key={key}
      //             placeholder={""}
      //             justifyContent="center"
      //             alignItems="center"
      //             // width="5rem"
      //             // height="5rem"
      //             position="relative"
      //             // flexWrap="wrap"
      //           >
      //             {file && (
      //               <Image
      //                 placeholder={""}
      //                 borderRadius="small"
      //                 height="100%"
      //                 objectFit="cover"
      //                 src={URL.createObjectURL(file)}
      //                 alt={key}
      //               />
      //             )}
      //             {progress < 100 ? (
      //               <Loader
      //                 // position="absolute"
      //                 size="large"
      //                 percentage={progress}
      //                 isDeterminate
      //                 isPercentageTextHidden={false}
      //               />
      //             ) : null}

      //             <Button
      //               placeholder={""}
      //               // opacity="50"
      //               borderRadius="xxl"
      //               backgroundColor="transparent"
      //               // backgroundColor="background.primary"
      //               // position="absolute"
      //               variation="link"
      //               size="small"
      //               onClick={() => {
      //                 if (status === "uploading" && uploadTask) {
      //                   onCancelUpload({ id, uploadTask });
      //                 } else {
      //                   onDeleteUpload({ id });
      //                 }
      //               }}
      //             >
      //               <Icon
      //                 fontSize="large"
      //                 color="font.error"
      //                 viewBox={{ width: 512, height: 512 }}
      //                 paths={[
      //                   {
      //                     d: "M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z",
      //                     strokeWidth: "32",
      //                     fill: "none",
      //                     strokeMiterlimit: "10",
      //                     stroke: "currentColor",
      //                   },
      //                   {
      //                     d: "M320 320L192 192m0 128l128-128",
      //                     strokeWidth: "32",
      //                     fill: "none",
      //                     strokeLinecap: "round",
      //                     stroke: "currentColor",
      //                   },
      //                 ]}
      //               />
      //             </Button>
      //           </Flex>
      //         ))}
      //       </Flex>
      //     );
      //   },
      // }}
    />
  );
};
