import React from 'react'

const SaveChanges = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_3629_11086)">
        <path
          d="M14.8073 4.09688L11.9037 1.1933C11.7698 1.05937 11.6055 0.961161 11.4269 0.907589V0.859375H1.4269C1.11083 0.859375 0.855469 1.11473 0.855469 1.4308V14.5737C0.855469 14.8897 1.11083 15.1451 1.4269 15.1451H14.5698C14.8858 15.1451 15.1412 14.8897 15.1412 14.5737V4.90402C15.1412 4.60045 15.0215 4.31116 14.8073 4.09688ZM5.71261 2.14509H10.284V4.00223H5.71261V2.14509ZM13.8555 13.8594H2.14118V2.14509H4.56976V4.57366C4.56976 4.88973 4.82511 5.14509 5.14118 5.14509H10.8555C11.1715 5.14509 11.4269 4.88973 11.4269 4.57366V2.53438L13.8555 4.96295V13.8594ZM7.99833 6.75223C6.57868 6.75223 5.4269 7.90402 5.4269 9.32366C5.4269 10.7433 6.57868 11.8951 7.99833 11.8951C9.41797 11.8951 10.5698 10.7433 10.5698 9.32366C10.5698 7.90402 9.41797 6.75223 7.99833 6.75223ZM7.99833 10.7522C7.20904 10.7522 6.56976 10.1129 6.56976 9.32366C6.56976 8.53438 7.20904 7.89509 7.99833 7.89509C8.78761 7.89509 9.4269 8.53438 9.4269 9.32366C9.4269 10.1129 8.78761 10.7522 7.99833 10.7522Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3629_11086">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SaveChanges
