import React from "react";

const BandwidthIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Apartment" clip-path="url(#clip0_1961_16631)">
        <path
          id="Vector"
          d="M28.2587 19.2857H24.7765V14.1964C24.7765 14.0491 24.656 13.9286 24.5087 13.9286H16.2051V10.7143H19.8212C20.1158 10.7143 20.3569 10.4732 20.3569 10.1786V0.535714C20.3569 0.241071 20.1158 0 19.8212 0H10.1783C9.88365 0 9.64258 0.241071 9.64258 0.535714V10.1786C9.64258 10.4732 9.88365 10.7143 10.1783 10.7143H13.7944V13.9286H5.49079C5.34347 13.9286 5.22294 14.0491 5.22294 14.1964V19.2857H1.74079C1.44615 19.2857 1.20508 19.5268 1.20508 19.8214V29.4643C1.20508 29.7589 1.44615 30 1.74079 30H11.3837C11.6783 30 11.9194 29.7589 11.9194 29.4643V19.8214C11.9194 19.5268 11.6783 19.2857 11.3837 19.2857H7.63365V16.3393H22.3658V19.2857H18.6158C18.3212 19.2857 18.0801 19.5268 18.0801 19.8214V29.4643C18.0801 29.7589 18.3212 30 18.6158 30H28.2587C28.5533 30 28.7944 29.7589 28.7944 29.4643V19.8214C28.7944 19.5268 28.5533 19.2857 28.2587 19.2857ZM9.37472 21.8304V27.4554H3.74972V21.8304H9.37472ZM12.1872 8.16964V2.54464H17.8122V8.16964H12.1872ZM26.2497 27.4554H20.6247V21.8304H26.2497V27.4554Z"
          fill="black"
          fill-opacity="0.85"
        />
      </g>
      <defs>
        <clipPath id="clip0_1961_16631">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BandwidthIcon;
