import React, { useEffect, useState } from "react";

import PageLayout from "../components/Layout/PageLayout";
import ProfileComponent from "../components/pages/ProfileComponent";

const Profile = () => {
  useEffect(()=>{
    document.title = "AiFise | Profile";
  })
  return (
    <PageLayout isProfile title="Profile" subTitle="Update your profile and reset your password.">
      <ProfileComponent />
    </PageLayout>
  );
};

export default Profile;
