import { Box, IconButton, Typography } from "@mui/material"
import { useEffect, useRef } from 'react'
import { prettyPrintJson } from 'pretty-print-json';
import CopyIcon from "../../Icons/CopyIcon";
import { useSnackbar } from 'notistack';
import CloseIcon from "@mui/icons-material/Close";
type JSONCardProps = {
    data: any,
    background?: string,
    maxHeight?: string,
    cardStyle?: React.CSSProperties
}

const JSONCard = ({ data, background = "#FBFAFE", maxHeight = "460px", cardStyle = {} }: JSONCardProps) => {
    const jsonRef = useRef<HTMLDivElement>();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        if (jsonRef.current) {
            jsonRef.current.innerHTML = prettyPrintJson.toHtml(data, { quoteKeys: true });
        }
    }, [data])

    const onClickCopy = () => {
        navigator.clipboard.writeText(JSON.stringify(data));
        enqueueSnackbar("copied to clipboard", {
          variant: "success",
          action: (key) => <CloseIcon onClick={() => closeSnackbar(key)} />,
        });
    }

    return (
        <Box
            sx={{
                background,
                maxHeight,
                padding: "16px 0 16px 16px !important",
                lineHeight: 1,
                borderRadius: "4px",
                border: "0.5px solid #9F9FA1",
                whiteSpace: "pre-wrap",
                boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 0px 2px 0px rgba(0, 0, 0, 0.09), 0px 0px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 3px 0px rgba(0, 0, 0, 0.01), 0px 0px 3px 0px rgba(0, 0, 0, 0.00)",
                overflowY: "auto",
                position: "relative",
                ...cardStyle
            }}
        >
            <Typography
                sx={{
                    fontSize: 14,
                    fontStyle: "normal",
                    fontWeight: "fontWeightMedium"
                }}
            >
                JSON
            </Typography>
            <IconButton
                sx={{
                    position: "absolute",
                    right: 12,
                    top: 12,
                    color: "#000",
                    "& .copy-text": {
                        fill: "#00000073"
                    },
                    "&:hover .copy-text": {
                        fill: "#000"
                    }
                }}
                onClick={onClickCopy}
            >
                <CopyIcon />
            </IconButton>
            <Box
                ref={jsonRef}
                sx={{
                    height: "384px",
                    overflowY: "auto",
                     marginTop: "16px",
                  
                     "&::-webkit-scrollbar": {
                     width: "2px",
                     marginRight:"8px",
                        // opacity: "0",
                        // animation: `${animatedBackground} 5s linear `,
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#9F9FA1",
                        borderRadius: "8px",
                        // height: "50px",
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#f0f0f0",
                        opacity: "0.5",
                        borderRadius: "8px",
                        height: "5px",
                    },
        
                    "& .json-key, & .json-mark": {
                      color: "#0B8043",
                      fontSize: 12,
                    },
                    "& .json-number": {
                      color: "#3367D6",
                      fontSize: 12,
                    },
                    "& .json-string": {
                      color: "#C53929",
                      fontSize: 12,
                    },
                  }}
            >

            </Box>
        </Box>

    )
}

export default JSONCard