import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import WebIcon from "../../Icons/WebIcon";
import AndroidIcon from "../../Icons/AndroidIcon";
import AppleIcon from "../../Icons/AppleIcon";
import theme from "../../style/GlobalStyle";

type AvailBoxProps = {
  boxStyle?: React.CSSProperties;
};

const AvailBox = ({ boxStyle = {} }: AvailBoxProps) => {
  const gridItemStyle = {
    display: "flex",
    padding: "16px 32px 12px 32px",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "4px",
    background: "rgba(186, 189, 191, 0.10)",
    maxWidth: "100px",
    maxHeight: "100px",
    cursor: "pointer",
    transition: "background 0.3s",
    userSelect: "none",
    ":hover": {
      background: "rgba(186, 189, 191, 0.3)",
    },
    ":active": {
      background: "rgba(186, 189, 191, 0.5)",
    },
  };

  const gridItemTitleStyle = {
    color: "#000",
    textAlign: "center",
    // fontFamily: "Avenir LT Std",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "fontWeightBold",
    lineHeight: "24px",
  };

  const handleWebIconClick = () => {
    window.open("https://main.df64pxx7nxbca.amplifyapp.com/", "_blank");
  };

  return (
    <>
      <Box
        pt={2}
        justifyContent="center"
        sx={{ maxWidth: "362px", margin: "auto", ...boxStyle }}
      >
        <Typography
          // variant="h6"
          sx={{
            color: "#000",
            textAlign: "center",
            fontSize: "24px",
            fontWeight: "fontWeightBold",
            fontStyle: "normal",
            lineHeight: "24px",
          }}
        >
          Available on
        </Typography>
     
          <Grid
            container
            justifyContent="center"
            sx={{
              margin: "16px 0px 0px 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item sx={gridItemStyle} onClick={handleWebIconClick}>
              <div onClick={handleWebIconClick}>
                <WebIcon />
                <Typography sx={gridItemTitleStyle} variant="h6">
                  Web
                </Typography>
              </div>
            </Grid>
            <Grid item sx={gridItemStyle}>
              <AndroidIcon />
              <Typography sx={gridItemTitleStyle} variant="h6">
                Android
              </Typography>
            </Grid>
            <Grid item sx={gridItemStyle}>
              <AppleIcon />
              <Typography sx={gridItemTitleStyle} variant="h6">
                iOS
              </Typography>
            </Grid>
          </Grid>
      
      </Box>
    </>
  );
};

export default AvailBox;
