import React from 'react'

const AdharIcon1 = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.5 27.5C52.5 41.375 42.9 54.35 30 57.5C17.1 54.35 7.5 41.375 7.5 27.5V12.5L30 2.5L52.5 12.5V27.5ZM30 52.5C39.375 50 47.5 38.85 47.5 28.05V15.75L30 7.95L12.5 15.75V28.05C12.5 38.85 20.625 50 30 52.5ZM37 27.5V23.75C37 20.25 33.5 17.5 30 17.5C26.5 17.5 23 20.25 23 23.75V27.5C21.5 27.5 20 29 20 30.5V39.25C20 41 21.5 42.5 23 42.5H36.75C38.5 42.5 40 41 40 39.5V30.75C40 29 38.5 27.5 37 27.5ZM33.75 27.5H26.25V23.75C26.25 21.75 28 20.5 30 20.5C32 20.5 33.75 21.75 33.75 23.75V27.5Z"
        fill="black"
        fill-opacity="0.85"
      />
    </svg>
  );
}

export default AdharIcon1
