import React from 'react'

const Onboarding1 = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.5 37.5H50V32.5C50 31.837 49.7366 31.2011 49.2678 30.7322C48.7989 30.2634 48.163 30 47.5 30H12.5C11.837 30 11.2011 30.2634 10.7322 30.7322C10.2634 31.2011 10 31.837 10 32.5V37.5H7.5C6.83696 37.5 6.20107 37.7634 5.73223 38.2322C5.26339 38.7011 5 39.337 5 40C5 40.663 5.26339 41.2989 5.73223 41.7678C6.20107 42.2366 6.83696 42.5 7.5 42.5H10V47.5C10 48.163 10.2634 48.7989 10.7322 49.2678C11.2011 49.7366 11.837 50 12.5 50H47.5C48.163 50 48.7989 49.7366 49.2678 49.2678C49.7366 48.7989 50 48.163 50 47.5V42.5H52.5C53.163 42.5 53.7989 42.2366 54.2678 41.7678C54.7366 41.2989 55 40.663 55 40C55 39.337 54.7366 38.7011 54.2678 38.2322C53.7989 37.7634 53.163 37.5 52.5 37.5ZM45 45H15V35H45V45ZM7.5 20H12.5V22.5C12.5 23.163 12.7634 23.7989 13.2322 24.2678C13.7011 24.7366 14.337 25 15 25H45C45.663 25 46.2989 24.7366 46.7678 24.2678C47.2366 23.7989 47.5 23.163 47.5 22.5V20H52.5C53.163 20 53.7989 19.7366 54.2678 19.2678C54.7366 18.7989 55 18.163 55 17.5C55 16.837 54.7366 16.2011 54.2678 15.7322C53.7989 15.2634 53.163 15 52.5 15H47.5V12.5C47.5 11.837 47.2366 11.2011 46.7678 10.7322C46.2989 10.2634 45.663 10 45 10H15C14.337 10 13.7011 10.2634 13.2322 10.7322C12.7634 11.2011 12.5 11.837 12.5 12.5V15H7.5C6.83696 15 6.20107 15.2634 5.73223 15.7322C5.26339 16.2011 5 16.837 5 17.5C5 18.163 5.26339 18.7989 5.73223 19.2678C6.20107 19.7366 6.83696 20 7.5 20ZM17.5 15H42.5V20H17.5V15Z"
        fill="black"
        fill-opacity="0.85"
      />
    </svg>
  );
}

export default Onboarding1
