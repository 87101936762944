import { Box, Typography } from '@mui/material'

type ImagePreviewProps = {
    title: string,
    src: string,
    name: string
}

const ImagePreview = ({ title, src, name }: ImagePreviewProps) => {
    return (
        <Box
            sx={{
                p: 2,
                lineHeight: 1,
                borderRadius: "4px",
                border: "0.5px solid #9F9FA1",
                background: " #FFF",
                height: '100%',
                boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 0px 2px 0px rgba(0, 0, 0, 0.09), 0px 0px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 3px 0px rgba(0, 0, 0, 0.01), 0px 0px 3px 0px rgba(0, 0, 0, 0.00)"
            }}
        >
            <Typography
                fontWeight={600}
                fontSize={14}
                marginBottom={0.8}
            >
                {title}
            </Typography>
            <Box>
                <Box
                    component="img"
                    src={src}
                    alt='ocr-image'
                    sx={{
                        display: "block",
                        margin: "auto",
                        width: "100%",
                        maxHeight: 350,
                        objectFit: "cover"
                    }}
                />
            </Box>
            <Typography
                sx={{
                    paddingTop: "6px",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "fontWeightRegular"
                }}
            >
                {name}
            </Typography>
        </Box>
    )
}

export default ImagePreview