import axios from "axios"
import store from "../redux/store";
import { changeSessionModal } from "../reduxSlice/sessionExpire/sessionExpireSlice";

export type ApiRequestType = "get" | "post" | "put" | "delete";

interface APIType {
    api: string;
    method: ApiRequestType;
    baseurl?: string;
    body?: any;
    isAuthentic?: boolean;
    extraParams?: any;
    isBearer?: boolean;
    header?: any;
}

const api = async ({
    api,
    method = "get",
    baseurl = "baseurl",
    body,
    isAuthentic = true,

    extraParams,
    isBearer = false,
}: APIType) => {

    const axiosInstance = axios.create({});

    axiosInstance.interceptors.response.use(
        (response):any => response,
        (error) => {
            if (error.response.status === 401 || error.response.data.message === 'jwt expired') {
                store.dispatch(changeSessionModal(true));
                localStorage.clear();
                // window.location.href = '/login'
                return
            }
                return Promise.reject(error);
          }
      );

    return await new Promise((resolve, reject) => {
        
        const auth =  localStorage.getItem('userLoginData') ? JSON.parse(localStorage.getItem('userLoginData') as string) : null;
        const token =  localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token') as string) : null;
        
        // if(isBearer){          
            axiosInstance.defaults.headers.common.Authorization = (auth?.token!==undefined)?`Bearer ${auth?.token}`:`Bearer ${token}` ;
        // }else if(isAuthentic){
        //     axiosInstance.defaults.auth = { username: "efe6331c", password: "c16774971b0208dbf9990af0d9cda714da9b231c" };
        // }         

        // if(extraParams){
        //     axiosInstance.defaults = extraParams;
        // }
        if (baseurl === 'addressmatch') {
            axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
        }


        axiosInstance[method](`${getApiBaseUrl(baseurl)}${api}`, body ? body : "")
            .then((res: any) => {
                resolve(res.data);
            }).catch((error: any) => {
                if (error.response) {
                    reject(error.response.data)
                } else {
                    reject(error);
                }
            })
    })
}
const getApiBaseUrl = (type: string) => {
    switch (type) {
        case ('baseurl'):
            return process.env.REACT_APP_API_BASE_URL;

        case ('baseurl2') :
            return process.env.REACT_APP_API_BASE_URL2;
    
        case ('addressmatch') :
            return process.env.REACT_APP_API_BASE_URL_ADDRESS_MATCH;
    
        case ('faceapi'): {
            return `https://uat.facematch.innodeed.com/v1/api`;
        }
        case ('violence'): {
            return `https://m5ba0ktdqf.execute-api.ap-south-1.amazonaws.com`;  
        }
        case ('violence-video'): {
            return `https://dipo9gblik.execute-api.ap-south-1.amazonaws.com/dev/nsfw`
        }

        case ('selfie'): {
            return `https://dipo9gblik.execute-api.ap-south-1.amazonaws.com`
        }
        default:
            return process.env.REACT_APP_API_BASE_URL;
    }
}

export default api;