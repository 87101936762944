import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom';
import { Grid, Stack, Typography } from '@mui/material'
import FileDragDrop from '../components/Common/FileDragDrop';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import CustomButton from '../components/Common/CustomButton';
import FilePreview from '../components/pages/FaceMatch/FilePreview';
import Loader from '../components/Common/Loader/Loader';
import HeaderTop from '../components/pages/OCR/HeaderTop';
import JSONCard from '../components/Common/JSONCard';
import ImagePreview from '../components/pages/FaceMatch/ImagePreview';
import { faceMatch } from '../service/apiActions/FaceAction';
import { OutletcontextType } from '../components/Layout/Index';
import DataSecurityCard from '../components/Common/DataSecurityCard';


const FaceMatch = () => {
    const {
        handleChangeTitle,
        showSnackbar
    } = useOutletContext<OutletcontextType>();
    const [file1, setFile1] = useState<any>({});
    const [file2, setFile2] = useState<any>({});
    const [isLoading, setLoading] = useState(false);
    const [result, setResult] = useState<any>({});
    const [file1size, setFile1Size] = useState<any>(0);
    const [file2size, setFile2Size] = useState<any>(0);

    useEffect(() => {
        document.title = "AiFise | Face Match";
        handleChangeTitle("Face Match", "Compare faces from two sources and generate a matching score.")
    }, [])

    const reset = () => {
        setFile1({})
        setFile2({})
        setResult({})
    }

    const handleFile1Change = (files: any) => {
        if (files.length > 0 && files[0].size > (1024 * 1024 * 3)) {
            showSnackbar("File size cannot be greater than 3 MB");
            return;
        } else if (files.length > 0 && files[0].type !== 'image/png' && files[0].type !== 'image/jpeg' && files[0].type !== 'image/jpg') {
            showSnackbar("Only png, jpg and jpeg are allowed");
            return;
        }
        setFile1(files[0])
        const sizeInMB = (files[0].size / (1024 * 1024)).toFixed(2);
        setFile1Size(sizeInMB+" MB")
    }

    const handleFile2Change = (files: any) => {
        if (files.length > 0 && files[0].size > (1024 * 1024 * 3)) {
            showSnackbar("File size cannot be greater than 3 MB");
            return;
        } else if (files.length > 0 && files[0].type !== 'image/png' && files[0].type !== 'image/jpeg' && files[0].type !== 'image/jpg') {
            showSnackbar("Only png, jpg and jpeg are allowed");
            return;
        }
        setFile2(files[0])
        const sizeInMB = (files[0].size / (1024 * 1024)).toFixed(2);
        setFile2Size(sizeInMB+" MB")
    }

    const handleUpload = () => {
        if (file1.name === undefined || file2.name === undefined) {
            showSnackbar("Please fill all fields");
            return;
        }
        setLoading(true);
        let formData = new FormData();
        formData.append("id", file1);
        formData.append("selfie", file2);
        faceMatch(formData).then((res) => {
            setResult(res);
            setLoading(false)
        }).catch((error) => {
            showSnackbar("Something went wrong")
            setLoading(false)
        })
    }

    if (isLoading) {
        return <Loader />
    }

    return (
      <>
        {Object.keys(result).length > 0 ? (
          <>
            <HeaderTop reset={reset} />
            <Grid container mt={0} spacing={2} justifyContent="center">
              <Grid item lg={4} md={5} sm={6} xs={12}>
                <ImagePreview
                  title="Image 1 Preview"
                  src={URL.createObjectURL(file1)}
                  name={file1?.name}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={6} xs={12}>
                <ImagePreview
                  title="Image 2 Preview"
                  src={URL.createObjectURL(file2)}
                  name={file2?.name}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={6} xs={12}>
                <JSONCard data={result} />
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid container spacing={2} justifyContent="center">
            <Grid item lg={5} md={6} sm={6} xs={12}>
              {file1?.name ? (
                <FilePreview
                  sx={{ mt: 1 }}
                  name={file1?.name}
                  filesize={file1size}
                  handleClose={() => setFile1({})}
                />
              ) : (
                <FileDragDrop handleChange={handleFile1Change} />
              )}
            </Grid>
            <Grid item lg={5} md={6} sm={6} xs={12}>
              {file2?.name ? (
                <FilePreview
                  sx={{ mt: 1 }}
                  name={file2?.name}
                  filesize={file2size}
                  handleClose={() => setFile2({})}
                />
              ) : (
                <FileDragDrop handleChange={handleFile2Change} />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="text.secondary" align="center">
                <b>Note:</b> File size should be less than 3 MB and only png,
                jpg and jpeg are allowed.
              </Typography>
            </Grid>
            <Grid item lg={10} md={12} sm={12} xs={12}>
              <Stack direction="row-reverse" gap={1.5}>
                <CustomButton
                  variant="outlined"
                  size="large"
                  startIcon={<RefreshOutlinedIcon />}
                  onClick={reset}
                >
                  Reset
                </CustomButton>
                <CustomButton
                  startIcon={<FileUploadOutlinedIcon />}
                  size="large"
                  onClick={handleUpload}
                >
                  Upload
                </CustomButton>
              </Stack>
            </Grid>
          </Grid>
        )}
        <Grid container justifyContent="center">
          <Grid item xl={11} lg={12} md={12} xs={12}>
            <DataSecurityCard />
          </Grid>
          <Grid item xs={12}>
            <img
              src="/images/facematch.svg"
              alt="facematch"
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
        
      </>
    );
}

export default FaceMatch