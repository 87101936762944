import {
  Button,
  ImageList,
  ImageListItem,
  Modal,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { Grid, Box } from "@mui/material";
import { CameraAltOutlined, ErrorOutlineSharp } from "@mui/icons-material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import CustomButton from "../components/Common/CustomButton"; // CustomLoadingButton,
import YamlDragDrop from "../components/Common/YamlDragDrop";
import { useCallback, useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { OutletcontextType } from "../components/Layout/Index";
import FilePreview from "../components/pages/FaceMatch/FilePreview";
import DataSecurityCard from "../components/Common/DataSecurityCard";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { StorageManagercomp } from "../components/Common/StorageManager/StorageManager";
// import * as S3 from "aws-sdk/clients/s3";
// import S3 from "aws-sdk/clients/s3";
import { Auth, Storage } from "aws-amplify";
import Webcam, { WebcamProps } from "react-webcam";
import WebcamRef from "react-webcam";
import {
  selfieMatchDeleteApi,
  selfieMatchingApi,
} from "../service/apiActions/FaceAction";
import Loader from "../components/Common/Loader/Loader";
import FeatureFrame from "../components/Common/FeatureFrame";
import LiveIcon from "../Icons/LiveIcon";
import VideoCameraIcon from "../Icons/VideoCameraIcon";
import MapIcon from "../Icons/MapIcon";
import ImageSearch from "../Icons/faceSearch/ImageSearch";
import Scalable from "../Icons/faceSearch/Scalable";
import Customize from "../Icons/faceSearch/Customize";
import NoImage from "../Icons/NoImage";

Storage.configure({ region: "ap-south-1" });

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const FaceSearch = () => {
  const [isLoading, setLoading] = useState(false);
  const [resData, setResData] = useState<any>({});
  const [formdata, setFormdata] = useState<any>([]);
  const [uploadFormData, setUploadFormData] = useState<any>({});
  const [faceSearch, setFaceSearch] = useState<boolean>(false);

  const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
  const [isGalleryOpen, setGalleryOpen] = useState(false);
  const [isWebcamOpen, setWebcamOpen] = useState(false);
  const [img, setImg] = useState<null | string>(null);
  const webcamRef = useRef<WebcamRef & WebcamProps>(null);
  const [matchedImages, setMatchedImages] = useState<any>([]);
  const [unMatchedImages, setUnmatchedImages] = useState<any>([]);
  const [selectedImageType, setSelectedImageType] = useState("");
  const [isResetSuccessful, setResetSuccessful] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const videoConstraints = {
    width: 420,
    height: 420,
    facingMode: "user",
  };

  const userEmail = localStorage.getItem("userLoginData")
    ? JSON.parse(localStorage.getItem("userLoginData") as string)
    : null;

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      // Convert the base64 image data to a Blob
      const byteString = atob(imageSrc.split(",")[1]);
      const mimeString = imageSrc.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: mimeString });

      // Create a File object from the Blob
      const file = new File([blob], "selfie.jpg", { type: mimeString });
      // Update the uploadFormData state
      setUploadFormData([file]);
      setImg(imageSrc);
    }
  }, [webcamRef]);

  const handleImageClick = (index: any, type: string) => {
    setSelectedImageIndex(index);
    setSelectedImageType(type);
    setGalleryOpen(true);
  };

  const handleUploadSuccess = () => {
    setResetSuccessful(true);
    setUploadSuccess(true);
  };

  const handleUploadError = () => {
    setUploadSuccess(false);
    showSnackbar("Error in uploading images");
  };

  const handleUploadStart = () => {
    if (!isResetSuccessful) {
      console.log("isResetSuccessful is false, preventing upload");
      return false; // Prevent the upload
    }
    console.log("isResetSuccessful is true, allowing upload");
    return true; // Allow the upload
  };

  const handleModalClose = () => {
    setGalleryOpen(false);
    setSelectedImageIndex(-1);
  };

  const { handleChangeTitle, showSnackbar } =
    useOutletContext<OutletcontextType>();

  useEffect(() => {
    document.title = "AiFise | Face Search";
    handleChangeTitle(
      "Face Search",
      "Identifies faces across multiple images."
    );
  }, []);

  // const handleUpload = async () => {
  //   // let _formdata = new FormData();
  //   // // setLoading(true);

  //   // if (formdata.name) {
  //   //   _formdata.append("config", formdata);
  //   // }

  //   // setResData([{}, {}]);

  //   // setResData([{}, {}]);
  //   // try {
  //   //   const credentials = await Auth.currentAuthenticatedUser();

  //   //   const s3 = new S3({
  //   //     params: {
  //   //       Bucket: "aifise-face-search",
  //   //       MaxKeys: "1000",
  //   //       // Delimiter: 'public/',
  //   //       Prefix: `public`,
  //   //     },
  //   //     region: "ap-south-1",
  //   //     credentials,
  //   //   });

  //   //   const response = await s3.listObjectsV2().promise();
  //   //   console.log("===Total response", response.Contents);
  //   // } catch (err) {
  //   //   console.log("Error in handleUpload: ", err);
  //   // }
  //   // let arr: Array=[];

  //   Storage.list(`${userEmail.email}`) // for listing ALL files without prefix, pass '' instead
  //     .then(({ results }) => {
  //       console.log(results, "total results obj ");
  //       const imageKeys = results
  //         .filter(
  //           (item) =>
  //             item.key &&
  //             (item.key.endsWith(".jpg") ||
  //               item.key.endsWith(".JPG") ||
  //               item.key.endsWith(".JPEG") ||
  //               item.key.endsWith(".jpeg"))
  //         )
  //         .map((item) => item.key);

  //       console.log(imageKeys, "filtered image keys");
  //       setFormdata(imageKeys);
  //     })
  //     .catch((err) => console.log(err));
  //     if(formdata.length === 0){
  //       showSnackbar("Please upload images to search faces");
  //       return;
  //     }

  //   // console.log("new arr",arr)
  // };

  const handleUpload = async () => {
    // setLoading(true);
    try {
      const { results } = await Storage.list(`${userEmail.email}`);

      console.log(results, "total results obj ");

      const imageKeys = results
        .filter(
          (item) =>
            item.key &&
            (item.key.endsWith(".jpg") ||
              item.key.endsWith(".JPG") ||
              item.key.endsWith(".JPEG") ||
              item.key.endsWith(".jpeg"))
        )
        .map((item) => item.key);

      console.log(imageKeys.length, "filtered image keys");

      if (imageKeys.length === 0) {
        showSnackbar("Processing , please wait for a few seconds");

        return;
      }

      setFormdata(imageKeys);
      // setLoading(false);
    } catch (err) {
      console.log("Error in handleUpload: ", err);
    }
  };

  // const handleReset = () => {
  //   setResData({});
  //   setFormdata({});
  // };

  const handleFile = (files: any, e: any) => {
    if (e.target.files) {
      const files = Array.from(e.target.files) as File[];
      setFormdata(files);
    }
  };

  const handleUploadFromDevice = (e: any) => {
    setWebcamOpen(false);
    setImg(null);
    if (e.target.files) {
      const files = Array.from(e.target.files) as File[];
      setUploadFormData(files);
    }
  };

  const handleUploadReset = () => {
    setUploadFormData({});
    setImg(null);
    setWebcamOpen(false);
    // console.log("Reset Clicked");
  };

  const handleSearchFace = async () => {
    try {
      setLoading(true);
      if (uploadFormData.length === 0) {
        showSnackbar("Please upload reference image to search faces");
        return;
      }
      const userEmail = localStorage.getItem("userLoginData")
        ? JSON.parse(localStorage.getItem("userLoginData") as string)
        : null;

      const formData = new FormData();
      formData.append("selfie", uploadFormData[0]);
      formData.append("userid", userEmail.email);

      await selfieMatchingApi(formData)
        .then((res: any) => {
          console.log(res, "res");
          if (res.status === "success") {
            console.log(res.facematch, "matched res.data");
            setLoading(false);
            setMatchedImages(res.facematch);
            console.log(formdata, "formdata");

            const matchedFileNames = res.facematch;

            // Extract file names from the entire path
            const fileNames = formdata.map(
              (item: string) => item.split("/").pop() || ""
            );

            // Filter matched file names
            const unmatchedFileNames = fileNames.filter(
              (fileName: any) => !matchedFileNames.includes(fileName)
            );

            // Filter unmatched file paths
            const unmatchedFilePaths = formdata.filter((filePath: string) =>
              unmatchedFileNames.includes(filePath.split("/").pop())
            );

            console.log(unmatchedFilePaths, "unmatched");
            setUnmatchedImages(unmatchedFilePaths);

            setFaceSearch(true);
          }
        })
        .catch((err) => {
          console.log(err, "selfiematch err");
          setLoading(false);
          showSnackbar(err);
        });
    } finally {
      setLoading(false); // Setting isLoading to false after the API call, whether it succeeds or fails
    }
  };

  const handleResetUpload = async () => {
    try {
      await selfieMatchDeleteApi(userEmail.email).then((res: any) => {
        console.log(res, "res");
        if (res.status === "success") {
          console.log(res.facematch, "matched res.data");
          setLoading(false);
          showSnackbar("Resetted Successfully", "success");
          setResetSuccessful(true);
        }
      });
    } catch (error: any) {
      console.log(error, "selfiematch err");
      setLoading(false);
      showSnackbar(error.message);
    }
  };

  const handleReSearchFace = () => {
    setFaceSearch(false);
    setUploadFormData({});
    setImg(null);
    setWebcamOpen(false);
  };

  const handleFinalReset = () => {
    setFaceSearch(false);
    setUploadFormData({});
    setImg(null);
    setWebcamOpen(false);
    setFormdata([]);
    setMatchedImages([]);
    setUnmatchedImages([]);
    setResetSuccessful(false);
    setUploadSuccess(false);
  };

  const handleFileClose = (index: number) => {
    setFormdata((prevFormdata: any) => {
      const updatedFormdata = [...prevFormdata];
      updatedFormdata.splice(index, 1);
      return updatedFormdata;
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  const baseUrl = process.env.REACT_APP_IMAGES_BASE_URL;
  const imageUrls = matchedImages.map(
    (fileName: any) => baseUrl + `public/${userEmail.email}/` + fileName
  );
  console.log(imageUrls, "imageUrls");
  // console.log(formdata, "logging formdata");
  const unMatchedImageUrls = unMatchedImages.map(
    (fileName: any) => baseUrl + `public/` + fileName
  );

  const uploadedImageUrls = formdata.map(
    (fileName: any) => baseUrl + `public/` + fileName
  );

  const galleryImages =
    selectedImageType === "uploaded"
      ? uploadedImageUrls.map((image: any) => ({
          original: image,
          thumbnail: image,
        }))
      : selectedImageType === "matched"
      ? matchedImages.map((fileName: any) => ({
          original: baseUrl + `public/${userEmail.email}/` + fileName,
          thumbnail: baseUrl + `public/${userEmail.email}/` + fileName,
        }))
      : selectedImageType === "unmatched"
      ? unMatchedImageUrls.map((image: any) => ({
          original: image,
          thumbnail: image,
        }))
      : [];

  return (
    <>
      {Object.keys(formdata).length === 0 ? (
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12} md={12} lg={8} sm={6} justifyContent="center">
            <Box sx={{ display: "block", width: "100%" }} mb={1}>
              <Typography
                noWrap
                component="div"
                color="rgba(0, 0, 0, 0.70)"
                fontSize="20px"
                fontWeight={600}
                lineHeight="24px"
                textAlign="center"
              >
                Upload images to search faces
              </Typography>
              <Typography
                color="rgba(0, 0, 0, 0.70)"
                fontSize="16px"
                fontWeight={400}
                lineHeight="22.4px"
                marginTop={0.5}
                textAlign="center"
                sx={{ pb: 2 }}
              >
                For example, upload all the photos containing faces you want to
                sort and organize.
              </Typography>
            </Box>
            <Grid item>
              <div>
                {formdata?.length > 0 &&
                  formdata?.map((file: any, index: number) => (
                    <FilePreview
                      sx={{ mt: 1 }}
                      name={file?.name}
                      filesize={file?.size}
                      handleClose={() => handleFileClose(index)} // Passing the index to handleFileClose
                    />
                  ))}{" "}
                <LightTooltip
                  title={
                    !isResetSuccessful && "Disabled: Reset first before upload"
                  }
                >
                  <span>
                    <div
                      style={{
                        pointerEvents: isResetSuccessful ? "auto" : "none",
                        opacity: isResetSuccessful ? 1 : 0.2,
                      }}
                    >
                      <StorageManagercomp
                        onUploadSuccess={handleUploadSuccess}
                        onUploadError={handleUploadError}
                      />
                    </div>
                  </span>
                </LightTooltip>
              </div>
            </Grid>
            <Grid
              item
              display="flex"
              justifyContent="flex-end"
              lg={12}
              mt={3}
              gap={2}
            >
              <CustomButton
                size="large"
                onClick={handleResetUpload}
                variant="outlined"
                disabled={isResetSuccessful}
                // loading={isLoading}
              >
                Reset
              </CustomButton>
              <CustomButton
                size="large"
                onClick={handleUpload}
                disabled={!uploadSuccess}
                // loading={isLoading}
                sx={{
                  border:
                    uploadSuccess && formdata.length > 0 ? "1px solid" : "none",
                }}
              >
                Next
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          rowSpacing={2}
          container
          alignItems={"center"}
          flexDirection="column"
        >
          {faceSearch ? (
            <Grid
              container
              justifyContent={"space-between"}
              lg={8}
              sx={{ marginTop: 2 }}
            >
              <Grid item>
                {/* <Grid container > */}
                <Typography
                  color="rgba(0, 0, 0, 0.70)"
                  fontSize="16px"
                  fontWeight={600}
                  lineHeight="24px"
                  marginTop={0.5}
                  textAlign="center"
                >
                  {matchedImages.length} faces matched
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  maxHeight: "60vh",
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: "2px",
                    marginRight: "8px",
                    // opacity: "0",
                    // animation: `${animatedBackground} 5s linear `,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#9F9FA1",
                    borderRadius: "8px",
                    // height: "50px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#f0f0f0",
                    opacity: "0.5",
                    borderRadius: "8px",
                    height: "5px",
                  },
                }}
              >
                {/* <Grid container spacing={1}> */}
                <ImageList
                  sx={{ width: "100%", height: "auto" }}
                  cols={9}
                  gap={8}
                >
                  {imageUrls?.length === 0 ? (
                    <ImageListItem>
                      <NoImage />
                    </ImageListItem>
                  ) : (
                    imageUrls.map((image: string, index: number) => (
                      <ImageListItem
                        key={index}
                        onClick={() => handleImageClick(index, "matched")}
                      >
                        <img
                          src={image}
                          alt={`image-${index}`}
                          loading="lazy"
                          style={{
                            objectFit: "cover",
                            width: "100%",
                            height: "4rem",
                            aspectRatio: "1/1",
                          }}
                        />
                      </ImageListItem>
                    ))
                  )}
                </ImageList>
                {/* </Grid> */}
              </Grid>
              <Typography
                color="rgba(0, 0, 0, 0.70)"
                fontSize="16px"
                fontWeight={600}
                lineHeight="24px"
                marginTop={0.5}
                textAlign="center"
              >
                {unMatchedImages.length} unmatched faces
              </Typography>
              <Grid
                item
                sx={{
                  maxHeight: "60vh",
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: "2px",
                    marginRight: "8px",
                    // opacity: "0",
                    // animation: `${animatedBackground} 5s linear `,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#9F9FA1",
                    borderRadius: "8px",
                    // height: "50px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#f0f0f0",
                    opacity: "0.5",
                    borderRadius: "8px",
                    height: "5px",
                  },
                }}
              >
                {/* <Grid container spacing={1}> */}
                <ImageList
                  sx={{ width: "100%", height: "auto" }}
                  cols={9}
                  gap={8}
                >
                  {unMatchedImageUrls.map((image: string, index: number) => (
                    <ImageListItem
                      key={index}
                      onClick={() => handleImageClick(index, "unmatched")}
                    >
                      <img
                        src={image}
                        alt={`image-${index}`}
                        loading="lazy"
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "4rem",
                          aspectRatio: "1/1",
                        }}
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
                {/* </Grid> */}
              </Grid>
              <CustomButton
                variant="contained"
                color="primary"
                onClick={handleReSearchFace}
                startIcon={<RefreshOutlinedIcon />}
                sx={{ mt: 1 }}
              >
                Search Faces Again
              </CustomButton>
              <CustomButton
                variant="outlined"
                // color="secondary"
                onClick={handleFinalReset}
                startIcon={<RefreshOutlinedIcon />}
                sx={{ mt: 1 }}
              >
                Reset
              </CustomButton>
            </Grid>
          ) : (
            // </Grid>
            // </Grid>
            <>
              <Grid
                container
                justifyContent={"space-between"}
                lg={8}
                sx={{ marginTop: 2 }}
              >
                {/* <Grid item> */}
                <Grid container rowSpacing={2}>
                  <Typography
                    color="rgba(0, 0, 0, 0.70)"
                    fontSize="16px"
                    fontWeight={600}
                    lineHeight="24px"
                    marginTop={0.5}
                    textAlign="center"
                  >
                    {formdata.length} items uploaded...
                  </Typography>
                  <Grid
                    item
                    sx={{
                      maxHeight: "60vh",
                      overflow: "auto",
                      "&::-webkit-scrollbar": {
                        width: "2px",
                        marginRight: "8px",
                        // opacity: "0",
                        // animation: `${animatedBackground} 5s linear `,
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#9F9FA1",
                        borderRadius: "8px",
                        // height: "50px",
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "#f0f0f0",
                        opacity: "0.5",
                        borderRadius: "8px",
                        height: "5px",
                      },
                    }}
                  >
                    <Grid container>
                      <ImageList
                        sx={{ width: "100%", height: "auto" }}
                        cols={9}
                        gap={8}
                      >
                        {uploadedImageUrls?.map(
                          (image: string, index: number) => (
                            <ImageListItem
                              key={index}
                              onClick={() =>
                                handleImageClick(index, "uploaded")
                              }
                            >
                              <img
                                src={image}
                                alt={`uploaded image-${index}`}
                                loading="lazy"
                                style={{
                                  objectFit: "cover",
                                  width: "100%",
                                  height: "4rem",
                                  aspectRatio: "1/1",
                                }}
                              />
                            </ImageListItem>
                          )
                        )}
                      </ImageList>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Box sx={{ display: "block", width: "100%" }} mb={1}>
                      {uploadFormData.length > 0 ? (
                        <Typography
                          color="rgba(0, 0, 0, 0.70)"
                          fontSize="16px"
                          fontWeight={400}
                          lineHeight="22.4px"
                          marginTop={0.5}
                          textAlign="center"
                        >
                          Reference image uploaded...
                        </Typography>
                      ) : (
                        <>
                          <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            color="#141414"
                            fontSize="20px"
                            fontWeight={700}
                            lineHeight="24px"
                            textAlign="center"
                          >
                            Upload refernce images to search faces
                          </Typography>
                          <Typography
                            color="rgba(0, 0, 0, 0.70)"
                            fontSize="16px"
                            fontWeight={400}
                            lineHeight="22.4px"
                            marginTop={0.5}
                            textAlign="center"
                          >
                            For instance, upload your selfie or the image from
                            which you'd like to search faces among multiple
                            images.
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                {/* </Grid> */}
              </Grid>
              <Grid
                container
                justifyContent={
                  uploadFormData.length > 0 ? "flex-start" : "center"
                }
                lg={8}
                mt={2}
                // columnSpacing={2}
                sx={{ marginTop: 2, display: "flex" }}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row" }}
                  columnGap={2}
                >
                  {/* <Grid item> */}
                  <CustomButton
                    variant="outlined"
                    color="info"
                    startIcon={<FileUploadOutlinedIcon />}
                    component="label"
                  >
                    Upload From Device
                    <input
                      type="file"
                      hidden
                      onChange={handleUploadFromDevice}
                    />
                  </CustomButton>
                  {/* </Grid> */}
                  {/* <Grid item> */}
                  <CustomButton
                    variant="outlined"
                    color="info"
                    startIcon={<CameraAltOutlined />}
                    component="label"
                    onClick={() => setWebcamOpen(true)}
                    sx={{ marginRight: 1 }}
                  >
                    Capture Selfie
                  </CustomButton>
                  {/* </Grid> */}
                  {uploadFormData.length > 0 && (
                    // <Grid item>
                    <CustomButton
                      variant="contained"
                      color="primary"
                      onClick={handleUploadReset}
                      startIcon={<RefreshOutlinedIcon />}
                    >
                      Reset
                    </CustomButton>
                    // </Grid>
                  )}
                </Box>
              </Grid>
              <Grid container lg={8}>
                {uploadFormData.length > 0 && (
                  <Grid item>
                    <ImageList gap={8}>
                      {uploadFormData.map((file: any, index: number) => (
                        <ImageListItem
                          key={index}
                          onClick={() => handleImageClick(index, "uploadedImg")}
                        >
                          <img
                            src={URL.createObjectURL(file)}
                            alt={`file-${index}`}
                            loading="lazy"
                            style={{ width: "100px", height: "100px" }}
                            width={100}
                            height={100}
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                    {/* </Box> */}
                  </Grid>
                )}
              </Grid>
              {isWebcamOpen && (
                // <div className="Container">
                <Grid
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {img === null && (
                    <>
                      <Webcam
                        audio={false}
                        mirrored={true}
                        height={400}
                        width={400}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                      />
                      <CustomButton
                        variant="contained"
                        color="primary"
                        onClick={capture}
                      >
                        Capture photo
                      </CustomButton>
                    </>
                  )}
                  {/* </div> */}
                </Grid>
              )}
              {uploadFormData.length > 0 && (
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  mt={1}
                >
                  <CustomButton
                    variant="contained"
                    color="primary"
                    onClick={handleSearchFace}
                    // loading={isLoading}
                  >
                    Search Faces
                  </CustomButton>
                </Grid>
              )}
            </>
          )}
        </Grid>
      )}

      {/* Gallery Modal */}
      <Modal open={isGalleryOpen} onClose={handleModalClose}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "430px",
            maxHeight: "550px",
            paddingBottom: "10px",
          }}
        >
          <ImageGallery
            items={galleryImages}
            startIndex={selectedImageIndex}
            showThumbnails={true}
            showFullscreenButton={false}
            showPlayButton={false}
            useBrowserFullscreen={false}
            // showBullets
            // onClose={handleModalClose}
          />
        </div>
      </Modal>
      <FeatureFrame
        frameTitle="Effortless Face Searching!"
        frameDescription="Why Choose AIFISE's Face Search Engine?"
        icon1={<ImageSearch />}
        icon2={<Scalable />}
        icon3={<Customize />}
        iconDescription1="Improved capabilities for finding images efficiently."
        iconDescription2="Adaptable services suitable for businesses of any size."
        iconDescription3="Tailored assistance designed to meet specific needs."
        iconTitle1="Advanced Image Search"
        iconTitle2="Scalable Solutions"
        iconTitle3="Customized Support"
      />
    </>
  );
};

export default FaceSearch;
