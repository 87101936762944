import React from 'react'

const Nsfw1 = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.9888 23.3075H41.846C41.5513 23.3075 41.3103 23.5486 41.3103 23.8432V27.0575C41.3103 27.3522 41.5513 27.5932 41.846 27.5932H58.9888C59.2835 27.5932 59.5246 27.3522 59.5246 27.0575V23.8432C59.5246 23.5486 59.2835 23.3075 58.9888 23.3075ZM50.0156 32.4147H41.846C41.5513 32.4147 41.3103 32.6557 41.3103 32.9504V36.1647C41.3103 36.4593 41.5513 36.7004 41.846 36.7004H50.0156C50.3103 36.7004 50.5513 36.4593 50.5513 36.1647V32.9504C50.5513 32.6557 50.3103 32.4147 50.0156 32.4147ZM27.6696 17.3142H24.7701C24.3549 17.3142 24.0201 17.649 24.0201 18.0642V34.6714C24.0201 34.9124 24.1339 35.1334 24.3281 35.274L34.2991 42.5464C34.6339 42.7874 35.1027 42.7205 35.3437 42.3856L37.0647 40.0352V40.0285C37.3058 39.6937 37.2321 39.2249 36.8973 38.9839L28.4129 32.8499V18.0642C28.4196 17.649 28.0781 17.3142 27.6696 17.3142Z"
        fill="black"
        fill-opacity="0.85"
      />
      <path
        d="M49.6027 40.8287H45.7322C45.3572 40.8287 45.0023 41.0229 44.8014 41.3444C43.9497 42.6945 42.9502 43.9455 41.8215 45.0743C39.8781 47.024 37.5742 48.5774 35.038 49.6479C32.4063 50.7595 29.6139 51.322 26.7344 51.322C23.8483 51.322 21.0558 50.7595 18.4308 49.6479C15.8929 48.5765 13.6094 47.0363 11.6474 45.0743C9.68532 43.1122 8.14514 40.8287 7.07371 38.2908C5.9621 35.6658 5.3996 32.8734 5.3996 29.9872C5.3996 27.1011 5.9621 24.3153 7.07371 21.6837C8.14514 19.1457 9.68532 16.8622 11.6474 14.9002C13.6094 12.9381 15.8929 11.3979 18.4308 10.3265C21.0558 9.2149 23.855 8.6524 26.7344 8.6524C29.6206 8.6524 32.413 9.2149 35.038 10.3265C37.5759 11.3979 39.8594 12.9381 41.8215 14.9002C42.9502 16.029 43.9497 17.28 44.8014 18.6301C45.0023 18.9515 45.3572 19.1457 45.7322 19.1457H49.6027C50.0648 19.1457 50.3594 18.6636 50.1518 18.2551C45.7858 9.56981 36.9331 3.92472 27.0492 3.81088C12.5782 3.63008 0.558081 15.4761 0.531296 29.9337C0.50451 44.418 12.2434 56.1703 26.7277 56.1703C36.7389 56.1703 45.7389 50.5051 50.1518 41.7194C50.1989 41.6257 50.2212 41.5215 50.2166 41.4167C50.2121 41.312 50.1808 41.2101 50.1258 41.1209C50.0707 41.0316 49.9938 40.9579 49.9022 40.9068C49.8107 40.8556 49.7076 40.8288 49.6027 40.8287Z"
        fill="black"
        fill-opacity="0.85"
      />
    </svg>
  );
}

export default Nsfw1
