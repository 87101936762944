import React from "react";

const VideoCameraIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="VideoCamera" clip-path="url(#clip0_2081_18411)">
        <path
          id="Vector"
          d="M28.3929 7.98716L24.1071 10.4548V5.36551C24.1071 4.18359 23.1462 3.22266 21.9643 3.22266H2.14286C0.960938 3.22266 0 4.18359 0 5.36551V24.6512C0 25.8331 0.960938 26.7941 2.14286 26.7941H21.9643C23.1462 26.7941 24.1071 25.8331 24.1071 24.6512V19.5619L28.3929 22.0296C29.106 22.4414 30 21.9258 30 21.1055V8.91462C30 8.09096 29.106 7.57533 28.3929 7.98716ZM21.6964 24.3834H2.41071V5.63337H21.6964V24.3834ZM27.5893 18.7919L24.1071 16.7896V13.2305L27.5893 11.2249V18.7919ZM4.82143 9.91909H8.57143C8.71875 9.91909 8.83929 9.79855 8.83929 9.65123V8.04409C8.83929 7.89676 8.71875 7.77623 8.57143 7.77623H4.82143C4.67411 7.77623 4.55357 7.89676 4.55357 8.04409V9.65123C4.55357 9.79855 4.67411 9.91909 4.82143 9.91909Z"
          fill="black"
          fill-opacity="0.85"
        />
      </g>
      <defs>
        <clipPath id="clip0_2081_18411">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VideoCameraIcon;
