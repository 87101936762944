import { Box, Typography, Grid } from "@mui/material";
import CertificateIcon from '../../../Icons/CertificateIcon'
import { SxProps, Theme } from '@mui/material/styles';

const DataSecurityCard = ({ sx = {} }: { sx?: SxProps<Theme>}) => {
    return (
      <Grid container justifyContent="center" mb={3}>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          xs={12}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
            // alignItems: "center",
            justifyContent: "center",
            columnGap: "0.5rem",
            background: "#E6F7FF",
            border: "1px solid #91D5FF",
            padding: "12px 24px",
            my: "3rem",
            borderRadius: "100px",
            ...sx,
          }}
        >
          <CertificateIcon />
          <Typography fontSize={14} fontWeight="fontWeightRegular">
            Data security comes first – We'll never sell or share your data. For more details, here’s our{" "}
            <a href="https://dev2.d1xoh43uq30kio.amplifyapp.com/privacy" target="blank">
              Privacy Policy
            </a>
          </Typography>
        </Grid>
      </Grid>
    );
}

export default DataSecurityCard