import { Button, Divider, Popover, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import UserIcon from "../../Icons/UserIcon";
import SettingsIcon from "../../Icons/SettingsIcon";
import LogoutIcon from "../../Icons/LogoutIcon";
import { useNavigate } from "react-router";

type TooltopProps = {
  children?: React.ReactNode;
  title?: string;
  style?: React.CSSProperties;
  textColor?: string;
};

const Tooltop = ({
  children,
  title,
  style = {
    display: "flex",
    justifyContent: "flex-end",
    // marginBottom: "20px",
    padding: "8px 12px",
    alignItems: "center",
    alignSelf: "stretch",
    gap: "8px",
    height: "fit-content",
  },
  textColor = "#FFF",
}: TooltopProps) => {
  const [name, setName] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const navigate = useNavigate();

  useEffect(() => {
    const name = localStorage.getItem("userLoginData")
      ? JSON.parse(localStorage.getItem("userLoginData") as string)
      : null;
    if (name) {
      setName(name.name);
    }
  }, []);

  const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <div className="header" style={style}>
        <img src="ProfileIcon.png" alt="profile-img" />{" "}
        <span
          style={{
            color: textColor ? textColor : "#FFF",
            //   fontFamily: "Avenir LT Std",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "6.8px",
            cursor: "pointer",
          }}
          onClick={handlePopoverClick}
        >
          {/* {title} */}
          <Typography variant="body2">{name ? name : title}</Typography>
        </span>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            width: "230px",
            height: "auto",
            borderRadius: "5px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Box
          sx={{
            width: "auto",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            // cursor: "pointer",
            padding: "5px",
          }}
        >
          <div>
            <Button
              variant="text"
              startIcon={<UserIcon />}
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "19.6px",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                padding: "10px 10px",
              }}
              onClick={() => {
                navigate("/profile");
              }}
            >
              <Typography variant="body2" sx={{textTransform:"none"}}>User Profile</Typography>
            </Button>
          </div>
          {localStorage.getItem("userLoginData") &&
            JSON.parse(localStorage.getItem("userLoginData") as string).role ===
              "adm" && (
              <div>
                <Button
                  variant="text"
                  sx={{
                    color: "#000",
                    fontFamily: "Open Sans",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19.6px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    padding: "10px 10px",
                  }}
                  startIcon={<SettingsIcon />}
                  onClick={() => {
                    navigate("/admin/user-management");
                  }}
                >
                  <Typography variant="body2" sx={{textTransform:"none"}}>Admin</Typography>
                </Button>
              </div>
            )}
          <Divider variant="fullWidth" sx={{
            paddingTop: "5px",
          }}/>
          <div>
            <Button
              variant="text"
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "19.6px",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                padding: "10px 10px",
                marginTop: "5px",
              }}
              startIcon={<LogoutIcon />}
              onClick={() => {
                // localStorage.getItem("isLoggedIn") &&
                //   localStorage.removeItem("isLoggedIn");
                // localStorage.getItem("userLoginData") &&
                //   localStorage.removeItem("userLoginData");
                // localStorage.getItem("token") &&
                //   localStorage.removeItem("token");  
                localStorage.clear();
                navigate("/login", { replace: true });
              }}
            >
              <Typography variant="body2" sx={{textTransform:"none"}}>Logout</Typography>
            </Button>
          </div>
        </Box>
      </Popover>
    </>
  );
};

export default Tooltop;
