import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import FileDragDrop from '../components/Common/FileDragDrop';
import Loader from '../components/Common/Loader/Loader';
import HeaderTop from '../components/pages/OCR/HeaderTop';
import JSONCard from '../components/Common/JSONCard';
import ImagePreviewCard from '../components/pages/OCR/ImagePreviewCard';
import { imageOcr } from '../service/apiActions/OcrAction';
import { OutletcontextType } from '../components/Layout/Index';
import BarChartIcon from '../Icons/BarChartIcon';
import ContentCard from '../components/Common/ContectCard';
import LanguageIcon from '../Icons/LanguageIcon';
import DataSecurityCard from '../components/Common/DataSecurityCard';
import Pdf from '../Icons/pdf';
import FeatureFrame from "../components/Common/FeatureFrame";


const ImageOCR = () => {
    const {
        handleChangeTitle,
        showSnackbar
    } = useOutletContext<OutletcontextType>();
    const [formdata, setFormdata] = useState<any>({});
    const [isLoading, setLoading] = useState(false);
    const [result, setResult] = useState<any>({})

    useEffect(() => {
        document.title = "AiFise | Image OCR";
        handleChangeTitle("OCR Indian Data", "Extract data from Aadhar, Driving License, Voter ID or PAN Card image.")
    }, [])

    const handleFileChange = (files: any, e: any) => {
        if (files.length > 0 && files[0].size > (1024 * 1024 * 3)) {
            showSnackbar("File size cannot be greater than 3 MB");
            return;
        } else if (files.length > 0 && files[0].type !== 'image/png' && files[0].type !== 'image/jpeg' && files[0].type !== 'image/jpg') {
            showSnackbar("Only png, jpg and jpeg are allowed");
            return;
        }
        setFormdata(files[0]);
        setLoading(true);
        let _formdata = new FormData();
        _formdata.append("image", files[0])
        imageOcr(_formdata).then((res) => {
            setLoading(false);
            setResult(res);
        }).catch((error) => {
            setLoading(false);
            showSnackbar("Something went wrong");
        })
    }

    const handleReset = () => {
        setResult({})
        setFormdata({})
    }

    if (isLoading) {
        return <Loader />
    }

    return (
      <>
        {Object.keys(result).length === 0 ? (
          <Grid container justifyContent="center">
            <Grid item lg={5} md={6} sm={6} xs={12}>
              <FileDragDrop handleChange={handleFileChange} />
            </Grid>
            <Grid item xs={12} mt={2}>
              <Typography variant="body2" color="text.secondary" align="center">
                <b>Note:</b> File size should be less than 3 MB and only png,
                jpg and jpeg are allowed.
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container justifyContent="center">
              <Grid item lg={8}>
                <HeaderTop
                  handleFileChange={(e: { target: { files: any } }) => {
                    setResult({});
                    handleFileChange(e.target.files, e);
                  }}
                  reset={handleReset}
                  enableUpload
                />
              </Grid>
            </Grid>
            <Box>
              <Grid container spacing={2} mt={0} justifyContent="center">
                <Grid item lg={4} md={5} sm={6} xs={12}>
                  <ImagePreviewCard
                    src={URL.createObjectURL(formdata)}
                    imageName={formdata?.name}
                  />
                </Grid>
                <Grid item lg={4} md={5} sm={6} xs={12}>
                  <JSONCard data={result} />
                </Grid>
              </Grid>
            </Box>
          </>
        )}

        {/* <Grid container justifyContent="center">
          <Grid item lg={10} md={12} xs={12}>
            <DataSecurityCard />
          </Grid>
          <Grid item xs={12}>
            <ContentCard>
              <ContentCard.Title sx={{ fontWeight: "700" }}>
                Why choose AIFISE's Image to Text Converter?
              </ContentCard.Title>
              <ContentCard.Text sx={{ fontWeight: "500", fontSize: "16px" }}>
                Here's why you should utilize our OCR tool to scan and extract
                text from your files
              </ContentCard.Text>
              <ContentCard.Text sx={{ fontWeight: "500", fontSize: "16px" }}>
                Incorporate Optical Character Recognition (OCR) capabilities
                into your applications using our APIs.
              </ContentCard.Text>
              <Box
                sx={{
                  background: "#BAE7FF",
                  borderRadius: "8px",
                  p: "36px",
                  mt: "16px",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <ContentCard.BoxCard
                      icons={<BarChartIcon />}
                      title="Result"
                      subTitle="Highly accurate results"
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <ContentCard.BoxCard
                      icons={<Pdf />}
                      title="Multiple imports"
                      subTitle="JPG, PNG, JPEG and more"
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <ContentCard.BoxCard
                      icons={<LanguageIcon />}
                      title="Languages"
                      subTitle="Multiple support"
                    />
                  </Grid>
                </Grid>
              </Box>
            </ContentCard>
          </Grid>
        </Grid> */}
        <FeatureFrame
          frameTitle="Why choose AIFISE's Image to Text Converter?"
          frameDescription="Here's why you should utilize our OCR tool to scan and extract text from your files"
          icon1={<BarChartIcon />}
          icon2={<Pdf />}
          icon3={<LanguageIcon />}
          iconDescription1="Highly accurate results"
          iconDescription2="JPG, PNG, JPEG and more"
          iconDescription3="Multiple support"
          iconTitle1="Secure Personal Info"
          iconTitle2="Scam Protection"
          iconTitle3="Languages"
        />
      </>
    );
}

export default ImageOCR