import React from 'react'

const LanguageIcon = ({ className=""}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width="60" height="60" viewBox="0 0 25 24" fill="none">
            <g clipPath="url(#clip0_1869_87416)">
                <path d="M13.37 15.071L10.83 12.561L10.86 12.531C12.6 10.591 13.84 8.36098 14.57 6.00098H17.5V4.00098H10.5V2.00098H8.5V4.00098H1.5V5.99098H12.67C12 7.92098 10.94 9.75098 9.5 11.351C8.57 10.321 7.8 9.19098 7.19 8.00098H5.19C5.92 9.63098 6.92 11.171 8.17 12.561L3.08 17.581L4.5 19.001L9.5 14.001L12.61 17.111L13.37 15.071ZM19 10.001H17L12.5 22.001H14.5L15.62 19.001H20.37L21.5 22.001H23.5L19 10.001ZM16.38 17.001L18 12.671L19.62 17.001H16.38Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_1869_87416">
                    <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default LanguageIcon