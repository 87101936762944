import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { OutletcontextType } from "../components/Layout/Index";
import { useOutletContext } from "react-router-dom";
import Breadcrumb from "../components/Common/Breadcrumb";

const APIDocumentation = () => {
  const [url, setUrl] = useState("");
  const params = useParams();
  // console.log(params, "API params");
  const { handleChangeTitle } = useOutletContext<OutletcontextType>();

  useEffect(() => {
    document.title = "AiFise | API Documentation";
    handleChangeTitle(
      "API Documentation",
      "See API Documentation of the Product"
    );
  }, []);

  useEffect(() => {
    const localProductData = JSON.parse(
      localStorage.getItem("prod_list") || "[]"
    );
    const productData = localProductData.filter(
      (item: any) => item.id === params.prodId
    );
    // console.log(productData, "productData");

    const swaggerUrl = productData[0]?.swagger_url;

    setUrl(swaggerUrl || getDefaultSwaggerUrl(productData[0]?.id));
  }, [params.prodId]);

  const getDefaultSwaggerUrl = (productId: string | undefined) => {
    const urlMapping: Record<string, string> = {
      "inno-fm": "https://petstore3.swagger.io/api/v3/openapi.json",
      "inno-ocr":
        "https://api.apis.guru/v2/specs/adobe.com/aem/3.7.1-pre.0/openapi.yaml",
      "inno-face-analysis":
        "https://api.apis.guru/v2/specs/instagram.com/1.0.0/swagger.yaml",
      "inno-face-liveliness":
        "https://api.apis.guru/v2/specs/instagram.com/1.0.0/swagger.yaml",
      "inno-aadhar-masking":
        "https://api.apis.guru/v2/specs/twitter.com/current/2.61/openapi.yaml",
      "inno-gist":
        "https://api.apis.guru/v2/specs/slack.com/1.7.0/openapi.yaml",
      "inno-ckyc-cropping":
        "https://api.apis.guru/v2/specs/spotify.com/1.0.0/openapi.yaml",
      "inno-video-kyc":
        "https://api.apis.guru/v2/specs/github.com/1.1.4/openapi.yaml",
      "inno-vid-verification":
        "https://api.apis.guru/v2/specs/googleapis.com/youtube/v3/openapi.yaml",
      "inno-spam-classifier":
        "https://api.apis.guru/v2/specs/googleapis.com/youtube/v3/openapi.yaml",
      "inno-moderation":
        "https://api.apis.guru/v2/specs/googleapis.com/youtube/v3/openapi.yaml",
      "inno-face-search":
        "https://api.apis.guru/v2/specs/googleapis.com/youtube/v3/openapi.yaml",
      "inno-address-match":
        "https://api.apis.guru/v2/specs/googleapis.com/youtube/v3/openapi.yaml",
    };

    return urlMapping[productId || ""] || "";
  };
  return (
    <div className="App">
      <Breadcrumb />
      <SwaggerUI url={url} />
    </div>
  );
};

export default APIDocumentation;
