import { Outlet } from "react-router-dom";
import React from "react";
import { VariantType, useSnackbar } from "notistack";
import PageLayout from "./PageLayout";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
interface AdminLayoutProps {
  window?: Window;
}

export interface OutletcontextType {
  handleChangeTitle: (title: string, subTitle: string) => void;
  showSnackbar: (message: string, type?: VariantType) => void;
}

const AdminLayout = ({ window }: AdminLayoutProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [headerText, setHeaderText] = React.useState({
    title: "",
    subTitle: "",
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleChangeTitle = (title: string, subTitle: string) => {
    setHeaderText({
      title,
      subTitle,
    });
  };

  const showSnackbar = (message: string, type: VariantType = "error") => {
    enqueueSnackbar(message, {
      variant: type,
      action: (key) => <CloseIcon onClick={() => closeSnackbar(key)} />,
    });
  };

  const drawerWidth = 260;
  return (
    <PageLayout title={headerText.title} subTitle={headerText.subTitle}>
      <Outlet context={{ handleChangeTitle, showSnackbar }} />
    </PageLayout>
  );
  // eslint-disable-next-line no-unreachable
 <style>
   {`
    @media (min-width: 1536px) {
      .css-vi59no {
       width: "100%"
      }
    }
  `}
 </style>;
};

export default AdminLayout;
