import React from 'react'

const CertificateIcon = ({ className = "" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M16.9316 3.31836L10.2949 1.05664C10.2148 1.0293 10.1074 1.01562 10 1.01562C9.89258 1.01562 9.78516 1.0293 9.70508 1.05664L3.06836 3.31836C2.90625 3.37305 2.77344 3.56055 2.77344 3.73242V13.1543C2.77344 13.3262 2.88477 13.5527 3.01953 13.6602L9.75195 18.9062C9.82031 18.959 9.9082 18.9863 9.99805 18.9863C10.0879 18.9863 10.1777 18.959 10.2441 18.9062L16.9766 13.6602C17.1113 13.5547 17.2227 13.3281 17.2227 13.1543V3.73242C17.2266 3.56055 17.0938 3.375 16.9316 3.31836ZM13.5645 6.6543L9.41211 12.3711C9.38292 12.411 9.34471 12.4436 9.30059 12.466C9.25647 12.4884 9.20769 12.5 9.1582 12.5C9.10872 12.5 9.05994 12.4884 9.01582 12.466C8.9717 12.4436 8.93349 12.411 8.9043 12.3711L6.43555 8.97266C6.36133 8.86914 6.43555 8.72461 6.5625 8.72461H7.64063C7.74023 8.72461 7.83594 8.77344 7.89453 8.85351L9.1582 10.5918L12.1055 6.5332C12.1641 6.45312 12.2578 6.4043 12.3594 6.4043H13.4375C13.5645 6.40625 13.6387 6.55078 13.5645 6.6543Z" fill="#007AFE" />
        </svg>
    )
}

export default CertificateIcon