import React from 'react'

const AdharIcon2 = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4259_18732)">
        <path
          d="M56.5134 38.5714H49.5491V28.3929C49.5491 28.0982 49.308 27.8571 49.0134 27.8571H32.4063V21.4286H39.6384C40.2277 21.4286 40.7098 20.9464 40.7098 20.3571V1.07143C40.7098 0.482143 40.2277 0 39.6384 0H20.3527C19.7634 0 19.2813 0.482143 19.2813 1.07143V20.3571C19.2813 20.9464 19.7634 21.4286 20.3527 21.4286H27.5848V27.8571H10.9777C10.683 27.8571 10.442 28.0982 10.442 28.3929V38.5714H3.47768C2.88839 38.5714 2.40625 39.0536 2.40625 39.6429V58.9286C2.40625 59.5179 2.88839 60 3.47768 60H22.7634C23.3527 60 23.8348 59.5179 23.8348 58.9286V39.6429C23.8348 39.0536 23.3527 38.5714 22.7634 38.5714H15.2634V32.6786H44.7277V38.5714H37.2277C36.6384 38.5714 36.1563 39.0536 36.1563 39.6429V58.9286C36.1563 59.5179 36.6384 60 37.2277 60H56.5134C57.1027 60 57.5848 59.5179 57.5848 58.9286V39.6429C57.5848 39.0536 57.1027 38.5714 56.5134 38.5714ZM18.7455 43.6607V54.9107H7.49554V43.6607H18.7455ZM24.3705 16.3393V5.08929H35.6205V16.3393H24.3705ZM52.4955 54.9107H41.2455V43.6607H52.4955V54.9107Z"
          fill="black"
          fill-opacity="0.85"
        />
      </g>
      <defs>
        <clipPath id="clip0_4259_18732">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AdharIcon2
