import React from 'react'

const Nsfw2 = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.6284 5C26.0815 5.00002 26.5193 5.16411 26.8608 5.46192C27.2022 5.75973 27.4243 6.17112 27.4859 6.62L27.5034 6.875V18.86C30.9755 19.2798 34.2065 20.8518 36.6797 23.3246C39.1529 25.7974 40.7255 29.028 41.1459 32.5H53.1309C53.606 32.5001 54.0633 32.6806 54.4104 33.0049C54.7575 33.3293 54.9686 33.7732 55.001 34.2472C55.0334 34.7212 54.8847 35.1897 54.5849 35.5582C54.2851 35.9268 53.8566 36.1677 53.3859 36.2325L53.1309 36.25H41.1459C40.7255 39.722 39.1529 42.9526 36.6797 45.4254C34.2065 47.8982 30.9755 49.4702 27.5034 49.89V53.1275C27.5083 53.6059 27.3301 54.0681 27.0053 54.4195C26.6804 54.7708 26.2336 54.9846 25.7563 55.0172C25.2789 55.0498 24.8071 54.8987 24.4376 54.5948C24.068 54.2909 23.8286 53.8572 23.7684 53.3825L23.7534 53.125V49.8875C20.2831 49.4668 17.0538 47.8951 14.5817 45.4234C12.1096 42.9518 10.5372 39.7228 10.1159 36.2525H6.87845C6.4 36.2574 5.9378 36.0791 5.58649 35.7543C5.23517 35.4295 5.02132 34.9827 4.98873 34.5053C4.95614 34.028 5.10728 33.5562 5.41118 33.1866C5.71509 32.8171 6.14878 32.5777 6.62345 32.5175L6.87845 32.5H10.1134C10.5338 29.0284 12.106 25.798 14.5787 23.3253C17.0515 20.8526 20.2819 19.2804 23.7534 18.86V6.875C23.7534 6.37772 23.951 5.90081 24.3026 5.54917C24.6543 5.19754 25.1312 5 25.6284 5ZM23.7534 36.25H13.9009C14.2967 38.7207 15.4622 41.0035 17.2313 42.773C19.0004 44.5425 21.2828 45.7086 23.7534 46.105V36.25ZM37.3584 36.25H27.5034V46.1025C29.9733 45.7064 32.2553 44.5411 34.0243 42.7725C35.7933 41.0039 36.9592 38.7223 37.3559 36.2525M23.7534 22.6475C21.2837 23.0442 19.002 24.2101 17.2335 25.9792C15.4649 27.7482 14.2996 30.0301 13.9034 32.5H23.7534V22.6475ZM27.5034 22.6475V32.5H37.3584C36.9769 30.1217 35.8819 27.9155 34.2184 26.1735C32.555 24.4314 30.4016 23.2358 28.0434 22.745L27.5034 22.6475Z"
        fill="black"
      />
    </svg>
  );
}

export default Nsfw2
