import React from 'react'

const UpdatePassword = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_3629_8332)">
        <path
          d="M9.71483 0.859375C6.71661 0.859375 4.28625 3.28973 4.28625 6.28795C4.28625 7.5433 4.71304 8.69866 5.42733 9.6183L4.6934 10.3522L3.5809 9.23973C3.5537 9.21312 3.51716 9.19822 3.47911 9.19822C3.44106 9.19822 3.40452 9.21312 3.37733 9.23973L2.66661 9.95045C2.64 9.97764 2.6251 10.0142 2.6251 10.0522C2.6251 10.0903 2.64 10.1268 2.66661 10.154L3.77911 11.2665L2.97733 12.0683L1.86483 10.9558C1.83763 10.9292 1.80109 10.9143 1.76304 10.9143C1.72499 10.9143 1.68845 10.9292 1.66125 10.9558L0.95054 11.6665C0.923928 11.6937 0.909025 11.7303 0.909025 11.7683C0.909025 11.8064 0.923928 11.8429 0.95054 11.8701L2.06304 12.9826L0.896969 14.1487C0.870383 14.1755 0.855469 14.2118 0.855469 14.2496C0.855469 14.2873 0.870383 14.3236 0.896969 14.3504L1.65233 15.1058C1.70768 15.1612 1.79875 15.1612 1.85411 15.1058L6.38268 10.5772C7.33598 11.3166 8.50839 11.7175 9.71483 11.7165C12.713 11.7165 15.1434 9.28616 15.1434 6.28795C15.1434 3.28973 12.713 0.859375 9.71483 0.859375ZM12.5934 9.16652C11.8255 9.93616 10.8023 10.3594 9.71483 10.3594C8.62733 10.3594 7.60411 9.93616 6.83626 9.16652C6.06661 8.39866 5.6434 7.37545 5.6434 6.28795C5.6434 5.20045 6.06661 4.17723 6.83626 3.40938C7.60411 2.63973 8.62733 2.21652 9.71483 2.21652C10.8023 2.21652 11.8255 2.63973 12.5934 3.40938C13.363 4.17723 13.7863 5.20045 13.7863 6.28795C13.7863 7.37545 13.363 8.39866 12.5934 9.16652Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3629_8332">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default UpdatePassword
