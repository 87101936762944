import React from 'react'

const CopyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_140_8627)">
                <path d="M20.5708 0H6.21368C6.09582 0 5.99939 0.0964286 5.99939 0.214286V1.71429C5.99939 1.83214 6.09582 1.92857 6.21368 1.92857H19.4994V20.3571C19.4994 20.475 19.5958 20.5714 19.7137 20.5714H21.2137C21.3315 20.5714 21.428 20.475 21.428 20.3571V0.857143C21.428 0.383036 21.0449 0 20.5708 0ZM17.1422 3.42857H3.42796C2.95385 3.42857 2.57082 3.81161 2.57082 4.28571V18.5009C2.57082 18.7286 2.66189 18.9455 2.8226 19.1063L7.46457 23.7482C7.5235 23.8071 7.59046 23.8554 7.66278 23.8955V23.9464H7.77528C7.86903 23.9813 7.96814 24 8.06993 24H17.1422C17.6164 24 17.9994 23.617 17.9994 23.1429V4.28571C17.9994 3.81161 17.6164 3.42857 17.1422 3.42857ZM7.6601 21.2196L5.35385 18.9107H7.6601V21.2196ZM16.0708 22.0714H9.37439V18.2679C9.37439 17.6759 8.89493 17.1964 8.30296 17.1964H4.49939V5.35714H16.0708V22.0714Z" className='copy-text' fillOpacity="0.85" />
            </g>
            <defs>
                <clipPath id="clip0_140_8627">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CopyIcon