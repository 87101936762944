import React from 'react'

const CloudUpload = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 30 30"
      fill="none"
    >
      <g clip-path="url(#clip0_2098_27240)">
        <path
          d="M15.2111 13.2278C15.1861 13.1958 15.1541 13.1699 15.1175 13.1521C15.081 13.1343 15.0408 13.125 15.0002 13.125C14.9595 13.125 14.9194 13.1343 14.8829 13.1521C14.8463 13.1699 14.8143 13.1958 14.7892 13.2278L11.0392 17.9722C11.0083 18.0117 10.9892 18.0591 10.9839 18.1089C10.9786 18.1588 10.9875 18.2091 11.0095 18.2542C11.0315 18.2992 11.0658 18.3372 11.1083 18.3637C11.1509 18.3902 11.2 18.4042 11.2502 18.4041H13.7245V26.5202C13.7245 26.6675 13.8451 26.788 13.9924 26.788H16.0013C16.1486 26.788 16.2692 26.6675 16.2692 26.5202V18.4075H18.7502C18.9745 18.4075 19.0984 18.1496 18.9611 17.9755L15.2111 13.2278Z"
          fill="black"
          fill-opacity="0.85"
        />
        <path
          d="M25.0246 10.1288C23.4911 6.08412 19.5837 3.20801 15.0067 3.20801C10.4297 3.20801 6.52232 6.08078 4.98884 10.1254C2.11942 10.8788 0 13.4937 0 16.6009C0 20.3006 2.99665 23.2973 6.69308 23.2973H8.03571C8.18304 23.2973 8.30357 23.1768 8.30357 23.0294V21.0205C8.30357 20.8732 8.18304 20.7527 8.03571 20.7527H6.69308C5.56473 20.7527 4.50335 20.304 3.71317 19.4904C2.92634 18.6801 2.50781 17.5886 2.54464 16.4569C2.57478 15.573 2.87612 14.7426 3.42187 14.0428C3.98103 13.3297 4.76451 12.8107 5.63504 12.5797L6.90402 12.2482L7.36942 11.0227C7.65737 10.2593 8.05915 9.54618 8.56473 8.89997C9.06386 8.25948 9.65509 7.69646 10.3192 7.22921C11.6953 6.26158 13.3158 5.7493 15.0067 5.7493C16.6975 5.7493 18.3181 6.26158 19.6942 7.22921C20.3605 7.69796 20.9498 8.26046 21.4487 8.89997C21.9542 9.54618 22.356 10.2627 22.644 11.0227L23.106 12.2448L24.3717 12.5797C26.1864 13.0685 27.4554 14.7192 27.4554 16.6009C27.4554 17.7091 27.0234 18.7538 26.24 19.5373C25.8557 19.9237 25.3986 20.2301 24.8952 20.4388C24.3917 20.6474 23.8519 20.7541 23.3069 20.7527H21.9643C21.817 20.7527 21.6964 20.8732 21.6964 21.0205V23.0294C21.6964 23.1768 21.817 23.2973 21.9643 23.2973H23.3069C27.0033 23.2973 30 20.3006 30 16.6009C30 13.4971 27.8873 10.8855 25.0246 10.1288Z"
          fill="black"
          fill-opacity="0.85"
        />
      </g>
      <defs>
        <clipPath id="clip0_2098_27240">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CloudUpload
