import { Box, Typography } from '@mui/material'
import loader from '../../../Icons/LoaderGIF/loader.gif'
import logo from '../../../Icons/LoaderGIF/aifiselogo.png'
import './loader.css'

const Loader = () => {
    
    return (
        <>
        <Box
            sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        gap: 1.5,
                        paddingTop: "60px",
                    }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <div style={{
                            position: "relative",
                            textAlign: "center"
                        }}>
                        <img src={loader} alt="loader" style={{
                            width: "99px",
                            height: "99px",
                        }}/> 
                        <img src={logo} alt="logo" className='logo'/>
                        </div>
                    </div>
            <Typography
                sx={{
                        color: "rgba(0, 0, 0, 0.85)",
                        textAlign: "center",
                        // fontFamily: "Gotham",
                        fontSize: "24px",
                        fontStyle: "normal",
                        fontWeight: 325,
                        lineHeight: "140%",
                }}
            >Prepare for an extraordinary journey as the AI awakens!
            </Typography>
        </Box>
        </>
        // <Box
        //     sx={{
        //         display: "flex",
        //         flexDirection: "column",
        //         justifyContent: "center",
        //         alignItems: "center",
        //         height: "100%",
        //         gap: 1.5,
        //         paddingTop: "60px",
        //     }}
        // >
        //     <Box
        //         sx={{
        //             display: "flex",
        //             gap: 1.1,
        //             "& .dot": {
        //                 background: "#007AFE",
        //                 display: "block",
        //                 height: 12,
        //                 width: 12,
        //                 borderRadius: 8,
        //                 animation: 'dotEffect 1100ms linear infinite'
        //             },
        //             "& .dot:nth-of-type(1)": {
        //                 animationDelay: "0ms"
        //             },
        //             "& .dot:nth-of-type(2)": {
        //                 animationDelay: "200ms"
        //             },
        //             "& .dot:nth-of-type(3)": {
        //                 animationDelay: "400ms"
        //             },
        //             "& .dot:nth-of-type(4)": {
        //                 animationDelay: "600ms"
        //             },
        //             // "& .dot:nth-child(5)": {
        //             //     animationDelay: "600ms"
        //             // },
        //             "@keyframes dotEffect":{
        //                 "0%, 60%, 100%": {
        //                     transform: "translateY(0px)"
        //                 },
        //                 "30%": {
        //                     transform: "translateY(-18px)"
        //                 }
        //             }
        //         }}
        //     >
        //         <span className="dot" />
        //         <span className="dot" />
        //         <span className="dot" />
        //         <span className="dot" />
        //         {/* <span className="dot" /> */}
        //     </Box>
        //     <Typography
        //         sx={{
        //             fontSize: "14px",
        //             fontStyle: "normal",
        //             fontWeight: 400,
        //         }}
        //     >Processing...</Typography>
        // </Box>
    )
}

export default Loader