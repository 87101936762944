import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Loader from "../components/Common/Loader/Loader";
import { OutletcontextType } from "../components/Layout/Index";
import FeatureFrame from "../components/Common/FeatureFrame";
import LiveIcon from "../Icons/LiveIcon";
import VideoCameraIcon from "../Icons/VideoCameraIcon";
import MapIcon from "../Icons/MapIcon";
import CustomButton from "../components/Common/CustomButton";
import ArrowLeftIcon from "../Icons/ArrowLeftIcon";
import Ckyc1 from "../Icons/Ckyc1";
import Ckyc2 from "../Icons/Ckyc2";
import Ckyc3 from "../Icons/Ckyc3";

const VideoKyc = () => {
  const { handleChangeTitle } = useOutletContext<OutletcontextType>();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "AiFise |  CKYC";
    handleChangeTitle(
      "CKYC",
      "Make Customer Onboarding a Comfortable Experience in Their Homes."
    );
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Grid container justifyContent="center" pt={3}>
        <Grid item lg={10} md={6} sm={6} xs={12}>
          <Typography
            sx={{
              color: "rgba(0, 0, 0, 0.70)",
              textAlign: "center",
              // fontFamily: "Avenir LT Std",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "24px",
            }}
          >
            Deliver unparalleled trust and convenience when customers sign up
            for your services
          </Typography>
        </Grid>
      </Grid>

      <div style={{ paddingTop: "24px" }}>
        <CustomButton
          onClick={() => {
            window.open(" https://main.dgsbgrcxqrjjv.amplifyapp.com/");
          }}
          sx={{
            display: "flex",
            padding: "10px 10px 10px 26px",
            alignItems: "center",
            gap: "16px",
            borderRadius: "200px",
            border: "1px solid #0D93F0",
            background: "#0D93F0",
            margin: "auto",
          }}
        >
          <div
            style={{
              color: "#FFF",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "350",
              lineHeight: "28px",
            }}
          >
            See it in Action
          </div>
          <div
            style={{
              display: "flex",
              padding: "10px",
              alignItems: "center",
              gap: "10px",
              borderRadius: "200px",
              background: "#FFFF",
            }}
          >
            <ArrowLeftIcon />
          </div>
        </CustomButton>
      </div>

      <FeatureFrame
        frameTitle="Compliance Excellence"
        frameDescription="Unveiling Vital CKYC Benefits"
        icon1={<Ckyc1 />}
        icon2={<Ckyc2 />}
        icon3={<Ckyc3 />}
        iconDescription1="Effortlessly thwart fraud by consolidating customer information into a centralized system."
        iconDescription2="Simplify KYC hassle by reducing information submissions—streamlined, secure, and user-friendly."
        iconDescription3="Assure quick access to verified KYC documents, cutting through the delays of traditional authentication."
        iconTitle1="Centralized System"
        iconTitle2="Streamlined KYC"
        iconTitle3="Quick Access"
      />
      <style>{`.css-3ef1jy-MuiGrid-root{  padding-top: 0px; }`}</style>
    </>
  );
};

export default VideoKyc;
