import React from "react";

const MapIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path
          id="Vector"
          d="M15 29.6599L7.04501 21.7049C5.47168 20.1316 4.40023 18.127 3.96616 15.9447C3.53209 13.7624 3.75488 11.5004 4.60637 9.44478C5.45786 7.38912 6.8998 5.63212 8.74985 4.39596C10.5999 3.1598 12.775 2.5 15 2.5C17.225 2.5 19.4001 3.1598 21.2502 4.39596C23.1002 5.63212 24.5421 7.38912 25.3936 9.44478C26.2451 11.5004 26.4679 13.7624 26.0338 15.9447C25.5998 18.127 24.5283 20.1316 22.955 21.7049L15 29.6599ZM21.1875 19.9374C22.4111 18.7137 23.2444 17.1546 23.5819 15.4572C23.9195 13.7599 23.7462 12.0006 23.0839 10.4018C22.4216 8.80305 21.3001 7.43654 19.8612 6.47511C18.4222 5.51369 16.7306 5.00053 15 5.00053C13.2695 5.00053 11.5778 5.51369 10.1389 6.47511C8.69995 7.43654 7.57844 8.80305 6.91614 10.4018C6.25385 12.0006 6.08052 13.7599 6.41807 15.4572C6.75562 17.1546 7.58888 18.7137 8.8125 19.9374L15 26.1249L21.1875 19.9374ZM15 16.2499C14.337 16.2499 13.7011 15.9865 13.2322 15.5177C12.7634 15.0488 12.5 14.4129 12.5 13.7499C12.5 13.0869 12.7634 12.451 13.2322 11.9821C13.7011 11.5133 14.337 11.2499 15 11.2499C15.663 11.2499 16.2989 11.5133 16.7678 11.9821C17.2366 12.451 17.5 13.0869 17.5 13.7499C17.5 14.4129 17.2366 15.0488 16.7678 15.5177C16.2989 15.9865 15.663 16.2499 15 16.2499Z"
          fill="black"
          fill-opacity="0.85"
        />
      </g>
    </svg>
  );
};

export default MapIcon;
