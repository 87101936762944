import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import DataSecurityCard from "./DataSecurityCard";

type FeatureFrameProps = {
  frameTitle?: string;
  frameDescription?: string;
  icon1?: React.ReactNode;
  icon2?: React.ReactNode;
  icon3?: React.ReactNode;
  iconDescription1: string;
  iconDescription2: string;
  iconDescription3?: string;
  iconTitle1?: string;
  iconTitle2?: string;
  iconTitle3?: string;
};

const FeatureFrame = ({
  frameTitle,
  frameDescription,
  icon1,
  icon2,
  icon3,
  iconDescription1,
  iconDescription2,
  iconDescription3,
  iconTitle1,
  iconTitle2,
  iconTitle3,
}: FeatureFrameProps) => {
  const frameTitleStyle = {
    color: "#141414",
    textAlign: "center",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "fontWeightBold",
    lineHeight: "28.8px",
    maxWidth: "602px",
    marginBottom:
     "8px",
  };

  const frameDescStyle = {
    color: "rgba(0, 0, 0, 0.70)",
    textAlign: "center",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "fontWeightRegular",
    lineHeight: "19.2px",
    // padding: "10px 0px",
  };

  const iconDescStyle = {
    color: "rgba(0, 0, 0, 0.85)",
    textAlign: "center",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "fontWeightRegular",
    lineHeight: "19.2px",
    // height: "calc(100% - 140px)",
    marginTop: "12px",
    marginBottom: "10px",
  };

  const iconTitleStyle = {
    color: "#141414",
    textAlign: "center",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "28.8px",
    minHeight: "57px",
    marginTop: "auto",
    // marginTop: "8px",
  };

  return (
    <>
      <Grid container justifyContent="center" mb={3}>
        <Grid item xl={11} lg={12} md={12} xs={12}>
          <DataSecurityCard />  
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <Box
            display="flex"
            // justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Typography sx={frameTitleStyle}>{frameTitle}</Typography>
            <Typography sx={frameDescStyle}>{frameDescription}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              mt: "8px",
            }}
            pt="24px"
          >
            <Grid
              container
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item lg={4} md={4} sm={6} xs={12} height={"auto"}>
                <Box
                  sx={{
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                    alignContent: "space-between",
                    flexDirection: "column",
                    padding: { md: "15px 15px 15px 15px", sm: "10px 0px" },
                    height: "100%",
                  }}
                >
                  {icon1}
                  <Typography sx={iconDescStyle}>{iconDescription1}</Typography>
                  <Typography sx={iconTitleStyle}>{iconTitle1}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12} height={"auto"}>
                <Box
                  sx={{
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    alignContent: "space-between",
                    padding: { md: "15px 15px 15px 15px", sm: "10px 0px" },
                    height: "100%",

                    position: "relative",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      left: 0,
                      width: "1px",

                      background:
                        "linear-gradient(  108deg,rgba(56, 189, 248, 0) 0%,#0ea5e9 50%,rgba(236, 72, 153, 0) 100% );",
                    },
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      right: 0,
                      width: "1px",

                      background:
                        "linear-gradient(  108deg,rgba(56, 189, 248, 0) 0%,#0ea5e9 50%,rgba(236, 72, 153, 0) 100% );",
                    },
                  }}
                >
                  {icon2}
                  <Typography sx={iconDescStyle}>{iconDescription2}</Typography>
                  <Typography sx={iconTitleStyle}>{iconTitle2}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12} height={"auto"}>
                <Box
                  sx={{
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    alignContent: "space-between",
                    height: "100%",

                    padding: { md: "15px 15px 15px 15px", sm: "10px 0px" },
                  }}
                >
                  {icon3}
                  <Typography sx={iconDescStyle}>{iconDescription3}</Typography>
                  <Typography sx={iconTitleStyle}>{iconTitle3}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <style>{``}</style>
    </>
  );
};

export default FeatureFrame;
