import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Drawer,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import LogoIcon from "../../Icons/LogoIcon";
import { NavLink, useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import ProfileBox from "../Common/ProfileBox";

interface AdminSidebarProps {
  drawerWidth: number;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  window?: () => Window;
  menuList?: any;
}

// const sidebar = [
//   {
//     title: "User Management",
//     externalUrl: false,
//     to: "/admin/user-management",
//     children: [],
//   },
//   {
//     title: "Enquiries",
//     externalUrl: false,
//     to: "/admin/enquiries",
//     children: [],
//   },
// ];

const AdminSidebar = ({
  drawerWidth,
  handleDrawerToggle,
  mobileOpen,
  window,
  menuList = [],
}: AdminSidebarProps) => {
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box
        component="nav"
        sx={{
          width: { md: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="AiFise-sidebar"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "#F2F1F6",
              // borderRight: "0.5px solid #9F9FA1",
              padding: "14px",
              border: "2px solid red",
            },
          }}
        >
          <DrawerList menuList={menuList} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "#F2F1F6",
              // borderRight: "0.5px solid #9F9FA1",
              // padding: "14px",
            },
          }}
          open
        >
          <DrawerList menuList={menuList} />
        </Drawer>
      </Box>
    </>
  );
};

const DrawerList = ({ menuList = [] }) => {
  const navigate = useNavigate();

  return (
    <>
      <div style={{ margin: "14px" }}>
        <LogoIcon sx={{ marginBottom: "16px" }} />

        <Button
          startIcon={<ArrowBack />}
          sx={{
            borderRadius: "2px",
            border: "1px solid  #D9D9D9",
            background: "#FFF ",
            color: "black",
            fontWeight: 500,
            // boxShadow: " 0px 2px 0px 0px rgba(0, 0, 0, 0.02)",
            textTransform: "none",
            width: "100%",
          }}
          onClick={() => navigate("/")}
        >
          Products
        </Button>
      </div>
      <Divider />

      <List
        sx={{
          my: "14px",
          height: "calc(100vh - 50px)",
          overflowY: "auto",
          marginLeft: "14px",
          marginRight: "14px",
          paddingTop: "0px",
        }}
      >
        {menuList.map((item: any, index: any) => (
          <SingleLevel item={item} key={index} />
        ))}
      </List>
      <Divider />
      <Box sx={{ padding: "0px 14px 14px 14px" }}>
        <ProfileBox />
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: "12px",
            color: "#8C8C8C",
            lineHeight: "16.8px",
            fontWeight: 400,
            fontStyle: "normal",
            paddingLeft: "8px",
          }}
        >
          Dashboard Version 1.1.0
        </Typography>
      </Box>
    </>
  );
};

const SingleLevel = ({
  item,
  pl = "10px",
  activeBG = "#007AFE",
  activeColor = "#fff",
}: {
  item: any;
  activeBG?: string;
  pl?: string;
  activeColor?: string;
}) => {
  let listItemProps = { component: NavLink, to: item.to };

  return (
    <ListItem
      key={item.title}
      disablePadding
      {...listItemProps}
      sx={{
        color: "#000",
        "&.active": {
          background: activeBG,
          color: activeColor,
          borderRadius: "2px",
          boxShadow:
            "0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 0px 3px 0px rgba(0, 0, 0, 0.10), 0px 0px 6px 0px rgba(0, 0, 0, 0.09), 0px 0px 8px 0px rgba(0, 0, 0, 0.05), 0px 0px 9px 0px rgba(0, 0, 0, 0.01), 0px 0px 10px 0px rgba(0, 0, 0, 0.00)",
        },
      }}
    >
      <ListItemButton
        sx={{
          padding: "13px 10px",
          pl: pl,
          width: "100%",
        }}
      >
        <ListItemText
          primary={item.title}
          sx={{
            margin: "0",
            textTransform: "capitalize",
            "&.MuiListItemText-primary": {
              fontSize: "7px",
              fontWeight: "fontWeightMedium",
              fontStyle: "normal",
              lineHeight: "1",
            },
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default AdminSidebar;
