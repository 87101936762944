import React from 'react'

const Gist2 = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.775 19.275L27.5 13.525V37.5C27.5 38.163 27.7634 38.7989 28.2322 39.2678C28.7011 39.7366 29.337 40 30 40C30.663 40 31.2989 39.7366 31.7678 39.2678C32.2366 38.7989 32.5 38.163 32.5 37.5V13.525L38.225 19.275C38.4574 19.5093 38.7339 19.6953 39.0386 19.8222C39.3432 19.9491 39.67 20.0145 40 20.0145C40.33 20.0145 40.6568 19.9491 40.9614 19.8222C41.2661 19.6953 41.5426 19.5093 41.775 19.275C42.0093 19.0426 42.1953 18.7661 42.3222 18.4614C42.4492 18.1568 42.5145 17.83 42.5145 17.5C42.5145 17.17 42.4492 16.8432 42.3222 16.5385C42.1953 16.2339 42.0093 15.9574 41.775 15.725L31.775 5.72499C31.5372 5.49738 31.2569 5.31897 30.95 5.19998C30.3413 4.94994 29.6587 4.94994 29.05 5.19998C28.7431 5.31897 28.4628 5.49738 28.225 5.72499L18.225 15.725C17.9919 15.9581 17.807 16.2348 17.6809 16.5394C17.5547 16.8439 17.4898 17.1703 17.4898 17.5C17.4898 17.8296 17.5547 18.1561 17.6809 18.4606C17.807 18.7652 17.9919 19.0419 18.225 19.275C18.4581 19.5081 18.7348 19.693 19.0394 19.8191C19.3439 19.9453 19.6704 20.0102 20 20.0102C20.3296 20.0102 20.6561 19.9453 20.9606 19.8191C21.2652 19.693 21.5419 19.5081 21.775 19.275ZM52.5 30C51.837 30 51.2011 30.2634 50.7322 30.7322C50.2634 31.2011 50 31.8369 50 32.5V47.5C50 48.163 49.7366 48.7989 49.2678 49.2678C48.7989 49.7366 48.163 50 47.5 50H12.5C11.837 50 11.2011 49.7366 10.7322 49.2678C10.2634 48.7989 10 48.163 10 47.5V32.5C10 31.8369 9.73661 31.2011 9.26777 30.7322C8.79893 30.2634 8.16304 30 7.5 30C6.83696 30 6.20107 30.2634 5.73223 30.7322C5.26339 31.2011 5 31.8369 5 32.5V47.5C5 49.4891 5.79018 51.3968 7.1967 52.8033C8.60322 54.2098 10.5109 55 12.5 55H47.5C49.4891 55 51.3968 54.2098 52.8033 52.8033C54.2098 51.3968 55 49.4891 55 47.5V32.5C55 31.8369 54.7366 31.2011 54.2678 30.7322C53.7989 30.2634 53.163 30 52.5 30Z"
        fill="black"
        fill-opacity="0.85"
      />
    </svg>
  );
}

export default Gist2
