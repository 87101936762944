import React from "react";
import { useParams, useNavigate } from "react-router";
import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const EnquiryDetails = () => {
  const { id } = useParams();
  const [enquiryDetails, setEnquiryDetails] = useState<any>({});

  useEffect(() => {
    const storedEnquiries = JSON.parse(
      localStorage.getItem("enquiries") as string
    );
    const details = storedEnquiries.find((enquiry: any) => enquiry.id === id);

    if (details) {
      setEnquiryDetails(details);
    }
  }, [id]);
  console.log(enquiryDetails);
  let navigate = useNavigate();

  return (
    <div>
      <Button
        sx={{
          color: "#000000D9",
          display: "inline-flex",
          alignItems: "center",
          borderRadius: "2px",
          border: "1px solid #D9D9D9",
          background: "#FFFFFF",
          padding: "7px 15px",
          // marginTop: 5,
          marginLeft: "0px",
          fontWeight: "500",
          textTransform: "none",
        }}
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          margin: "24px 0px",
          marginLeft: "0px",
        }}
      >
        <div
          style={{
            color: "#000000A6",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
          }}
        >
          Name{" "}
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.85)",
            fontSize: "17px",
            marginTop: 3,
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          }}
        >
          {enquiryDetails?.name}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          margin: 0,
          maxWidth: "25%",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginLeft: "0px",
          }}
        >
          <div
            style={{
              color: "#000000A6",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            Company Name{" "}
          </div>
          <div
            style={{
              color: "#000000D9",
              fontSize: "17px",
              marginTop: 3,
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
            }}
          >
            {enquiryDetails?.company}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginLeft: "0px",
          }}
        >
          <div
            style={{
              color: "#000000A6",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            Designation
          </div>
          <div
            style={{
              color: "#000000D9",
              fontSize: "17px",
              marginTop: 5,
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
            }}
          >
            {enquiryDetails?.designation}
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          margin: "24px 0px",
          marginLeft: "0px",
        }}
      >
        <div
          style={{
            color: "#000000A6",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
          }}
        >
          Email ID
        </div>
        <div
          style={{
            color: "#000000D9",
            fontSize: "17px",
            marginTop: 3,
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          }}
        >
          {enquiryDetails?.email}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          margin: "24px 0px",
          marginLeft: "0px",
        }}
      >
        <div
          style={{
            color: "#000000A6",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
          }}
        >
          {" "}
          Contact Number
        </div>
        <div
          style={{
            color: "#000000D9",
            fontSize: "17px",
            marginTop: 3,
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          }}
        >
          {enquiryDetails?.contactNumber}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          margin: "24px 0px",
          marginLeft: "0px",
        }}
      >
        <div
          style={{
            color: "#000000A6",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
          }}
        >
          Schedule Date
        </div>
        <div
          style={{
            color: "#000000D9",
            fontSize: "17px",
            marginTop: 3,
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          }}
        >
          {enquiryDetails?.schedule}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          margin: "24px 0px",
          marginLeft: "0px",
        }}
      >
        <div
          style={{
            color: "#000000A6",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
          }}
        >
          Product{" "}
        </div>
        <div
          style={{
            color: "#000000D9",
            fontSize: "17px",
            marginTop: 3,
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          }}
        >
          {enquiryDetails?.product}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          margin: "24px 0px",
          marginLeft: "0px",
          marginBottom: "60px",
        }}
      >
        <div
          style={{
            color: "#000000A6",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
          }}
        >
          Message
        </div>
        <div
          style={{
            color: "#000000D9",
            fontSize: "17px",
            marginTop: 3,
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          }}
        >
          {enquiryDetails.message ? enquiryDetails.message : <div style={{color: "#000000D9",
            fontSize: "17px",
            marginTop: 3,
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",}}>No Message</div>}
        </div>
      </div>
      <style>
        {`
    @media (min-width: 1536px) {
      .css-vi59no {
       width: 100%; 
       
      margin-bottom: 30px;
      }
    }

  `}
      </style>
    </div>
  );
};
export default EnquiryDetails;
