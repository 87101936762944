import React from "react";

const UserIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="User">
        <path
          id="Vector"
          d="M14.187 12.4906C13.8501 11.6927 13.3613 10.968 12.7477 10.3567C12.136 9.74368 11.4113 9.25492 10.6138 8.91741C10.6066 8.91384 10.5995 8.91206 10.5923 8.90848C11.7048 8.10491 12.4281 6.79598 12.4281 5.3192C12.4281 2.87277 10.4459 0.890625 7.99949 0.890625C5.55306 0.890625 3.57091 2.87277 3.57091 5.3192C3.57091 6.79598 4.29413 8.10491 5.40663 8.91027C5.39949 8.91384 5.39234 8.91563 5.3852 8.9192C4.5852 9.2567 3.86734 9.74063 3.25127 10.3585C2.63825 10.9702 2.14949 11.6949 1.81199 12.4924C1.48042 13.2732 1.3016 14.1104 1.2852 14.9585C1.28472 14.9775 1.28807 14.9965 1.29503 15.0143C1.302 15.032 1.31245 15.0482 1.32576 15.0618C1.33907 15.0755 1.35498 15.0863 1.37255 15.0937C1.39012 15.1011 1.40899 15.1049 1.42806 15.1049H2.49949C2.57806 15.1049 2.64056 15.0424 2.64234 14.9656C2.67806 13.5871 3.23163 12.296 4.2102 11.3174C5.2227 10.3049 6.56734 9.74777 7.99949 9.74777C9.43163 9.74777 10.7763 10.3049 11.7888 11.3174C12.7673 12.296 13.3209 13.5871 13.3566 14.9656C13.3584 15.0442 13.4209 15.1049 13.4995 15.1049H14.5709C14.59 15.1049 14.6089 15.1011 14.6264 15.0937C14.644 15.0863 14.6599 15.0755 14.6732 15.0618C14.6865 15.0482 14.697 15.032 14.7039 15.0143C14.7109 14.9965 14.7142 14.9775 14.7138 14.9585C14.6959 14.1049 14.5191 13.2746 14.187 12.4906ZM7.99949 8.39063C7.17984 8.39063 6.40841 8.07098 5.82806 7.49063C5.2477 6.91027 4.92806 6.13884 4.92806 5.3192C4.92806 4.49955 5.2477 3.72813 5.82806 3.14777C6.40841 2.56741 7.17984 2.24777 7.99949 2.24777C8.81913 2.24777 9.59056 2.56741 10.1709 3.14777C10.7513 3.72813 11.0709 4.49955 11.0709 5.3192C11.0709 6.13884 10.7513 6.91027 10.1709 7.49063C9.59056 8.07098 8.81913 8.39063 7.99949 8.39063Z"
          fill="black"
          fill-opacity="0.85"
        />
      </g>
    </svg>
  );
};

export default UserIcon;
