import React from 'react'

const FileUploadIcon = ({ color }: { color: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
            <path d="M41.4939 20.9112L41.4845 20.8737L36.2252 7.50962C35.9908 6.75493 35.2923 6.23462 34.5002 6.23462H13.1814C12.3845 6.23462 11.6767 6.76431 11.4517 7.52837L6.53453 20.7565L6.52047 20.7893L6.51109 20.8268C6.45016 21.0565 6.43141 21.2909 6.46422 21.5206C6.45953 21.5956 6.45484 21.6706 6.45484 21.7456V38.8971C6.45608 39.6526 6.75675 40.3768 7.29096 40.911C7.82517 41.4452 8.54936 41.7459 9.30484 41.7471H38.7048C40.2752 41.7471 41.5548 40.4674 41.5595 38.8971V21.7456C41.5595 21.6846 41.5595 21.6237 41.5548 21.5721C41.5736 21.3424 41.5548 21.1221 41.4939 20.9112ZM27.6283 18.8956L27.6142 19.6315C27.5767 21.7362 26.1236 23.1518 24.0002 23.1518C22.9642 23.1518 22.0736 22.819 21.4314 22.1862C20.7892 21.5534 20.4377 20.6721 20.4189 19.6315L20.4048 18.8956H10.758L14.4845 9.83462H33.197L37.0267 18.8956H27.6283ZM10.0502 22.4956H17.4236C18.5627 25.1721 20.9861 26.7518 24.0048 26.7518C25.5845 26.7518 27.0517 26.3112 28.2377 25.4768C29.2783 24.7456 30.0892 23.7237 30.6142 22.4956H37.9502V38.1471H10.0502V22.4956Z" fill={color} />
        </svg>
    )
}

export default FileUploadIcon