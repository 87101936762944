import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  IconButton,
  InputAdornment,
  Modal,
  Box,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { LoginApi } from "./service/apiActions/FaceAction";
import Loader from "./components/Common/Loader/Loader";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { VariantType, useSnackbar } from "notistack";
import LogoIcon from "./Icons/LogoIcon";
import CloseIcon from "@mui/icons-material/Close";
import Google from "./Icons/Google";
import CustomButton from "./components/Common/CustomButton";
import { useDispatch } from "react-redux";
import { changeProfileName } from "./reduxSlice/profile/profileSlice";
const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar  ,closeSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const showSnackbar = (message: string, type: VariantType = "error") => {
    enqueueSnackbar(message, { variant: type  , action:  (key) => (
      <CloseIcon onClick={() => closeSnackbar(key)} />
    ), });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  useEffect(()=>{
    dispatch(changeProfileName(false));
  })

  const onSubmit = async (username: string, password: string) => {
    setLoading(true);

    // Converting the entered email to lowercase
    const lowercasedUsername = username.toLowerCase();

    const body = {
      // email: username,
      email: lowercasedUsername,
      password: password,
    };

    try {
      const res: any = await LoginApi(body);

      if (res.status !== "success") {
        if (res.status === 400 || res.status === 401) {
          setOpen(true); // Showing modal for status code 400 or 401
        } else {
          showSnackbar(res.message, "error");
        }
      } else {
        localStorage.setItem("userLoginData", JSON.stringify(res));
        dispatch(changeProfileName(res.name));
        localStorage.setItem("isLoggedIn", "true");
        setLoading(false);
        navigate("/");
      }
    } catch (error: any) {
      console.log("error", error);
      if (error.message === "access revoked") {
        setOpen(true);
      } else {
        showSnackbar(error.message, "error");
      }
      setLoading(false);
    }
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    let isValid = true;

    if (!validateEmail(username)) {
      setEmailError(true);
      isValid = false;
    } else {
      setEmailError(false);
    }

    if (username.trim() === "") {
      setUsernameError(true);
      isValid = false;
    } else {
      setUsernameError(false);
    }

    if (password.trim() === "") {
      setPasswordError(true);
      isValid = false;
    } else {
      setPasswordError(false);
    }

    if (isValid) {
      onSubmit(username, password);
    }
  };

  if (loading) return <Loader />;

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className="auth-layout">
      <div
        style={{
          display: "flex",
          marginBottom: "16px",
          justifyContent:"center",
          alignItems:"center",
          transform: "translate(10%)",
        }}
      >
        <LogoIcon />
        {/* <img src="/images/aifise-logo.png" alt="logo" height={40} width={350} /> */}
      </div>
      <Grid container justifyContent="center">
        <Grid item xs={10} sm={6} md={4} xl={3}>
          <Paper elevation={3} style={{ padding: 20 }}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontWeight: "medium",  }}
            >
              Welcome
            </Typography>

            <Typography variant="subtitle1" fontSize={14}  sx={{lineHeight:"140%"}}  gutterBottom>
              Please enter your login information to access your AIFISE CONSOLE
              account
            </Typography>

            <form onSubmit={handleFormSubmit}>
              <Grid container spacing={2} direction="column" mt={0.5}>
                <Grid item>
                  <Typography variant="body1">Email</Typography>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={username}
                    placeholder="Email"
                    onChange={(e) => setUsername(e.target.value)}
                    error={emailError || usernameError}
                    helperText={
                      emailError
                        ? "Invalid email format"
                        : usernameError
                        ? "Username is required"
                        : ""
                    }
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body1">Password</Typography>
                  <TextField
                    size="small"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    placeholder="Password"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={passwordError}
                    helperText={passwordError ? "Password is required" : ""}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <div
                    className="justify-content-end"
                    style={{ textAlign: "right" }}
                  >
                    <Link
                      style={{ fontStyle: "italic", color: "#9579E2" }}
                      to=""
                    >
                      Forgot your Password?
                    </Link>
                  </div>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ p: 1.5 }}
                  >
                    <Typography variant="body1">Login</Typography>
                  </Button>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        Access Revoked
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Your access has not enabled, please contact us for
                        enabling your access.
                      </Typography>
                      <CustomButton
                        onClick={() => {
                          setOpen(false);
                        }}
                        sx={{
                          marginTop: "20px",
                          borderRadius: "2px",
                          border: "1px solid #007AFE",
                          background: "#007AFE",
                          boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)",
                          maxWidth: "150px",
                        }}
                      >
                        OK
                      </CustomButton>
                    </Box>
                  </Modal>
                </Grid>
                <Grid item>
                  {/* <Grid container justifyContent={'center'} >
                
                <Grid item>
                  <Typography>
                       or
                  </Typography>
                </Grid>
              </Grid>
              <Grid item sx = {{mb: 2}}>
              <Button
              
                variant="contained"
                color="primary"
                fullWidth
                sx={{ p: 1.5 ,borderRadius: "2px",background: "#FFF",color :"black",
                boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.17), 0px 0px 3px 0px rgba(0, 0, 0, 0.08)"}}
              >
               <Google/>   <Typography sx = {{m:1 ,fontSize:"20px"}}>Login with Google</Typography>  
              </Button>
            </Grid> */}
                  <Grid container justifyContent={"center"}>
                    <Grid item>
                      <Typography>
                        Don't have an account ?{" "}
                        <strong
                          onClick={() => navigate("/signup")}
                          style={{ color: "#1976d2", cursor: "pointer" }}
                        >
                          Sign Up
                        </strong>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
      <style>
        {`.css-1j23owc-MuiButtonBase-root-MuiButton-root {
     text-transform: capitalize;
  }`}
        {`.css-rjeh99-MuiButtonBase-root-MuiButton-root{ text-transform: capitalize;}`}
        {`.css-1nb4old-MuiTypography-root{margin-bottom: -1px}`}
      </style>
    </div>
  );
};

export default Login;
