import React, { useEffect, useState } from "react";
import { Divider, Paper, Typography, styled } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Switch from "@mui/material/Switch";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import ExclamationCircle from "../Icons/ExclamationCircle";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { Search } from "@mui/icons-material";
import { useOutletContext, useParams } from "react-router-dom";
import { OutletcontextType } from "../components/Layout/Index";
import Grid from "@mui/material/Grid";
import WrongPasswordModal from "../components/pages/UserManagement/WrongPasswordModal";
import OpenPageIcon from "../Icons/OpenPageIcon";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import {
  allUserProduct,
  allProductsApi,
  allUserPermission,
} from "../service/apiActions/FaceAction";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useNavigate } from "react-router-dom";
import { AnyAction } from "@reduxjs/toolkit";
// const useStyles = makeStyles({
//     table:{

//     }
// })
const label = { inputProps: { "aria-label": "Switch demo" } };

const UserManagementDetail = () => {
  const { handleChangeTitle, showSnackbar } =
    useOutletContext<OutletcontextType>();
  const [opendailog, setOpendailog] = useState<any>();
  const [open, setOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isRevoke, setIsRevoke] = useState(false);
  const [password, setPassword] = useState("");
  const [user_Names, setUser_Names] = useState("");
  const [userDestignation, setUserDestignation] = useState("");
  const [arrayProduct, setArrayProduct] = useState<any>([]);
  const [error, setError] = useState(false);
  const [selectedApi, setselectedApi] = useState<any>([]);
  const [conformToggle, setConformToggle] = useState(false);
  const [userid, setUserid] = useState(" ");
  const [userPermisionProductid, setUserPermisionProductid] = useState(" ");
  const [userproductid, setUserprductid] = useState("");
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [ischecked, setIschecked] = useState<string[]>([]);
  const [checkedProducts, setCheckedProducts] = useState("");
  const [toggle, setToggle] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      ConfirmApi();
    }
  };

  useEffect(() => {
    handleChangeTitle(
      "User Management ",
      " Control user access with the ability to effortlessly enable/disable users and manage their API permissions."
    );
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allproductList: any = await allProductsApi();
        const userdata: any = await allUserProduct({ email: id });
        const resArray = allproductList.map((item: any) => ({
          productid: item.id,
          name: item.name,
          toggleKey: userdata.data.selected_products.includes(item.id),
        }));
        setUser_Names(userdata.data.user_details.name);
        // setUserData(userdata);
        setUserprductid(userdata.data.selected_products);
        console.log(userdata, "userData");
        // setProductapi(allproductList);
        console.log(allproductList, "product");
        setArrayProduct(resArray);
        setUserDestignation(userdata.data.user_details.designation);

        console.log(resArray, "resData");
        console.log(Array.isArray(userdata.data.selected_products), "isArray");
      } catch (err) {
        console.log("Error fetching data:", err);
      }
    };
    if (id) {
      fetchData();
    }
  }, [id, conformToggle]);

  console.log(arrayProduct.toggleKey, "togglekey");

  const EventTarget = (e: any) => {
    setOpendailog(e.target.checked);
    setOpen(true);
    setToggle(true);
  };

  console.log(userproductid, "userproductid");
  // handle checkbox

  const handleSelectAllChange = (event: any) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      setIschecked(arrayProduct.map((item: any) => item.productid));
    } else {
      setIschecked([]);
    }
    console.log(isChecked, "isChecked");
    setIschecked((prevIschecked) => {
      setCheckedProducts(prevIschecked.join(", "));
      return prevIschecked;
    });
  };

  const handlecheckbox = (e: any) => {
    const { value, checked } = e.target;
    console.log(value, "checkedValue");

    if (checked) {
      setIschecked((prevIschecked) => [...prevIschecked, value]);
    } else {
      setIschecked((prevIschecked) => prevIschecked.filter((e) => e !== value));
    }

    //array to string
    setIschecked((prevIschecked) => {
      setCheckedProducts(prevIschecked.join(", "));

      return prevIschecked;
    });
    console.log(ischecked, "checked");
  };

  console.log(checkedProducts, "richa");

  const Updatepermission = () => {
    if (opendailog === true) {
      let existingAddId: any[] =
        userproductid.length > 0
          ? userproductid.split(",").map((id: any) => id.trim())
          : [];
      existingAddId.push(userid);
      let uniqueIds: any[] = [...new Set(existingAddId)];
      let addedId: string =
        uniqueIds.length > 0 ? uniqueIds.join(",") : uniqueIds.join(" ");
      console.log(addedId, "addedId");
      setUserPermisionProductid(addedId);
    } else {
      let existingRemoveId: any[] = userproductid
        .split(",")
        .map((id: any) => id.trim());
      let updateIds: any[] = existingRemoveId.filter(
        (id) => !userid.includes(id)
      );
      let RemovedId: string =
        updateIds.length > 0 ? updateIds.join(",") : updateIds.join(",");
      console.log(RemovedId, "remove");
      setUserPermisionProductid(RemovedId);
    }
  };

  const ConfirmApi = async () => {
    const body: any = {
      email: id,
      password: password,
      product_access: userPermisionProductid,
    };
    setLoading(true);
    await allUserPermission(body)
      .then((res: any) => {
        setConformToggle(!conformToggle);
        setOpen(false);
        setOpendailog(false);
        setToggle(false);
        setIsRevoke(false);
        setIsActive(false);
        setConformToggle(!conformToggle);
        selectedApi.consoleAccessEnabled = false;
        setArrayProduct((prev: any) => [...prev, selectedApi]);
        setLoading(false);
        setPassword("");
        setIschecked([]);
      })
      .catch((err) => {
        setError(true);
        setOpendailog(false);
        setOpen(false);
        setIsActive(false);
        setIsRevoke(false);
        setLoading(false);
        setToggle(false);
        setPassword("");
        setIschecked([]);
      });
  };

  const handleClose = () => {
    setOpen(false);
    setIsRevoke(false);
    setIsActive(false);
    setPassword("");
  };

  const ConfirmForPasswordActive = () => {
    setIsActive(true);
    Updatepermission();
    setError(false);
  };
  const conformDelete = () => {
    let existingRemoveId: any[] = userproductid
      .split(",")
      .map((id: any) => id.trim());
    let updateIds: any[] = existingRemoveId.filter(
      (id) => !ischecked.includes(id)
    );
    let RemovedId: string = updateIds.length > 0 ? updateIds.join(",") : "";
    console.log(RemovedId, "remove");
    setUserPermisionProductid(RemovedId);
    console.log(RemovedId, "updateIdsupdateIds");
  };

  const ConfirmForPasswordRevoke = () => {
    setIsRevoke(true);
    toggle ? Updatepermission() : conformDelete();
    setError(false);
  };
  const Delete = () => {
    // setOpendailog(false)
    console.log(ischecked, "checked...");

    setOpen(true);
    setToggle(false);
    setOpendailog(false);
    console.log(toggle, "");
    console.log(ischecked, "checked");
  };

  const modalButtonLable = opendailog
    ? //isactive false
      isActive
      ? "Active"
      : "Confirm"
    : //isRevoke false
    isRevoke
    ? "Revoke"
    : "Confirm";

  console.log(userid, "userid");
  console.log(arrayProduct.productid, "arrayProduct");

  //==================================== filter the data==========================================================//
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: "" }}>
      <Button
        sx={{
          color: "#000000D9",
          display: "inline-flex",
          alignItems: "center",
          borderRadius: "2px",
          border: "1px solid #D9D9D9",
          background: "#FFFFFF",
          padding: "7px 15px",
          // marginTop: 5,
          marginLeft: "0px",
          fontWeight: "500",
          textTransform: "none",
        }}
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
      >
        Back
      </Button>

      <Button
        sx={{
          color: ischecked.length === 0 ? "Black" : "white",
          display: "inline-flex",
          alignItems: "center",
          borderRadius: "2px",
          border: "1px solid #D9D9D9",
          backgroundColor: ischecked.length === 0 ? "white" : "#FF4D4F",
          padding: "7px 15px",
          // marginTop: 5,
          marginLeft: "10px",
          fontWeight: "500",

          textTransform: "none",
          "&:hover": { color: "white", backgroundColor: "red" },
        }}
        onClick={Delete}
        disabled={ischecked.length === 0}
      >
        Revoke
      </Button>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography variant="h4" mt={2}>
            {" "}
            {user_Names}
          </Typography>
          <Typography> {userDestignation} </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <TableContainer sx={{ marginTop: "16px", marginBottom: "60px" }}>
            <Table
              sx={{
                "& .MuiTableCell-sizeMedium": {
                  padding: "8px 16px",
                },
              }}
            >
              <TableHead>
                <StyledTableRow
                  sx={{
                    backgroundColor: "#fafafa",
                    borderbottom: "1px solid  rgba(0,0,0,0.06)",
                    padding: "16px",
                  }}
                >
                  <TableCell style={{ padding: "16px", width: "100px" }}>
                    {" "}
                    {/* <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                      color="primary"
                    /> */}
                    Select
                  </TableCell>
                  <TableCell style={{ padding: "16px" }}>Product</TableCell>

                  <TableCell style={{ padding: "16px" }}>API Access</TableCell>
                </StyledTableRow>

                {arrayProduct.map((item: any) => (
                  <StyledTableRow key={item.productid}>
                    <StyledTableCell>
                      <Checkbox
                        {...label}
                        value={item.productid}
                        checked={ischecked.includes(item.productid)}
                        disabled={!item.toggleKey}
                        onChange={(e) => {
                          handlecheckbox(e);
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        // color: selectedApi ? "green" : "red",
                        fontWeight: "500",
                        height: "50px",
                      }}
                    >
                      {item.name}
                    </StyledTableCell>
                    <StyledTableCell>
                      {" "}
                      <Switch
                        {...label}
                        checked={item.toggleKey}
                        onChange={(e: any) => {
                          EventTarget(e);
                          setselectedApi(item);
                          setUserid(item.productid);
                        }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableHead>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Dialog
            open={open}
            onClose={(event: any, reason: any) => {
              if (reason !== "backdropClick") {
                handleClose();
              }
            }}
            disableEscapeKeyDown={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
              "& .MuiDialog-paper": {
                width: "100%",
                maxWidth: "500px",
                borderRadius: "0px",
                padding: "0px",
                margin: "0px",
                maxHeight: "500px",
              },
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                display: "flex",
                alignItems: "center",
                paddingX: "24px",
                fontSize: "16px",
                fontWeight: 600,
                color: "rgba(0, 0, 0, 0.85)",
              }}
            >
              {opendailog ? "Activate API Access" : "Revoke API Access"}
            </DialogTitle>
            <Divider />
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {opendailog ? (
                  <div>
                    <Typography
                      sx={{ color: "#333", fontSize: "13px", fontWeight: 400 }}
                    >
                      {" "}
                      API Name: <strong>{selectedApi.name}</strong>
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: "13px",
                        fontWeight: 400,
                        fontStyle: "normal",
                      }}
                    >
                      {" "}
                      Are you sure you want to activate {selectedApi.name} API
                      access to {user_Names}?
                    </Typography>
                    {isActive ? (
                      <>
                        <Typography
                          sx={{
                            mb: 1,
                            mt: 1.5,
                            fontWeight: "700",
                            color: "#333",
                            fontSize: "13px",
                            fontStyle: "normal",
                          }}
                        >
                          {" "}
                          Confirm your identity by entering your password.
                        </Typography>

                        {/* <TextField
                          id="filled-password-input"
                          type="password"
                          autoComplete="current-password"
                          placeholder="Password"
                          value={password}
                          required
                          onChange={(e) => setPassword(e.target.value)}
                          //  onClick={() => handleActivateConfirm()}
                          sx={{
                            width: "100%",
                            borderBottom: "none",
                            backgroundColor: "white",
                            "& input": {
                              backgroundColor: "white",
                            },
                          }}
                        /> */}
                        <FormControl sx={{ width: "100%" }} variant="outlined">
                          <OutlinedInput
                            size="small"
                            id="outlined-adornment-password"
                            sx={{ width: "221px", mb: 1.7 }}
                            type={showPassword ? "text" : "password"}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={handleKeyDown}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    <Typography
                      sx={{ color: "#333", fontSize: "13px", fontWeight: 400 }}
                    >
                      {" "}
                      API Name:{" "}
                      {toggle ? (
                        <strong>{selectedApi.name}</strong>
                      ) : (
                        <strong> {checkedProducts} </strong>
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: "13px",
                        fontWeight: 400,
                        fontStyle: "normal",
                      }}
                    >
                      {" "}
                      Are you sure you want to revoke {selectedApi.name} API
                      access to {user_Names}?
                    </Typography>

                    {isRevoke ? (
                      <>
                        <Typography
                          sx={{
                            mb: 1,
                            mt: 1.5,
                            fontWeight: "700",
                            color: "#333",
                            fontSize: "13px",
                            fontStyle: "normal",
                          }}
                        >
                          Confirm your identity by entering your password.
                        </Typography>

                        {/* <TextField
                          id="filled-password-input"
                          type="password"
                          autoComplete="current-password"
                          placeholder="Password"
                          value={password}
                          required
                          onChange={(e) => setPassword(e.target.value)}
                          sx={{
                            width: "100%",
                            borderBottom: "none",
                            backgroundColor: "white",
                            "& input": {
                              backgroundColor: "white",
                            },
                          }}
                        /> */}
                        <FormControl sx={{ width: "100%" }} variant="outlined">
                          <OutlinedInput
                            size="small"
                            id="outlined-adornment-password"
                            sx={{ width: "221px", mb: 1.7 }}
                            type={showPassword ? "text" : "password"}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={handleKeyDown}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </>
                    ) : null}
                  </div>
                )}
              </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ mt: 1 }}>
              <Button
                sx={{
                  borderRadius: "2px",
                  border: "1px solid #d9d9d9",
                  color: "rgba(0, 0, 0, 0.85)",
                  fontWeight: 400,
                  fontSize: "14px",
                  padding: "4px 24px",
                  textTransform: "none",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                sx={
                  opendailog
                    ? {
                        backgroundColor: "#52C41A",
                        borderRadius: "2px",
                        border: "1px solid #52C41A",
                        background: "#52C41A",
                        fontSize: "14px",
                        fontWeight: 400,
                        padding: "4px 24px",
                        textTransform: "none",
                        color: "white",
                        "&:hover": { color: "white", backgroundColor: "green" },
                      }
                    : {
                        backgroundColor: "#FF4D4F",
                        borderRadius: "2px",
                        textTransform: "none",
                        padding: "4px 24px",
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "white",
                        "&:hover": { color: "white", backgroundColor: "red" },
                      }
                }
                style={{
                  borderRadius: "2px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
                onClick={
                  opendailog
                    ? isActive
                      ? ConfirmApi
                      : ConfirmForPasswordActive
                    : isRevoke
                    ? ConfirmApi
                    : ConfirmForPasswordRevoke
                }
                disabled={loading}
              >
                {<div>{loading ? "Loading..." : modalButtonLable}</div>}
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
      {error ? <WrongPasswordModal open={error} /> : ""}
      <style>
        {`.css-1yazjtg-MuiInputBase-root-MuiFilledInput-root{
     background-color:white
  }`}
        {`
    @media (min-width: 1536px) {
      .css-vi59no {
       width: 100%; 

      margin-bottom: 30px;
      }
    }

  `}
      </style>
    </div>
  );
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default UserManagementDetail;
