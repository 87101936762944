import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseCircle from "../../../Icons/CloseCircle";
import ExclamationCircle from "../../../Icons/ExclamationCircle";
import { Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeSessionModal } from "../../../reduxSlice/sessionExpire/sessionExpireSlice";
import { RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";

const SessionTimeOutModal = (prop: any) => {
  // const [open, setOpen] = React.useState(prop.open);
  const navigate = useNavigate();
  const isSessionExpired = useSelector(
    (state: RootState) => state.sessionExpire.isSessionExpired
  );
  console.log(isSessionExpired, "isSessionExpired");

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    // setOpen(true);
    dispatch(changeSessionModal(true));
  };

  const handleClose = () => {
    // setOpen(false);
    dispatch(changeSessionModal(false));
    localStorage.clear();
    navigate("/login", { replace: true });
  };
  return (
    <Dialog
      open={isSessionExpired}
      onClose={(event: any, reason: any) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "500px",
          borderRadius: "0px",
          padding: "0px",
          margin: "0px",
          maxHeight: "500px",
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          display: "flex",
          alignItems: "center",
          paddingX: "24px",
          fontSize: "16px",
          fontWeight: 600,
          color: "rgba(0, 0, 0, 0.85)",
        }}
      >
        <ExclamationCircle />{" "}
        <div style={{ marginLeft: "16px" }}>{"Session Timed Out."}</div>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            color: "rgba(0, 0, 0, 0.85)",
            fontSize: "14px",
            fontWeight: 400,
            fontStyle: "normal",
            marginLeft: "36px",
          }}
        >
          For security reasons, your session has expired. Please restart the
          enable user activation process again.
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
          sx={{
            borderRadius: "2px",
            border: "1px solid #d9d9d9",
            // color: "rgba(0, 0, 0, 0.85)",
            fontWeight: 400,
            fontSize: "14px",
            padding: "4px 24px",
            textTransform: "none",
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionTimeOutModal;
